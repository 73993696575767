import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';

import { ProductionCategoryValues } from 'edgeco/types';

import Title from 'edgeco/components/common/Title';
import { Loading } from 'edgeco/components/loading';

import { useProductionBreakdownComparisonChart } from 'edgeco/graphql/advisor-summary/queries/productionBreakdown';

import ComboChart from 'edgeco/components/data-visualization/Charts/ComboChart';
import { ComparisonButtonTabBar } from 'edgeco/components/buttons';
import {
  Bar,
  Line,
  Stripes,
  renderBarCellPattern,
  useYAxis,
  YAxis,
  XAxis,
} from '../../../components/data-visualization';
import Widget from '../../../components/dashboard-widget/Widget';
import { getPastYears, currencyFormatter } from '../../../libs';

import colorScheme from '../../../components/data-visualization/colorScheme';
import { themeExtensions } from '../../../assets/theme';

const useStyles = makeStyles({
  widgetRoot: {
    display: 'flex',
    alignContent: 'center',
    margin: 'auto',
  },
  graphRoot: {
    display: 'flex',
    width: '100%',
    height: '500px',
    justifyContent: 'center',
  },
  tooltipContent: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
  axisTickLabel: {
    ...themeExtensions.font.infographicTickLabelLg,
  },
});

function ProductionBreakdownWidget() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [selectedYear, selectYear] = useState<number>();

  const years = useMemo(() => getPastYears(5, false), []);

  const {
    productionBreakdownChartData,
    loading,
    error,
    domain,
    populatedYears,
  } = useProductionBreakdownComparisonChart(years, currentYear, selectedYear);

  const onYearChange = useCallback((e: any, year: string) => {
    const comparisonYear = parseInt(year);
    selectYear(comparisonYear);
  }, []);

  const getDataCategory = useCallback(
    (
      current: ChartDataShape<ProductionBreakdownDataShape, { year: number }>,
      category: ProductionCategory
    ) => {
      return current.metadata?.year === currentYear
        ? current.data[category]
        : null;
    },
    [currentYear]
  );

  const getHistoricalData = useCallback(
    (
      current: ChartDataShape<ProductionBreakdownDataShape, { year: number }>,
      category: ProductionCategory
    ) => {
      return current.metadata?.year !== currentYear
        ? current.data[category]
        : null;
    },
    [currentYear]
  );

  const getColor = (idx: number) => {
    return {
      fill: colorScheme.forIndex(idx, ProductionCategoryValues.length),
      activeFill: colorScheme.forIndex(
        idx,
        ProductionCategoryValues.length,
        true
      ),
    };
  };

  const tooltipFormat: (
    key: string
  ) => TooltipRenderer<ProductionBreakdownChartDataShape> = useCallback(
    (key: string) =>
      ({ value, data: { metadata } }: TooltipProps) => {
        return (
          <div className={classes.tooltipContent}>
            <div>
              <b>{currencyFormatter(value as number, 9)}</b>
            </div>
            <div>
              <b>{key} Posted</b>
            </div>
            <div>{metadata?.year}</div>
          </div>
        );
      },
    [classes.tooltipContent]
  );

  const renderChartObjects = useMemo(() => {
    if (loading || !productionBreakdownChartData) return [];
    const barData = productionBreakdownChartData.filter(
      (x) => x.metadata!.year === currentYear
    );
    return ProductionCategoryValues.reduce((acc, k, idx) => {
      acc.push(
        ...[
          <Line
            key={`1-line-${k}`}
            name={k}
            legendType="none"
            type="linear"
            dataKey={(d) => getHistoricalData(d, k)}
            stroke={getColor(idx).fill}
            getTooltipContent={tooltipFormat(k)}
            activeFill={getColor(idx).activeFill}
          />,
          <Bar
            key={`3-bar-${k}`}
            name={k}
            legendType="square"
            dataKey={(d) => getDataCategory(d, k)}
            getTooltipContent={tooltipFormat(k)}
            children={renderBarCellPattern({
              pattern: <Stripes />,
              data: barData,
              match: (d) => !d.metadata.isComplete,
              ...getColor(idx),
            })}
          />,
        ]
      );
      return acc;
    }, [] as React.ReactElement[]).sort((a, b) =>
      b.key!.toString().localeCompare(a.key!.toString())
    );
  }, [
    currentYear,
    getDataCategory,
    getHistoricalData,
    loading,
    productionBreakdownChartData,
    tooltipFormat,
  ]);
  const yAxisProps = useYAxis(domain);
  const chartProps = {
    yAxis: (
      <YAxis
        {...yAxisProps}
        tick={{ fontSize: '2.1rem', fill: themeExtensions.color.black }}
      />
    ),
    xAxis: (
      <XAxis tick={{ fontSize: '2.1rem', fill: themeExtensions.color.black }} />
    ),
    dataShape: { chartData: productionBreakdownChartData },
    barGap: '-30',
    legendSeries: ProductionCategoryValues,
  };

  return (
    <Widget title="Production Breakdown" className={classes.widgetRoot}>
      <Title text={{ right: `${new Date().getFullYear()} YTD` }} />
      <div className={classes.graphRoot}>
        {loading || error ? (
          <Loading />
        ) : (
          <ComboChart {...chartProps}>{renderChartObjects}</ComboChart>
        )}
      </div>
      <ComparisonButtonTabBar
        size="medium"
        years={populatedYears}
        selectedYear={selectedYear}
        onSelect={onYearChange}
      />
    </Widget>
  );
}
export default ProductionBreakdownWidget;
