import React, { useContext } from 'react';
import {
  AppInsightsContext,
  ReactPlugin,
  useTrackEvent,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../config/appInsightsConfig';

type AppInsightsContextProviderProps = {
  plugin?: ReactPlugin;
};

const AppInsightsContextProvider = ({
  children,
  plugin,
}: React.PropsWithChildren<AppInsightsContextProviderProps>) => {
  return (
    <AppInsightsContext.Provider value={plugin ?? reactPlugin}>
      {children}
    </AppInsightsContext.Provider>
  );
};

const useAppInsights = () => useContext(AppInsightsContext);

const useTrackComponent = (componentName: string) => {
  const plugin = useAppInsights();

  const trackEvent = useTrackMetric(plugin, componentName);

  return trackEvent;
};

const useTrackEventCustom = <TData extends any>(
  eventName: string,
  eventData: TData
) => {
  const plugin = useAppInsights();
  const trackEvent = useTrackEvent<TData>(plugin, eventName, eventData);

  return trackEvent;
};

enum EventTypes {
  ExportLimit = 'Export Limit',
}

export {
  AppInsightsContext,
  AppInsightsContextProvider,
  useAppInsights,
  useTrackComponent,
  useTrackEventCustom as useTrackEvent,
  EventTypes,
};
