import { gql, useQuery } from '@apollo/client';
import useCumulativeDomain from 'edgeco/components/data-visualization/hooks/useCumulativeDomain';
import { useMemo } from 'react';
import { groupObjectsBy } from '../../../utils/utility-functions';
import { GetTopHouseholdYearlyTotalsQuery } from '../@types';
import { useHouseholdPolling } from './shared';

export const GET_TOP_HOUSEHOLD_YEARLY_TOTALS = gql`
  query GetTopHouseholdYearlyTotals {
    viewer {
      topHouseholdYearlyTotals {
        name
        year
        value
        total
      }
    }
  }
`;

export const useYearlyTopHouseholdsComparisonChart = (
  years: number[],
  currentYear: number,
  selectedYear?: number
) => {
  const { data, loading, error, startPolling, stopPolling } =
    useQuery<GetTopHouseholdYearlyTotalsQuery>(
      GET_TOP_HOUSEHOLD_YEARLY_TOTALS,
      {
        variables: {
          years,
        },
      }
    );

  useHouseholdPolling(startPolling, stopPolling);

  const queryResult = data?.viewer?.topHouseholdYearlyTotals ?? [];
  const topPayoutAccountsYearlyData: Map<
    string | number,
    YearlyTopDataShape[]
  > = useMemo(() => {
    const results = queryResult
      .map(
        ({ year, value, name, total }) =>
          ({
            year,
            name,
            value,
            total,
            percentOfTotal: value / total,
          } as YearlyTopDataShape)
      )
      .sort((a, b) => a.year - b.year);
    return groupObjectsBy(results, (d) => d.name);
  }, [queryResult]);

  const chartData = useMemo(
    () =>
      [...topPayoutAccountsYearlyData.entries()].map(([key, values]) => ({
        key,
        data: values.reduce((acc, curr) => {
          if (curr.year === currentYear) {
            acc.current = curr as YearlyTopDataShape;
          }

          if (curr.year === selectedYear) {
            acc.comparison = curr as YearlyTopDataShape;
          }

          return acc;
        }, {} as TopYearlyComparison),
      })),
    [currentYear, selectedYear, topPayoutAccountsYearlyData]
  );

  const domain = useCumulativeDomain(chartData, undefined, [
    (d: ChartDataShape<TopYearlyComparison>) => d.data?.current?.value,
    (d: ChartDataShape<TopYearlyComparison>) => d.data?.comparison?.value,
  ]);

  const populatedYears = [...topPayoutAccountsYearlyData.values()]
    .flat()
    .reduce((acc, curr) => {
      if (curr.year !== currentYear) acc.add(curr.year);
      return acc;
    }, new Set<number>());

  return { chartData, domain, populatedYears, loading, error };
};
