import { PropsWithChildren } from 'react';
import { makeStyles, Button, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { themeExtensions } from '../../assets/theme';

const useStyle = makeStyles((theme) => ({
  root: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
    padding: theme.spacing(1) + 2,
    borderRadius: '6px',
    borderColor: themeExtensions.color.borderLight,
  },
}));
type ButtonWrapperProps = ButtonProps;
export default function ButtonWrapper({
  className,
  children,
  ...rest
}: PropsWithChildren<ButtonWrapperProps>) {
  const classes = useStyle();
  return (
    <Button className={clsx(classes.root, className)} {...rest}>
      {children}
    </Button>
  );
}
