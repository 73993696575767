import React, { forwardRef } from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import colorScheme from '../colorScheme';

const useStyles = makeStyles<null, { stroke?: string }>({
  backgroundCircle: {
    fill: colorScheme.colors.backgroundWhite,
    stroke: colorScheme.colors.backgroundWhite,
  },
  circle: ({ stroke }) => ({
    fill: stroke,
    stroke,
  }),
});

const DotShape = forwardRef<any, DotShapeProps>(
  (
    {
      cx,
      cy,
      stroke,
      refId,
      strokeWidth,
      height,
      width,
      value,
      radius = 8,
      isActive = false,
      showBackground = true,
      visible = true,
      activeFill,
    },
    ref
  ) => {
    const { backgroundCircle, circle } = useStyles({
      stroke: isActive && activeFill ? activeFill : stroke,
    });

    const matchMdBreakpointDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm')
    );
    let backgroundRadius = radius + 2;
    let mainRadius = radius;

    if (matchMdBreakpointDown) {
      backgroundRadius = Math.round((radius + 2) / 2); // default 5
      mainRadius = Math.round(radius / 2); // default 4
    }

    return value && visible ? (
      <>
        {showBackground && (
          <circle
            strokeWidth={strokeWidth}
            cx={cx}
            cy={cy}
            height={height}
            width={width}
            className={backgroundCircle}
            r={backgroundRadius}
          />
        )}
        <circle
          mask={`url(#mask-${refId})`}
          strokeWidth={strokeWidth}
          ref={ref}
          cx={cx}
          cy={cy}
          height={height}
          width={width}
          className={circle}
          r={mainRadius}
        />
      </>
    ) : null;
  }
);

export default DotShape;
