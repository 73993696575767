import { IconButton as MuiIconButton } from '@material-ui/core';

import { themeExtensions } from '../../assets/theme';
import Icon from '../common/Icon';

function IconButton<TValue, TVariant extends IconVariant>({
  iconProps,
  variant,
  primaryColor = 'currentColor',
  secondaryColor,
  backgroundColor = themeExtensions.color.white,
  children,
  value,
  ...buttonProps
}: CustomIconButtonProps<TValue, TVariant>) {
  return (
    <MuiIconButton {...buttonProps} value={value as ButtonValueType}>
      {children ??
        (variant && (
          <Icon
            variant={variant}
            backgroundColor={backgroundColor}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            iconProps={iconProps}
          />
        ))}
    </MuiIconButton>
  );
}

export default IconButton;
