import useConstant from './useConstant';

/* Returns the scrollbar size, useful with vw & vh as they
   include the width as you may want to subtract it */
export function useScrollbarSize() {
  return useConstant(() => {
    const body = document.getElementsByTagName('body')[0];
    const measureElement = document.createElement('div');
    measureElement.style.position = 'fixed';
    measureElement.style.top = '-100px';
    measureElement.style.overflow = 'scroll';
    body.appendChild(measureElement);
    const scrollbarSize = {
      width: measureElement.offsetWidth - measureElement.clientWidth,
      height: measureElement.offsetHeight - measureElement.clientHeight,
    };
    measureElement.remove();
    return scrollbarSize;
  });
}
