import { themeExtensions } from '../../assets/theme';

type SchemeValue = {
  primary: string;
  active?: string;
};

type ColorScheme = SchemeValue[];

export class ColorSchemeProvider {
  schemes: ColorScheme[];

  colors = themeExtensions.color.data;

  constructor() {
    const p = this.colors;
    this.schemes = [];
    this.schemes[3] = [
      { primary: p.teal, active: p.lightTeal },
      { primary: p.orange, active: p.lightOrange },
      { primary: p.eucalyptus, active: p.lightEucalyptus },
    ];

    this.schemes[4] = [
      { primary: p.eucalyptus, active: p.lightEucalyptus },
      { primary: p.teal, active: p.lightTeal },
      { primary: p.orange, active: p.lightOrange },
      { primary: p.sushi, active: p.lightSushi },
    ];

    this.schemes[5] = [
      { primary: p.orange, active: p.lightOrange },
      { primary: p.sushi, active: p.lightSushi },
      { primary: p.eucalyptus, active: p.lightEucalyptus },
      { primary: p.teal, active: p.lightTeal },
      { primary: p.gray, active: p.lightGray },
    ];
  }

  forIndex(index: number, length: number, isActive?: boolean): string {
    let schemeIndex = length;
    if (length < 3) {
      schemeIndex = 3;
    } else if (length > 5) {
      schemeIndex = 5;
    }
    const scheme = this.schemes[schemeIndex];
    const { primary, active } = scheme[index % schemeIndex];
    return isActive ? active ?? primary : primary;
  }

  static forIndexFromScheme(
    scheme: ColorScheme | string[],
    index: number,
    isActive?: boolean
  ) {
    const mappedScheme: ColorScheme =
      typeof scheme[0] === 'string'
        ? ((scheme as string[]).map((s) => ({ primary: s })) as ColorScheme)
        : (scheme as ColorScheme);
    const { primary, active } = mappedScheme[index % scheme.length];
    return isActive ? active ?? primary : primary;
  }
}

const instance = new ColorSchemeProvider();

export default instance;
