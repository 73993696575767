import { gql } from '@apollo/client';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import {
  RenameHouseholdMutation,
  RenameHouseholdMutationVariables,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';
import {
  getHouseholdFromCache,
  startPollingHouseholds,
  updateHouseholdCache,
} from './shared';

const RENAME_HOUSEHOLD = gql`
  mutation RenameHousehold($householdId: Uuid!, $name: String!) {
    UpdateHouseholdName(input: { householdId: $householdId, newName: $name }) {
      errors {
        code
        message
      }
    }
  }
`;

export const useRenameHousehold = () => {
  const [renameHousehold, { loading }] = useUserProfileMutation<
    RenameHouseholdMutation,
    RenameHouseholdMutationVariables
  >(RENAME_HOUSEHOLD);
  const [, dispatch] = useAppStateValue();
  return {
    renameHousehold: (householdId: string, name: string) =>
      renameHousehold({
        variables: { householdId, name },
        update: (cache, res) => {
          const errors = res.data?.UpdateHouseholdName?.errors ?? [];
          if (errors.length > 0) return;

          const household = getHouseholdFromCache(cache, householdId);
          updateHouseholdCache(cache, {
            ...(household ?? {}),
            name,
          });
          startPollingHouseholds(dispatch);
        },
      }),
    loading,
  };
};
