import React, { SyntheticEvent, useCallback, useState } from 'react';
import {
  Button,
  createStyles,
  FormControl,
  StyledComponentProps,
  TextField,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import useModal from 'edgeco/components/modal/useModal';
import { FilterContainerClassKey } from './FilterContainer';
import FilterGroup, { FilterGroupClassKey } from './FilterGroup';
import { useTableContext } from '../TableContext';
import { FilterActionType, useFilterContext } from './FilterContext';

const styles = (theme: EdgeCoTheme) =>
  createStyles({
    root: {
      margin: theme.spacing(0, 3),
      padding: theme.spacing(3, 0, 2),
      background: theme.extensions.color.backgroundContrast,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    applyButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    inputForm: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    saveReportInput: {
      margin: 35,
      padding: 10,
    },
  });

type ReportFiltersClassKey = keyof ReturnType<typeof styles>;

type Props = {
  className?: string;
  classes: DisjointMany<
    WithStyles<ReportFiltersClassKey>['classes'],
    NonNullable<StyledComponentProps<FilterGroupClassKey>['classes']>,
    NonNullable<StyledComponentProps<FilterContainerClassKey>['classes']>
  >;
};

function PureReportFilters({ classes, className }: Props) {
  const {
    state: { filters },
  } = useFilterContext();
  const {
    root,
    actions,
    applyButton,
    saveReportInput,
    inputForm,
    ...groupClasses
  } = classes;

  const { dispatch } = useFilterContext();

  const [reportName, setReportName] = useState('');
  const { onSaveFilters } = useTableContext();
  const CHARACTER_LIMIT = 25;
  const { Modal: SaveReportsModal, setOpen: setSaveReportsOpen } = useModal({
    isDraggable: false,
    hideHeader: true,
    content: (
      <FormControl className={classes.inputForm}>
        <TextField
          id="outlined-basic"
          label="Name Your Filter Set"
          variant="outlined"
          inputProps={{
            maxlength: CHARACTER_LIMIT,
          }}
          className={classes.saveReportInput}
          placeholder="Name"
          value={reportName}
          onChange={(evt) => setReportName(evt.target.value)}
          helperText={`${reportName.length}/${CHARACTER_LIMIT}`}
        />
      </FormControl>
    ),
    submitButton: {
      text: 'Save',
    },
    cancelButton: {
      text: 'Cancel',
    },
    onSubmit: () => {
      onSaveFilters(reportName);
      setReportName('');
    },
    onCancel: () => {
      setReportName('');
    },
  });

  const handleSaveReports = (event: SyntheticEvent) => {
    event.stopPropagation();
    setSaveReportsOpen(true);
  };

  const handleApplyFilters = useCallback(
    () => dispatch({ type: FilterActionType.ApplyAllEditFilters }),
    [dispatch]
  );

  return (
    <div className={clsx(root, className)}>
      <FilterGroup classes={groupClasses} filters={filters} />
      <div className={actions}>
        <Button
          className={applyButton}
          variant="outlined"
          color="secondary"
          onClick={(event: SyntheticEvent) => handleSaveReports(event)}
        >
          Save Filters
        </Button>
        <Button
          className={applyButton}
          variant="contained"
          color="secondary"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        {SaveReportsModal}
      </div>
    </div>
  );
}

const TableFilters = withStyles(styles, { name: 'TableFilters' })(
  PureReportFilters
);
export default TableFilters;
export type TableFiltersInstance = typeof TableFilters;
export type TableFiltersProps = React.ComponentProps<TableFiltersInstance>;
