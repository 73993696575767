import React from 'react';
import { makeStyles, fade, capitalize, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
      '&:hover': {
        backgroundColor: fade(theme.palette.action.hover, 0.15),
      },
      '& + &': {
        borderLeft: 0,
        marginLeft: 0,
      },
    },
    '&$disabled': {
      color: fade(theme.palette.action.disabled, 0.12),
    },
    '&:hover': {
      textDecoration: 'none',
      // Reset on mouse devices
      backgroundColor: theme.palette.action.hover,
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
      },
    },
  },
  /* Pseudo-class applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Pseudo-class applied to the root element if `selected={true}`. */
  selected: {},
  /* Styles applied to the `label` wrapper element. */
  label: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  /* Styles applied to the root element if `size="small"`. */
  sizeSmall: {
    padding: 9,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    minWidth: 'initial',
  },
  /* Styles applied to the root element if `size="large"`. */
  sizeLarge: {
    padding: 15,
    fontSize: theme.typography.pxToRem(15),
  },
  sizeMedium: {},
}));

type SizeStyle = 'sizeLarge' | 'sizeMedium' | 'sizeSmall';

export type ToggleButtonProps = ButtonProps & {
  value: any;
  className?: string;
  disabled?: boolean;
  disableFocusRipple?: boolean;
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  size?: ButtonSize;
  selected?: boolean;
};
export default React.forwardRef((props: ToggleButtonProps, ref: any) => {
  const {
    children,
    className,
    disabled = false,
    disableFocusRipple = false,
    onChange,
    onClick,
    selected,
    size = 'medium',
    value,
    ...rest
  } = props;

  const classes = useStyles();
  const handleChange = (event: any) => {
    if (onClick) {
      onClick(event, value);
      if (event.isDefaultPrevented()) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <Button
      className={clsx(
        classes.root,
        {
          [classes.disabled]: disabled,
          [classes.selected]: selected,
          [classes[`size${capitalize(size)}` as SizeStyle]]: size !== 'medium',
        },
        className
      )}
      disabled={disabled}
      focusRipple={!disableFocusRipple}
      ref={ref}
      onClick={handleChange}
      onChange={onChange}
      value={value}
      aria-pressed={selected}
      {...rest}
    >
      <span className={classes.label}>{children ?? value}</span>
    </Button>
  );
});
