import {
  Container,
  Typography,
  TypographyProps,
  useMediaQuery,
  createStyles,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ExternalLink from '../routing/Links/ExternalLink';

const styles = ({ breakpoints, spacing, extensions }: EdgeCoTheme) =>
  createStyles({
    root: {
      marginTop: spacing(5),
      maxWidth: 1250,
      padding: 0,
      [breakpoints.down('xs')]: {
        marginTop: spacing(1),
      },
    },
    content: {
      borderTop: extensions.borders.light,
      padding: spacing(4, 0, 5),
      maxWidth: 1250,
      [breakpoints.down('sm')]: {
        padding: spacing(1),
      },
    },
    line: {
      fontSize: '2.1rem',
      lineHeight: '2.4rem',
      [breakpoints.down('sm')]: {
        fontSize: '1.4rem',
        lineHeight: '1.6rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.3rem',
        lineHeight: '1.5rem',
      },
    },
  });

function Footer({ classes }: WithStyles<ReturnType<typeof styles>>) {
  const small = useMediaQuery((theme: EdgeCoTheme) =>
    theme.breakpoints.down('xs')
  );
  const typographyProps: TypographyProps = {
    className: classes.line,
    align: small ? 'inherit' : 'center',
  };

  const terms = (
    <>
      Use of this system indicates that you have read and agree with the{' '}
      <strong>
        <ExternalLink href="https://secure.macg.com/staticpda/terms.pdf">
          Terms and conditions
        </ExternalLink>
      </strong>
      .
    </>
  );

  const finraStatement = (
    <>
      Settlement Services are provided by{' '}
      <strong>Mid Atlantic Capital Corporation</strong>, a registered broker
      dealer. Member{' '}
      <ExternalLink className={classes.line} href="https://www.finra.org/#/">
        FINRA
      </ExternalLink>
      ,{' '}
      <ExternalLink className={classes.line} href="https://www.sipc.org">
        SIPC
      </ExternalLink>
      .
    </>
  );

  const contact = (
    <>
      {['1251 Waterfront Place', 'Pittsburgh, PA 15222', '412-391-7077'].join(
        small ? ', ' : ' | '
      )}
    </>
  );

  return (
    <Container className={classes.root}>
      <div className={classes.content}>
        {small ? (
          <>
            <Typography {...typographyProps}>
              {terms} {finraStatement} {contact}
            </Typography>
          </>
        ) : (
          <>
            <Typography {...typographyProps}>{terms}</Typography>
            <Typography {...typographyProps}>{finraStatement}</Typography>
            <Typography {...typographyProps}>{contact}</Typography>
          </>
        )}
      </div>
    </Container>
  );
}

export default withStyles(styles)(Footer);
