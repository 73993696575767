/* eslint-disable no-param-reassign */

import React, { ComponentType } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';

/**
 * Gets display name of a given component
 *
 * @param {React.Component} WrappedComponent The component to retrieve a display name for
 * @returns {String} The display name of the given component, or `Component` by default
 * @private
 */
function getDisplayName(WrappedComponent: ComponentType<any>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

/**
 * Recharts uses internal class methods to help render data.  Wrapping a Rechart data type causes render issues.
 * This assigns the wrapper the object properties from the type it is rendering.
 * Note: This breaks React Hooks.
 * @param component The ReChart type
 * @param WrapperFC The wrapper Functional Component
 * @param defaultProps Default props to substitute
 */
export const renderChartWrapper = <P extends BP, BP = {}>(
  component: React.ComponentType<BP>,
  WrapperFC: React.FC<P>,
  defaultProps?: Partial<P>
): React.FC<P> => {
  // Copy over non-react statics per
  // https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
  hoistNonReactStatics(WrapperFC, component);

  // Wrap display name per
  // https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
  WrapperFC.displayName = getDisplayName(component);

  WrapperFC.defaultProps = WrapperFC.defaultProps ?? {};

  if (component.defaultProps)
    Object.assign(WrapperFC.defaultProps, component.defaultProps);

  if (defaultProps) {
    Object.assign(WrapperFC.defaultProps, defaultProps);
  }

  return WrapperFC;
};
