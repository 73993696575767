import moment from 'moment';
import numeral from 'numeral';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Column } from 'react-table';

export function getShadowGradient(
  direction: 'left' | 'right' | 'bottom' | 'top'
) {
  const rv: CSSProperties = {
    position: 'absolute',
    background: `linear-gradient(to ${direction},
                  rgba(0,0,0,0.15) 1px,
                  rgba(0,0,0,0) 100% )`,
  };

  const shadowSize = 5;
  if (direction === 'left' || direction === 'right') {
    rv.width = shadowSize;
    rv.height = '100%';
    rv.top = 0;
    if (direction === 'left') {
      rv.left = -shadowSize;
    } else {
      rv.right = -shadowSize;
    }
  } else {
    rv.height = shadowSize;
    rv.width = '100%';
    rv.left = 0;
    if (direction === 'top') {
      rv.top = -shadowSize;
    } else {
      rv.bottom = -shadowSize;
    }
  }

  return rv;
}

const typeDefaultMap: Record<ColumnType, Partial<ColumnDefinition>> = {
  date: {
    format: 'MM/DD/yyyy',
    minWidth: 90,
  },
};

function getColumnDefaults(definition: ColumnDefinition): ColumnDefinition {
  const typeDefaults = definition!.type ? typeDefaultMap[definition!.type] : {};
  return {
    ...(typeDefaults as ColumnDefinition),
    ...definition,
  } as ColumnDefinition;
}

export function formatColumnValue(value: any, definition: ColumnDefinition) {
  const { format, type } = getColumnDefaults(definition);

  if (value === null || value === undefined) return '';
  if (!format) return value.toString();

  if (typeof value === 'number') {
    return numeral(value).format(format);
  }

  // instanceof does not work as the value returned from
  // the api is going to become a string when serialized to JSON
  if (type && type === 'date') {
    return value ? moment(value).format(format) : '';
  }
  throw new Error('A format was received for an unsupported type');
}

export function mapTableColumns<D extends object>(
  visibleColumns: (keyof D)[],
  columnDefinitions: ColumnDefinitionList<D>
): Array<Column<D>> {
  return visibleColumns
    .filter(
      (columnName) => (columnDefinitions[columnName] as any).hidden !== 'always'
    )
    .map((key) => {
      const definition = columnDefinitions[key];
      const {
        header,
        format,
        sticky,
        width,
        minWidth,
        tooltip,
        tooltipWidth,
        isSortable,
      } = getColumnDefaults(definition);

      const column: Column<D> = {
        Header: header,
        accessor: key as keyof D,
        width,
        sticky,
        tooltip,
        tooltipWidth,
        disableSortBy: !isSortable,
      };

      // setting minWidth to undefined causes issues with react-table-sticky
      if (minWidth !== undefined) column.minWidth = minWidth;

      if (format) {
        column.Cell = ({ value }) => formatColumnValue(value, definition);
      }
      return column;
    });
}
