import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  GetTopHouseholdPeriodTotalsQuery,
  GetTopHouseholdPeriodTotalsQueryVariables,
  TimeFrame,
} from '../@types';
import { useHouseholdPolling } from './shared';

export const GET_TOP_HOUSEHOLD_PERIOD_TOTALS = gql`
  query GetTopHouseholdPeriodTotals($timeFrame: TimeFrame) {
    viewer {
      topHouseholdPeriodTotals(timeFrame: $timeFrame) {
        name
        value
        total
      }
    }
  }
`;

export const useTopHouseholdsChart = (
  timeFrame: TopPayoutAccountDatePeriod
) => {
  const { data, loading, error, startPolling, stopPolling } = useQuery<
    GetTopHouseholdPeriodTotalsQuery,
    GetTopHouseholdPeriodTotalsQueryVariables
  >(GET_TOP_HOUSEHOLD_PERIOD_TOTALS, {
    variables: {
      timeFrame: timeFrame.toUpperCase() as TimeFrame,
    },
  });

  useHouseholdPolling(startPolling, stopPolling);

  const queryResult = data?.viewer?.topHouseholdPeriodTotals ?? [];

  const [totalTop, totalPercent] = useMemo(() => {
    const top = queryResult.reduce((acc, r) => acc + r.value, 0);
    const production = queryResult[0]?.total;
    return [top, top / production];
  }, [queryResult]);

  const topPayoutAccountsChartData: ChartDataShape<TopDataShape>[] = useMemo(
    () =>
      queryResult
        ? queryResult.map((r) => ({
            key: r.name,
            data: {
              ...r,
              percentOfTotal: r.value / totalTop,
            },
          }))
        : [],
    [queryResult, totalTop]
  );

  return { topPayoutAccountsChartData, loading, error, totalPercent };
};
