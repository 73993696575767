import { Link, makeStyles, Typography } from '@material-ui/core';
import useAuth from 'edgeco/hooks/useAuth';

const useStyles = makeStyles({
  root: {
    width: 1040,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 105,
  },
});

function LoggedOutView() {
  const classes = useStyles();
  const auth = useAuth();
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.title} variant="h2">
          You have logged out...
        </Typography>
        <Typography>
          If you have done this in error,{' '}
          <Link
            underline="always"
            onClick={() => {
              auth.login({ navigateToLoginRequestUrl: false });
            }}
          >
            please log in again
          </Link>
          .
        </Typography>
      </div>
    </div>
  );
}

export default LoggedOutView;
