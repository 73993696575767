import { useState, ChangeEvent } from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';
import TabPanel from 'edgeco/components/tabs/TabPanel';
import { useHouseholds } from 'edgeco/graphql/user-profile/queries';
import { Loading } from 'edgeco/components/loading';
import Widget from '../../../components/dashboard-widget/Widget';
import TopPayoutAccounts from './periodic/TopPayoutAccounts';
import YearlyTopPayoutAccounts from './yearly/YearlyTopPayoutAccounts';
import YearlyTopHouseholds from './yearly/YearlyTopHouseholds';
import TopHouseholds from './periodic/TopHouseholds';
import HouseholdChartContainer from './household/HouseholdChartContainer';
import TabContent from './TabContent';

const useStyles = makeStyles({
  tabs: {
    marginLeft: 74,
  },
});

function SignificantRevenueAccounts() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [topAccounts, setTopAccounts] = useState<KeyValueTypes[]>([]);
  const handleChange = (_: ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { households, loading: householdsLoading } = useHouseholds();

  return (
    <Widget
      title="Significant Accounts (NFS)"
      tabs={
        <Tabs
          className={classes.tabs}
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="significant revenue tabs"
        >
          <Tab
            label="Top 5 Accounts"
            id="significant-top-accounts-tab"
            aria-controls="significant-top-accounts-tabpanel"
          />
          <Tab
            label="Top 5 Households"
            id="significant-top-households-tab"
            aria-controls="significant-top-households-tabpanel"
          />
        </Tabs>
      }
    >
      <TabPanel tabName={'significant-top-accounts'} value={value} index={0}>
        <TabContent
          left={<TopPayoutAccounts onTopAccountsLoaded={setTopAccounts} />}
          right={
            topAccounts.length > 0 && (
              <YearlyTopPayoutAccounts topAccounts={topAccounts} />
            )
          }
        />
      </TabPanel>
      <TabPanel tabName={'significant-top-households'} value={value} index={1}>
        {householdsLoading ? (
          <Loading />
        ) : (
          <HouseholdChartContainer households={households}>
            <TabContent
              left={<TopHouseholds />}
              right={<YearlyTopHouseholds />}
            />
          </HouseholdChartContainer>
        )}
      </TabPanel>
    </Widget>
  );
}

export default SignificantRevenueAccounts;
