import { makeStyles, fade, Theme } from '@material-ui/core';
import clsx from 'clsx';

import IconButton from './IconButton';

// TODO: extract common toggle styles
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
      '& + &': {
        borderLeft: 0,
        marginLeft: 0,
      },
    },
    '&$disabled': {
      color: fade(theme.palette.action.disabled, 0.12),
    },
  },
  /* Pseudo-class applied to the root element if `disabled={true}`. Overrides defaults */
  disabled: {},
  /* Pseudo-class applied to the root element if `selected={true}`. Overrides defaults */
  selected: {},
}));
function ToggleIconButton<TValue>({
  selected,
  className,
  classes = {},
  disabled,
  selectedStyle,
  disabledStyle,
  onChange,
  value,
  onClick,
  children,
  variant,
  ...rest
}: ToggleIconButton<TValue>) {
  const internalClasses = useStyles();
  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(event, value);
      if (event.isDefaultPrevented()) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };
  const {
    selected: selectedClass,
    disabled: disabledClass,
    ...restClasses
  } = classes;

  const renderedProps = {
    className: clsx(
      internalClasses.root,
      classes.root,
      {
        [disabledClass]: disabled,
        [selectedClass]: selected,
      },
      className
    ),
    classes: restClasses,
    onClick: handleChange,
    onChange,
    'aria-pressed': selected,
    value,
    disabled,
    ...rest,
  };

  return variant ? (
    <IconButton {...renderedProps} variant={variant} />
  ) : (
    <IconButton {...renderedProps}>{children}</IconButton>
  );
}

export default ToggleIconButton;
