import { createMuiTheme, fade } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import { ComponentsProps } from '@material-ui/core/styles/props';
import { CSSProperties } from '@material-ui/styles';

// Color naming http://chir.ag/projects/name-that-color/
//              https://www.htmlcsscolor.com/hex/FFFFFF
const namedColors = {
  boulder: '#797979',
  hintOfRed: '#FBF9F9',
  concrete: '#F2F2F2',
  emperor: '#565454',
  zambezi: '#5F5D5D',
  alabaster: '#F8F8F8',
  alto: '#D8D8D8',
  mineShaft: '#2C2C2C',
  tundora: '#424242',
  white: '#fff',
  black: '#000',
  eucalyptus: '#1E9868',
  lightEucalyptus: '#63A289',
  gray: '#706565',
  lightGray: '#999999',
  orange: '#FFA600',
  lightOrange: '#FFCC99',
  sushi: '#8AAB2C',
  lightSushi: '#ABBB7D',
  teal: '#007A80',
  lightTeal: '#669999',
  wildSand: '#F4F4F4',
  mercury: '#e8e8e8',
  navajoWhite: '#FFDFAA',
  cornflowerBlue: '#98C5E3',
};

const colors = {
  ...namedColors,
  background: namedColors.white,
  backgroundContrast: namedColors.wildSand,
  borderDark: namedColors.boulder,
  borderLight: '#979797',
  borderLightest: namedColors.alto,
  primary: '#425460',
  primaryDark: '#192b36',
  primaryLight: '#6e808d',
  lightGrayBorder: '#E4E4E4',
  iconPrimary: namedColors.boulder,
  tableAltRow: namedColors.hintOfRed,
  warn: namedColors.navajoWhite,
  info: namedColors.cornflowerBlue,
  text: {
    primary: '#212020',
  },
  data: {
    backgroundWhite: namedColors.white,
    comparisonWhite: namedColors.wildSand,
    eucalyptus: namedColors.eucalyptus,
    gray: namedColors.gray,
    orange: namedColors.orange,
    sushi: namedColors.sushi,
    teal: namedColors.teal,
    lightEucalyptus: namedColors.lightEucalyptus,
    lightGray: namedColors.lightGray,
    lightOrange: namedColors.lightOrange,
    lightSushi: namedColors.lightSushi,
    lightTeal: namedColors.lightTeal,
  },
};

// this function returns 62.5% of the browsers font size setting
// to allow this trick to work with material ui
// https://dev.to/dan503/comment/4oo0
function getHtmlFontSize() {
  try {
    const elementToMeasure = document.createElement('div');

    elementToMeasure.style.cssText =
      'display:inline-block; padding:0; line-height:1; position:absolute; visibility:hidden; font-size:1rem';
    document.body.appendChild(elementToMeasure);
    const fontSizeString = window.getComputedStyle(elementToMeasure).fontSize;
    const fs = parseInt(fontSizeString.replace('px', ''));
    document.body.removeChild(elementToMeasure);
    return fs;
  } catch {
    // eslint-disable-next-line no-console
    console.warn('Unable to detect browser size, falling back to 10px');
    return 10;
  }
}
const baseFontStyle = {
  fontFamily: '"Helvetica", "Arial", sans-serif',
  fontSize: '1.4rem',
  color: colors.text.primary,
  fontWeight: 400,
};

const baseBorder = {
  borderRadius: 6,
  border: `1px solid ${colors.borderLight}`,
};

const propOverride: ComponentsProps = {
  MuiButton: {
    disableElevation: true,
    disableRipple: true,
  },
};

const baseTheme = createMuiTheme({});

// #region Typography Override
const typographyOverride: TypographyOptions = {
  htmlFontSize: getHtmlFontSize(),
  fontFamily: '"Helvetica", "Arial", sans-serif',
  h1: {
    fontSize: '3.6rem',
    fontWeight: 400,
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: 400,
  },
  h3: {
    fontSize: '2.1rem',
    fontWeight: 400,
  },
  h4: {
    fontSize: '1.8rem',
  },
  body1: {
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
  },
  button: {
    textTransform: 'none',
    lineHeight: '1.4rem',
  },
  caption: {
    fontSize: '1.4rem',
  },
};
// #endregion

// #region Palette Override
const paletteOverride: PaletteOptions = {
  primary: {
    light: colors.primaryLight,
    main: colors.primary,
    dark: colors.primaryDark,
    contrastText: colors.white,
  },
  background: {
    paper: '#fafafa',
    default: colors.white,
  },
  text: {
    primary: colors.text.primary,
  },
  success: {
    contrastText: colors.white,
    main: colors.data.teal,
  },
};
// #endregion

// #region  Theme Override
const themeOverride: Overrides = {
  MuiBackdrop: {
    root: {
      backgroundColor: fade(colors.alto, 0.48),
    },
  },
  MuiInput: {
    root: {
      '&::before': {
        borderBottom: 'none !important',
      },
      '&::after': {
        borderBottom: 'none !important',
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '6px',
      '& $notchedOutline': {
        borderColor: colors.borderLight,
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: colors.mineShaft,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: colors.borderLight,
        },
      },
      '&$focused $notchedOutline': {
        borderColor: colors.mineShaft,
        borderWidth: 1,
      },
    },
    input: {
      padding: baseTheme.spacing(1),
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '1.4rem',
    },
  },
  MuiSelect: {
    select: {
      borderRadius: 6,
      border: `1px solid ${colors.borderLight}`,
      paddingLeft: 8,
      textAlign: 'left',

      '&:focus': {
        borderRadius: 6,
        border: `1px solid ${colors.borderLight}`,
      },
    },
  },
  MuiIconButton: {
    root: {
      ...baseFontStyle,
    },
  },
  MuiButton: {
    containedPrimary: {
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      color: colors.white,
      backgroundColor: colors.data.teal,
      '&:hover': {
        backgroundColor: fade(colors.data.teal, 0.75),
        '@media (hover: none)': {
          backgroundColor: fade(colors.data.teal, 0.75),
        },
      },
    },
    containedSecondary: {
      ...baseBorder,
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      color: colors.white,
      backgroundColor: colors.data.teal,
      '&:hover': {
        backgroundColor: fade(colors.data.teal, 0.75),
        '@media (hover: none)': {
          backgroundColor: fade(colors.data.teal, 0.75),
        },
      },
    },
    outlinedPrimary: {
      ...baseBorder,
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      color: colors.teal,
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    outlinedSecondary: {
      ...baseBorder,
      fontSize: '1.6rem',
      lineHeight: '1.6rem',
      backgroundColor: colors.white,
      color: colors.text.primary,
      '&:hover': {
        ...baseBorder,
        border: `1px solid ${colors.borderLight}`,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
    root: {
      ...baseFontStyle,
    },
    text: {
      padding: 6,
    },
  },
  MuiTableCell: {
    head: {
      padding: 7,
      fontWeight: 'bold',
    },
    body: {
      padding: 7,
      border: 'none',
    },
  },
  MuiTablePagination: {
    toolbar: {
      background: 'inherit',
    },
  },
  MuiInputLabel: {
    root: {
      paddingLeft: baseTheme.spacing(1),
    },
    shrink: {
      paddingLeft: 0,
    },
  },
  MuiListSubheader: {
    root: {
      lineHeight: 1.4,
      fontWeight: 'bold',
      color: colors.text.primary,
      fontSize: '1.4rem',
    },
    gutters: {
      paddingLeft: baseTheme.spacing(1),
    },
  },
  MuiListItem: {
    root: {
      outline: 'none !important',
    },
  },
  MuiLink: {
    root: {
      cursor: 'pointer',
    },
  },
  MuiTabs: {
    root: {
      height: 39,
      minHeight: 39,
    },
    indicator: {
      display: 'none',
    },
    flexContainer: {
      position: 'relative',
      borderBottom: `1px solid ${colors.borderLightest}`,
    },
  },
  MuiTab: {
    root: {
      position: 'relative',
      top: 1,
      height: 38,
      minHeight: 38,
      fontSize: '2.1rem',
      padding: '6px 39px',
      '&$selected': {
        borderRadius: '16px 16px 0 0',
        border: `1px solid ${colors.borderLightest}`,
        borderBottom: `1px solid ${colors.background}`,
        fontWeight: 'bold',
      },
    },
    textColorPrimary: {
      color: colors.black,
      '&.Mui-selected': {
        color: colors.teal,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: namedColors.black,
    },
  },
};
// #endregion

const styles = {
  button: {
    accept: {
      padding: baseTheme.spacing(1, 4),
      backgroundColor: colors.data.teal,
      color: colors.white,
      '&:hover': {
        backgroundColor: fade(colors.data.teal, 0.75),
      },
    },
    cancel: {
      // TODO
      backgroundColor: '#A80400',
      color: '#FFF',
      '&:hover': {
        backgroundColor: fade('#A80400', 0.75),
      },
    },
    action: (backgroundColor?: string) => ({
      padding: '6px',
      margin: baseTheme.spacing(1),
      backgroundColor: backgroundColor ?? colors.white,
      '&:hover': {
        backgroundColor: fade(backgroundColor ?? colors.white, 0.75),
      },
    }),
  },
};

const fonts: Record<string, CSSProperties> = {
  default: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontWeight: 300,
    lineHeight: '1.5em',
  },
  title: {
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '2.4rem',
  },
  infographicHeading: {
    fontSize: '1.8rem',
  },
  infographicLabel: {
    fontFamily: '"Arial","Helvetica", sans-serif',
    fontSize: '1.6rem',
  },
  infographicTickLabelSm: {
    fontFamily: '"Helvetica","Arial", sans-serif',
    fontSize: '1.4rem',
  },
  infographicTickLabelLg: {
    fontFamily: '"Helvetica","Arial", sans-serif',
    fontSize: '2.1rem',
  },
  infographicSelectionHeadingSm: {
    fontFamily: '"Arial", "Helvetica",sans-serif',
    fontSize: '1.4rem',
  },
  infographicSelectionHeadingLg: {
    fontFamily: '"Arial", "Helvetica",sans-serif',
    fontSize: '2.1rem',
  },
  tooltip: {
    ...baseTheme.typography.h3,
  },
  graphAxis: {
    ...baseTheme.typography.h3,
  },
  eyebrow: {
    fontSize: '2.1rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  headline: {
    fontSize: '6rem',
    fontWeight: 700,
  },
};

export const themeExtensions = {
  toolbarHeight: 118,
  pageWrapper: {
    headerHeight: 90,
    spacing: {
      padding: 3,
    },
  },
  table: {
    pager: {
      height: 52,
    },
    cell: {
      padding: 7,
    },
  },
  color: colors,
  font: fonts,
  boxShadow: '0px 2px 4px 0px rgba(0,0,0,.5)',
  borders: {
    light: `1px solid ${colors.borderLight}`,
    dark: `1px solid ${colors.borderDark}`,
  },
  styles,
};

const theme = createMuiTheme({
  props: propOverride,
  palette: paletteOverride,
  mixins: {
    toolbar: {
      minHeight: 56,
      backgroundColor: colors.primaryLight,
    },
  },
  typography: typographyOverride,
  overrides: themeOverride,
  extensions: themeExtensions,
});

export type ThemeExtensions = typeof themeExtensions;
export default theme;
