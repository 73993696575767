import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  AllProductionCategoriesFragment,
  GetPayEarnedSummaryQuery,
  PayEarnedModel,
} from '../@types';

export const PRODUCTION_CATEGORIES_FRAGMENT = gql`
  fragment AllProductionCategories on PayEarnedTotalModel {
    Commissions: commission
    Direct: direct
    Fees: fee
    Total: total
  }
`;

export const GET_PAY_EARNED_SUMMARY = gql`
  query GetPayEarnedSummary($repIds: [Int!]!) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregatePayEarned(repIds: $repIds) {
        day {
          Commissions: commission
          Direct: direct
          Fees: fee
          Total: total
        }
        month {
          Commissions: commission
          Direct: direct
          Fees: fee
          Total: total
        }
        year {
          Commissions: commission
          Direct: direct
          Fees: fee
          Total: total
        }
        t12 {
          Commissions: commission
          Direct: direct
          Fees: fee
          Total: total
        }
      }
    }
  }
`;

export const usePayEarnedSummaryChart = () => {
  const { data, loading, error } = useQuery<GetPayEarnedSummaryQuery>(
    GET_PAY_EARNED_SUMMARY
  );
  const queryResult = data?.viewer?.aggregatePayEarned;

  const payEarnedSummaryData: PayEarnedData | undefined = useMemo(() => {
    if (!queryResult) return undefined;
    const mapData = (dataKey: keyof PayEarnedModel) => {
      const timeFrameData = queryResult[
        dataKey
      ] as AllProductionCategoriesFragment;

      return Object.entries(timeFrameData).reduce(
        (acc, [key, value]) => {
          if (key.startsWith('__') || key === 'Total') return acc;
          acc.metadata.total = timeFrameData.Total;
          acc.chartData.push({
            key,
            data: value,
          });
          return acc;
        },
        { metadata: { total: 0 }, chartData: [] as any }
      );
    };
    const result = {
      Day: mapData('day'),
      Month: mapData('month'),
      Year: mapData('year'),
      T12: mapData('t12'),
    };
    return result;
  }, [queryResult]);

  return { payEarnedSummaryData, loading, error };
};
