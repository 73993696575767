import React, { PropsWithChildren } from 'react';
import { Feature, useFeatureFlagContext } from './FeatureFlagContext';

export type FeatureFlagProps<TFeatureNames extends string = Feature> =
  PropsWithChildren<{
    name: TFeatureNames;
    showIfDisabled?: boolean;
  }>;

// Kudos to Chris for the template
function FeatureFlag<TFeatureNames extends string = Feature>({
  name,
  showIfDisabled = false,
  children,
}: FeatureFlagProps<TFeatureNames>) {
  const context = useFeatureFlagContext<TFeatureNames>();

  let showComponent = context[name];
  if (showIfDisabled) showComponent = !showComponent;

  if (!showComponent) return null;
  return <>{children}</>;
}

export default FeatureFlag;
