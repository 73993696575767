const devDefaults: AppConfig = {
  oidcRedirect: 'http://localhost:3000/callback',
  oidcAuthority: 'https://edgecodevb2c.b2clogin.com',
  oidcTenant: 'edgecodevb2c.onmicrosoft.com',
  oidcFlow: 'B2C_1_SignUpSignIn1_Default',
  oidcResetPasswordFlow: 'B2C_1_ResetPassword_Default',
  oidcClient: '5684bbf2-07dc-49dc-bac6-6eef546374c4',
  apiDomain: 'edgeco-dev.com',
  deployEnv: process.env.REACT_APP_DEPLOY_ENV,
  appVersion: 'DEBUG',
  appName: 'wealth-management',
};

export default window.AppConfig ?? devDefaults;
