import TuneIcon from '@material-ui/icons/Tune';
import { isMobile } from 'react-device-detect';
import useRepSelection from 'edgeco/hooks/useRepSelection';

import { Button, makeStyles } from '@material-ui/core';
import useModal from './useModal';

const useStyles = makeStyles(({ extensions: { color }, breakpoints }) => ({
  tuneIcon: {
    width: 45,
    height: 40,
    fill: color.black,
    marginLeft: '0.5rem',

    [breakpoints.down('md')]: {
      width: 44,
      height: 44,
      marginRight: '1.0rem',
      marginBottom: 5,
    },
  },
  repSelectionButton: {
    width: '300px',
    marginRight: '45px',
    borderRadius: 16,
    borderWidth: 3,
    height: '52px',
    fontSize: '2.4rem',
    paddingLeft: '20px',
    paddingRight: '20px',
    '&:hover': {
      borderWidth: 1,
      backgroundColor: color.background,
    },
  },
}));

function RepSelectionModal() {
  const classes = useStyles();
  const {
    TransferComponent,
    applyRepSelection,
    resetSelection,
    clearSelection,
    selected,
  } = useRepSelection();

  const { Modal, setOpen: setRepSelectionModalOpen } = useModal({
    isDraggable: false,
    title: 'Create Your Dashboard',
    content: TransferComponent,
    cancelButton: {
      text: 'Clear All',
      onClick: () => clearSelection(),
    },
    submitButton: {
      text: 'Apply Changes',
      disabled: selected.length === 0,
    },
    onSubmit: () => {
      setRepSelectionModalOpen(false);
      applyRepSelection();
    },
    onCancel: () => resetSelection(),
  });

  const handleRepSelectionModal = () => {
    setRepSelectionModalOpen(true);
  };

  return (
    <div>
      {isMobile ? (
        <TuneIcon
          className={classes.tuneIcon}
          onClick={handleRepSelectionModal}
        />
      ) : (
        <Button
          className={classes.repSelectionButton}
          variant="outlined"
          color="primary"
          onClick={handleRepSelectionModal}
        >
          Create Your Dashboard
        </Button>
      )}
      {Modal}
    </div>
  );
}

export default RepSelectionModal;
