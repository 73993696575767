/* eslint-disable no-console */
import { LogLevel } from './LogLevel';

/**
 * Simple logging functions, currently it is only used as an abstraction to apply LogLevels
 * over the built in console functions. We could add sinks or something similar in the future.
 */
export default class Logger {
  logLevel: LogLevel;

  name: string | undefined;

  context: LogContext;

  levelNames: Record<LogLevel, string>;

  constructor(context: LogContext, name?: string) {
    this.context = context;
    this.name = name;
    this.levelNames = {
      [LogLevel.Error]: 'ERR',
      [LogLevel.Warning]: 'WRN',
      [LogLevel.Info]: 'INF',
      [LogLevel.Debug]: 'DBG',
      [LogLevel.Trace]: 'TRC',
    };
    this.logLevel = this.getLogLevel();
  }

  /**
   * Log levels work the same way they do in dotnet, it will try and match the closest name before falling back to the default.
   *
   * ex: a logger with the name components.table.filters would look for the following in order:
   * components.table.filters
   * components.table
   * components
   * default
   * @returns The log level for this logger
   */
  getLogLevel() {
    const defaultLevel = this.context.logLevel.default;
    if (!this.name) return defaultLevel;
    const nameParts = this.name.split('.');
    let level;
    while (level === undefined && nameParts.length) {
      level = this.context.logLevel[nameParts.join('.')];
      nameParts.pop();
    }
    return level ?? defaultLevel;
  }

  static getLogger(messageLevel: LogLevel) {
    const loggers: Partial<Record<LogLevel, typeof console.log>> = {
      [LogLevel.Error]: console.error,
      [LogLevel.Warning]: console.warn,
    };
    return loggers[messageLevel] ?? console.info;
  }

  log(logLevel: LogLevel, ...args: any[]) {
    if (this.logLevel >= logLevel) {
      Logger.getLogger(logLevel)(`[${this.levelNames[logLevel]}]:`, ...args);
    }
  }

  trace(...args: any[]) {
    this.log(LogLevel.Trace, ...args);
  }

  debug(...args: any[]) {
    this.log(LogLevel.Debug, ...args);
  }

  info(...args: any[]) {
    this.log(LogLevel.Info, ...args);
  }

  warn(...args: any[]) {
    this.log(LogLevel.Error, ...args);
  }

  error(...args: any[]) {
    this.log(LogLevel.Warning, ...args);
  }
}
