import {
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  YAxisProps,
} from 'recharts';
import numeral from 'numeral';
import clsx from 'clsx';

import { themeExtensions } from '../../../assets/theme';
import colorScheme from '../colorScheme';
import { useRechartStyles } from '../common/classes';
import { XAxisType } from '../types';
import Bar from '../common/Bar';
import { YAxis, XAxis } from '..';

type BarChartProps<TData extends object> = WithTooltip<TData> & {
  data: ReadonlyArray<TData>;
  series: Series[];
  xAxisType?: XAxisType;
  width?: number | string;
  height?: number;
  yAxis?: React.ReactElement<YAxisProps>;
  xAxis?: React.ReactElement<XAxisProps>;
  hideTopGridLine?: boolean;
};

function BarChartWrapper<TData extends object>({
  data,
  series,
  xAxisType = 'time',
  width = '100%',
  height = 300,
  yAxis,
  xAxis,
  hideTopGridLine = true,
  ...rest
}: BarChartProps<TData>) {
  const classes = useRechartStyles();
  const getColor = (colorSeries: Series, keyIndex: any, cellIndex: any) => {
    return {
      fill:
        colorSeries.fill ??
        colorScheme.forIndex(
          xAxisType === 'time' ? keyIndex : cellIndex,
          xAxisType === 'time' ? series!.length : data!.length
        ),
      activeFill:
        colorSeries.activeFill ??
        colorScheme.forIndex(
          xAxisType === 'time' ? keyIndex : cellIndex,
          xAxisType === 'time' ? series!.length : data!.length,
          true
        ),
    };
  };
  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        className={clsx(hideTopGridLine && classes.hideTopGridLine)}
        data={data}
      >
        <CartesianGrid
          stroke={themeExtensions.color.borderLight}
          strokeWidth={1}
          vertical={false}
        />

        {xAxis ?? <XAxis minTickGap={0} />}
        {yAxis ?? (
          <YAxis
            tickFormatter={(p) => {
              return numeral(p).format('$# a');
            }}
          />
        )}
        {series?.map((s, keyIndex) => {
          return (
            <Bar
              key={`bar-${keyIndex}`}
              {...rest}
              dataKey={s.dataKey}
              metaKey={s.metaKey}
            >
              {data?.map((_, cellIndex) => {
                return (
                  <Cell
                    key={`cell-${cellIndex}`}
                    style={s.style}
                    width={40}
                    stroke={themeExtensions.color.borderLight}
                    strokeWidth={1}
                    {...getColor(s, keyIndex, cellIndex)}
                  />
                );
              })}
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartWrapper;
