import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'center',
    width: '1.25em',
    height: '1em',
    verticalAlign: '-0.125em',
    '& svg': {
      position: 'absolute',
      transformOrigin: 'center center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
  },
});

function LayeredIcon({
  className,
  children,
  ...rest
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  const { wrapper } = useStyles();
  return (
    <span className={clsx(wrapper, className)} {...rest}>
      {children}
    </span>
  );
}

export default LayeredIcon;
