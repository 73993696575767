import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import LegendEntry from './LegendEntry';
import colorScheme from '../colorScheme';

const useStyles = makeStyles<Theme, { vertical: boolean }>({
  root: ({ vertical }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: vertical ? 'column' : 'row',
  }),
});

export type CustomLegendProps = {
  series: readonly KeyValueTypes[];
  className?: string;
  vertical?: boolean;
};

function CustomLegend({
  series,
  className,
  vertical = false,
}: CustomLegendProps) {
  const classes = useStyles({ vertical });
  return (
    <div className={clsx(classes.root, className)}>
      {series.map((label, index) => {
        return (
          <LegendEntry
            key={`legend-entry-${label}`}
            label={label}
            color={colorScheme.forIndex(index, series.length)}
          />
        );
      })}
    </div>
  );
}

export default CustomLegend;
