import { makeStyles } from '@material-ui/core';
import MissingData from 'edgeco/components/data-visualization/MissingData';

import clsx from 'clsx';
import { Household } from 'edgeco/types/account';
import CreateHouseholdsButton from './CreateHouseholdsButton';

const useStyles = makeStyles({
  missingHouseholdsWrapper: {
    margin: '55px auto 20px',
  },
  missingHouseholdsMessage: {
    width: '100% !important',
    margin: 'auto',
  },
  createHouseholdWrapper: {
    margin: 'auto',
    width: 'max-content',
  },
});

type Props = React.PropsWithChildren<{
  households: Household[];
}>;

const HouseholdChartContainer = ({ children, households }: Props) => {
  const classes = useStyles();
  const noHouseholds = !households?.length;
  return (
    <>
      {noHouseholds && (
        <div className={classes.missingHouseholdsWrapper}>
          <MissingData
            className={classes.missingHouseholdsMessage}
            size="sm"
            description="You do not have any household data. Please create your households."
            hideCaption
          ></MissingData>
        </div>
      )}
      <div className={clsx(noHouseholds && classes.createHouseholdWrapper)}>
        <CreateHouseholdsButton households={households} />
      </div>
      {!noHouseholds && <div>{children}</div>}
    </>
  );
};

export default HouseholdChartContainer;
