import React, { ReactNode } from 'react';
import { useUncontrolled } from 'uncontrollable';

export function hasValue(value: any) {
  return value != null && !(Array.isArray(value) && value.length === 0);
}

export function isFilled(obj: any, SSR = false) {
  return (
    obj &&
    ((hasValue(obj.value) && obj.value !== '') ||
      (SSR && hasValue(obj.defaultValue) && obj.defaultValue !== ''))
  );
}

export function areEqualValues(a: any, b: any) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
}

export function renderValue(
  childrenArray: React.ReactElement<
    React.PropsWithChildren<
      {
        value: string;
        'aria-selected'?: string;
      } & React.HTMLAttributes<any>
    >
  >[],
  value: SelectValueType,
  renderValueProp: undefined | ((value: unknown) => ReactNode),
  displayEmpty?: boolean,
  multiple?: boolean
) {
  let displaySingle: ReactNode;
  const displayMultiple: any[] = [];

  // No need to display any value if the field is empty.
  if (isFilled({ value }) || displayEmpty) {
    if (renderValueProp) {
      return renderValueProp(value);
    }
  }

  childrenArray.forEach((child) => {
    if (!React.isValidElement(child)) {
      return;
    }

    if (multiple) {
      if (!Array.isArray(value)) {
        throw new Error(
          'Material-UI: The `value` prop must be an array ' +
            'when using the `Select` component with `multiple`.'
        );
      }

      if (value.some((v) => areEqualValues(v, child.props.value))) {
        displayMultiple.push(child.props.children);
      }
    } else if (areEqualValues(value, child.props.value)) {
      displaySingle = child.props.children;
    }
  });

  return multiple ? displayMultiple.join(', ') : displaySingle;
}

export function useConfirmSelectState(props: ConfirmSelectProps) {
  const controlledProps = useUncontrolled(props, {
    editValue: 'setEditValue',
    open: 'setOpen',
    value: 'setValue',
  }) as RequireSome<
    ConfirmSelectProps,
    'setEditValue' | 'setOpen' | 'setValue'
  >;

  const { multiple } = controlledProps;
  const {
    value = multiple ? [] : '',
    setValue,
    editValue = multiple ? [] : '',
    onEditChange,
    onChange,
    open = false,
    setOpen,
    setEditValue,
  } = controlledProps;

  const isArray = Array.isArray(value);
  const valueArray = value as [];
  const editValueArray = editValue as [];

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    child?: React.ReactNode
  ) => {
    setEditValue(event.target.value as SelectValueType);
    onEditChange?.(event, child);
  };

  const handleCancel = (event: React.ChangeEvent<{}>) => {
    const newValue = isArray ? [...valueArray] : value;
    Object.defineProperty(event, 'target', {
      writable: true,
      value: { value: newValue },
    });
    setOpen(false);
    setEditValue(newValue);
    onEditChange?.(event as React.ChangeEvent<{ value: unknown }>);
  };

  const handleSubmit = (
    event: React.ChangeEvent<{}>,
    child?: React.ReactNode
  ) => {
    const newValue = isArray ? [...editValueArray] : editValue;
    setValue?.(newValue as SelectValueType); // TODO: try an fix this cast
    Object.defineProperty(event, 'target', {
      writable: true,
      value: { ...event.target, value: newValue },
    });
    onChange?.(event as React.ChangeEvent<{ value: unknown }>, child);
    setOpen(false);
  };

  const handleClose = (event: React.ChangeEvent<{}>) => {
    // close is fired when the select isn't a multi-select, check to see
    // if this event is on an item or outside of the popover
    if ((event.target as any).hasAttribute?.('data-value')) {
      return; // an item was clicked, don't close
    }
    setOpen(false);
    setEditValue(value);
    Object.defineProperty(event, 'target', {
      writable: true,
      value: { value },
    });
    onEditChange?.(event as React.ChangeEvent<{ value: unknown }>);
  };
  return {
    ...controlledProps,
    open,
    setOpen,
    editValue,
    setEditValue,
    value: value as SelectValueType,
    setValue,
    handleClose,
    handleSubmit,
    handleCancel,
    handleChange,
  };
}
