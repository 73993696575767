import { CartesianGrid } from 'recharts';
import { themeExtensions } from '../../../assets/theme';
import { renderChartWrapper } from './renderChartWrapper';

function Grid({ vertical, stroke, ...rest }: GridProps) {
  return (
    <CartesianGrid
      vertical={vertical ?? false}
      stroke={stroke ?? themeExtensions.color.borderLight}
      {...rest}
    />
  );
}

export default renderChartWrapper(CartesianGrid, Grid, {
  vertical: undefined,
  stroke: undefined,
});
