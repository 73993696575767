import { Link as MuiLink, LinkProps } from '@material-ui/core';

function Link({
  underline = 'always',
  color = 'inherit',
  rel = 'noopener',
  target = '_blank',
  ...other
}: LinkProps) {
  return (
    <MuiLink
      {...other}
      color={color}
      rel={rel}
      target={target}
      underline={underline}
    ></MuiLink>
  );
}

export default Link;
