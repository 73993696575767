import { AuthError, AuthenticationParameters } from 'msal';
import { useCallback, useEffect, useState } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { auth, accessScopes } from '../config/oidcConfig';

export const LOGIN_REQUEST_URL_KEY = 'LOGIN_REQUEST_URL_KEY';

const useAuth = () => {
  const [authState, setAuthState] = useState(auth.authenticationState);
  const [authError, setAuthError] = useState(auth.getError());
  const [accountInfo, setAccountInfo] = useState(auth.getAccountInfo());

  const login = useCallback(
    ({
      navigateToLoginRequestUrl = true,
      ...rest
    }: AuthenticationParameters & {
      navigateToLoginRequestUrl?: boolean;
    } = {}) => {
      if (navigateToLoginRequestUrl) {
        localStorage.setItem(
          LOGIN_REQUEST_URL_KEY,
          window.location.href.slice(window.location.origin.length)
        );
      } else {
        localStorage.removeItem(LOGIN_REQUEST_URL_KEY);
      }
      auth.login(rest);
    },
    []
  );
  const logout = useCallback(() => {
    if (authState !== AuthenticationState.Authenticated) {
      const postLogout = auth.getPostLogoutRedirectUri();
      window.location.href = postLogout ?? window.location.origin;
      return;
    }
    auth.logout();
  }, [authState]);

  const getAccessToken = useCallback(
    async (parameters?: AuthenticationParameters) => {
      return auth.getAccessToken(parameters);
    },
    []
  );

  const setAuthenticationState = useCallback(
    (newState: AuthenticationState) => {
      if (newState !== authState) {
        setAuthenticationState(newState);

        if (newState === AuthenticationState.Unauthenticated && !authError) {
          login();
        }
      }
    },
    [authError, authState, login]
  );

  const setError = useCallback((newError: AuthError | null) => {
    setAuthError(newError);
  }, []);

  useEffect(() => {
    auth.registerAuthenticationStateHandler(setAuthState);
    auth.registerAcountInfoHandler(setAccountInfo); // Spelling mistake in library react-aad-msal
    auth.registerErrorHandler(setError);
    return () => {
      auth.unregisterAuthenticationStateHandler(setAuthState);
      auth.unregisterAccountInfoHandler(setAccountInfo);
      auth.unregisterErrorHandler(setError);
    };
  }, [setError]);
  return {
    login,
    logout,
    authState,
    accountInfo,
    accessScopes,
    authError,
    getAccessToken,
  };
};

export default useAuth;
