export type FilterValue = string[] | string;

export type ProductionDetailFilter = Partial<
  Record<ProductionCategory, FilterValue>
> & {
  datePeriod?: FilterValue;
  startDate: Date;
  endDate?: Date;
};

export enum ProductionDetailLinkType {
  DetailLink,
  ReviewButton,
  Custom,
}
