import React, { useState } from 'react';
import useModal from 'edgeco/components/modal/useModal';
import { Button, makeStyles, Slide } from '@material-ui/core';
import clsx from 'clsx';

import { Household } from 'edgeco/types/account';

import {
  useAddAccountsToHousehold,
  useAddHousehold,
  useDeleteHousehold,
  useRemoveAccountFromHousehold,
  useRenameHousehold,
} from 'edgeco/graphql/user-profile/mutations';
import HouseholdList from './HouseholdList';
import AccountList from './HouseholdAccountList';
import { householdSizes } from './shared';

const useStyles = makeStyles(({ extensions: { color } }) => ({
  createHousehold: {
    width: '300px',
    borderRadius: 16,
    borderWidth: 3,
    height: 43,
    marginBottom: 30,
    '&:hover': {
      borderWidth: 1,
      backgroundColor: '#fff',
    },
    '&$noHouseholds': {
      marginBottom: 60,
    },
  },
  noHouseholds: {},
  root: {
    overflow: 'hidden',
    position: 'relative',
    height: householdSizes.modalHeight,
    background: color.background,
    width: 450,
  },
  accounts: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

type Props = {
  households: Household[];
};

function CreateHouseholdsButton({ households }: Props) {
  const classes = useStyles();
  const { addHousehold } = useAddHousehold();
  const { addAccountsToHousehold } = useAddAccountsToHousehold();
  const { removeAccountFromHousehold } = useRemoveAccountFromHousehold();
  const { renameHousehold } = useRenameHousehold();
  const { deleteHousehold } = useDeleteHousehold();

  const noHouseholds = !households?.length;

  const [householdsOpen, setHouseholdsOpen] = useState(false);
  const [addingAccounts, setAddingAccounts] = useState(false);
  const [household, setHousehold] = useState<Household>();

  const [newAccounts, setNewAccounts] = useState(new Set<string>());

  const { Modal } = useModal({
    title: 'Create Your Households',
    open: householdsOpen,
    onSubmit: () => {
      setAddingAccounts(false);
      addAccountsToHousehold(household!.id, [...newAccounts]);
    },
    onCancel: () => {
      setHouseholdsOpen(false);
      setAddingAccounts(false);
    },
    content: (
      <div className={classes.root}>
        <Slide direction="right" in={!addingAccounts}>
          <div>
            <HouseholdList
              households={households}
              onAddAccounts={(householdToAddTo) => {
                setAddingAccounts(true);
                setHousehold(householdToAddTo);
                setAddingAccounts(true);
              }}
              onDeleteAccount={(householdToDeleteFrom, account) => {
                removeAccountFromHousehold(householdToDeleteFrom!.id, account);
              }}
              onAddHousehold={(name) => addHousehold(name)}
              onDeleteHousehold={(id) => deleteHousehold(id)}
              onRenameHousehold={(id, name) => renameHousehold(id, name)}
            />
          </div>
        </Slide>
        <Slide direction="left" in={addingAccounts} unmountOnExit={true}>
          <div className={classes.accounts}>
            {household && (
              <AccountList
                height={householdSizes.modalHeight}
                selectedHousehold={household}
                households={households}
                selected={newAccounts}
                onChange={setNewAccounts}
              />
            )}
          </div>
        </Slide>
      </div>
    ),
    submitButton: {
      text: 'Add',
      style: {
        visibility: addingAccounts ? 'visible' : 'hidden',
      },
    },
    defaultOpen: true,
  });

  return (
    <>
      {Modal}
      <Button
        variant="outlined"
        color="primary"
        className={clsx(
          classes.createHousehold,
          noHouseholds && classes.noHouseholds
        )}
        onClick={() => setHouseholdsOpen(true)}
      >
        Create Your Households
      </Button>
    </>
  );
}

export default CreateHouseholdsButton;
