import { TextField, TextFieldProps, useControlled } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

(window as any).moment = moment;
export type DateInputProps = {
  value?: Moment;
  defaultValue?: Moment;
  onChange?: (value: Moment) => void;
  displayFormat?: string;
  inputFormats?: string[];
  className?: string;
  textFieldProps?: TextFieldProps;
  separators?: string[];
};

function DateInput({
  value: valueProp,
  defaultValue,
  onChange: onChangeProp,
  inputFormats: inputFormatsProp = [
    'MM/DD/YYYY',
    'M/DD/YYYY',
    'MM/D/YYYY',
    'M/D/YYYY',
  ],
  className,
  textFieldProps,
  separators = ['/'],
  ...rest
}: DateInputProps) {
  const { displayFormat = inputFormatsProp[0] ?? 'MM/DD/YYYY' } = rest;
  const inputFormats = useMemo(
    () => [displayFormat, ...inputFormatsProp],
    [displayFormat, inputFormatsProp]
  );

  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValue ?? moment(),
    name: 'DateInput',
    state: 'value',
  });

  const parseExact = useCallback(
    (val: string) => moment(val, inputFormats, true),
    [inputFormats]
  );

  const [stringValue, setStringValue] = useState(value.format(displayFormat));
  const [showError, setShowError] = useState<boolean>(false);
  const hasError = useMemo(
    () => !parseExact(stringValue).isValid(),
    [parseExact, stringValue]
  );

  useEffect(() => {
    setStringValue(value.format(displayFormat));
  }, [displayFormat, value]);

  const validCharRegex = useMemo(() => {
    const separatorCharacters = separators
      .join()
      .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'); // escape any regex characters
    return RegExp(`^[0-9${separatorCharacters}]*$`);
  }, [separators]);

  const onChange = useCallback(
    ({
      target: { value: eventValue },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!validCharRegex.test(eventValue)) return;

      setStringValue(eventValue);
      const newVal = parseExact(eventValue);

      if (newVal.isValid()) {
        setValue(newVal);
        onChangeProp?.(newVal);
      }
    },
    [onChangeProp, parseExact, setValue, validCharRegex]
  );

  const onFocusChange = useCallback(() => setShowError(hasError), [hasError]);

  return (
    <TextField
      {...textFieldProps}
      error={hasError && showError}
      className={className}
      margin="dense"
      variant="outlined"
      value={stringValue}
      onChange={onChange}
      onFocus={onFocusChange}
      onBlur={onFocusChange}
    />
  );
}

export default DateInput;
