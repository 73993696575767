import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { globalHistory } from '@reach/router';
import appConfig from './appConfig';

const reactPlugin = new ReactPlugin();
const disableAppInsights = process.env.REACT_APP_DEPLOY_ENV !== 'production';

const tagInitializer = (envelope: ITelemetryItem) => {
  if (!envelope.tags) {
    envelope.tags = [];
  }

  envelope.tags['ai.cloud.role'] = 'Wealth Management UI';
  envelope.tags['ai.cloud.roleInstance'] = appConfig.apiDomain;
  envelope.tags['ai.cloud.roleEnvironment'] = process.env.REACT_APP_DEPLOY_ENV;
  envelope.tags[
    'ai.cloud.appVersion'
  ] = `${appConfig.appName}-${appConfig.appVersion}`;
};
// TODO: Hide Instrumentation Key
// ! If fetch requests begin having sensitive data, we may want to disable logging
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: 'ce1dc47f-ff36-465e-b4d3-12d222cf6833',
    maxBatchInterval: 15000,
    disableFetchTracking: false,
    disableAjaxTracking: false,
    extensions: [reactPlugin],
    disableCorrelationHeaders: false,
    correlationHeaderExcludedDomains: ['visualstudio.com'], // the post command for telemetry doesn't except the headers.
    enableCorsCorrelation: true, // Necessary for localhost debugging, might be necessary in prod due to subdomain certificate provisioning
    disableTelemetry: disableAppInsights,
    extensionConfig: {
      [reactPlugin.identifier]: { history: globalHistory },
    },
  },
});

ai.loadAppInsights();

ai.addTelemetryInitializer(tagInitializer);

export { ai, reactPlugin };
