import { useMutation } from '@apollo/client';

type MutationFn = typeof useMutation;
export const useUserProfileMutation: MutationFn = <TMutation, TVariables>(
  mutation: any,
  options: any
) =>
  useMutation<TMutation, TVariables>(mutation, {
    ...options,
    context: {
      ...options?.context,
      clientName: 'user-profile',
    },
  });
