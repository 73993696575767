import { makeStyles } from '@material-ui/core';

import { themeExtensions } from '../../../../assets/theme';

type Props = {
  direction?: 'up' | 'down';
  chevronAnchor?: 'left' | 'right';
  chevronWidth?: number;
};
const useStyles = makeStyles<EdgeCoTheme, Props>(() => ({
  chevron: ({ chevronWidth }) => ({
    width: `${chevronWidth}px`,
    height: '25px',
  }),
  line: ({ chevronWidth }) => ({
    width: `calc(100% - ${chevronWidth}px)`,
    height: '100%',
  }),
  svgCore: {
    fill: 'none',
    stroke: `${themeExtensions.color.borderLight}`,
    strokeWidth: '10',
    width: '100%',
    height: '100%',
  },
  relativeContainer: {
    width: '100%',
    height: '0',
    position: 'relative',
  },
  absoluteContainer: {
    top: '-12px',
    width: '100%',
    height: '25px',
    position: 'absolute',
  },
}));

export const DropdownLineWithChevron = (props: Props) => {
  const {
    direction = 'down',
    chevronAnchor = 'left',
    chevronWidth = 25,
  } = props;
  const classes = useStyles({ chevronWidth, ...props });
  const chevronY = direction === 'up' ? 10 : 190;
  const chevron = (
    <span>
      <svg className={classes.chevron} viewBox="0 0 200 200">
        <polyline
          points={`0 100, 100 ${chevronY}, 200 100`}
          className={classes.svgCore}
        />
      </svg>
    </span>
  );
  return (
    <div className={classes.relativeContainer}>
      <div className={classes.absoluteContainer}>
        {chevronAnchor === 'left' && chevron}
        <span>
          <svg
            preserveAspectRatio="none"
            className={classes.line}
            viewBox="0 0 1875 200"
          >
            <polyline points={'0 100,  1875 100'} className={classes.svgCore} />
          </svg>
        </span>
        {chevronAnchor === 'right' && chevron}
      </div>
    </div>
  );
};
