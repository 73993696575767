import React, { useMemo } from 'react';
import { Grid, makeStyles, Typography, Container } from '@material-ui/core';
import numeral from 'numeral';
import { ProductionCategoryValues } from 'edgeco/types';
import moment from 'moment';

import { ALL_FILTERS } from 'edgeco/components/data-visualization/Table/constants';
import { useTrackComponent } from 'edgeco/hooks/AppInsights';
import {
  DonutChart,
  DonutChartProps,
  DonutChartTotal,
} from '../../../components/data-visualization';
import { themeExtensions as thmExt } from '../../../assets/theme';
import { formatDate, periodToRange } from '../../../libs';

import {
  ProductionDetailLinkType,
  ProductionDetailLink,
} from '../../../components/routing';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  title: {
    ...thmExt.font.infographicHeading,
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  tooltipLabel: {
    fontWeight: 'bold',
  },
  tooltipLinkContainer: {
    marginTop: 5,
  },
  reviewButtonRoot: {
    marginTop: theme.spacing(2),
    width: 200,
  },
  reviewButton: {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },
}));

type PeriodContainerProps = Omit<DonutChartProps, 'data'> & {
  chartShape: ChartNestedDataShape;
  period: DatePeriod;
  totalLabel: string;
};

function PeriodContainer({
  chartShape,
  totalLabel,
  period,
}: PeriodContainerProps) {
  const classes = useStyles();
  const track = useTrackComponent(`Dashboard - PayEarned - ${period}`);

  // once we are actually making a request for the data, we'll want to capture
  // the date at that time and add it to the dependency list
  const date = useMemo(() => moment(), []);
  const [start, end] = periodToRange(period, date);
  let linkPeriod: DatePeriod | 'Custom' = period;
  if (period === 'Day') linkPeriod = 'Custom';
  return (
    <Grid
      onMouseOver={track}
      onClick={track}
      item
      md={3}
      sm={6}
      xs={12}
      className={classes.root}
    >
      <div>
        <div className={classes.title}>
          {formatDate(period, start.toDate())}
        </div>
        <DonutChart
          data={chartShape.chartData}
          hideTooltip={true}
          inner={
            <DonutChartTotal
              total={chartShape.metadata.total}
              description={totalLabel}
            />
          }
          legend="none"
          isHoverEnabled={true}
          tooltipOptions={{ interactive: true }}
          getTooltipContent={({ value, data }) => {
            if (!data) return null;
            return (
              <>
                <Typography className={classes.tooltipLabel}>
                  {numeral(value).format('$0,0')}
                </Typography>
                <Typography className={classes.tooltipLabel}>
                  {data.key} Posted
                </Typography>
                <div className={classes.tooltipLinkContainer}>
                  <ProductionDetailLink
                    filter={
                      {
                        [data.key as string]: ALL_FILTERS,
                        datePeriod: linkPeriod,
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                      } as any
                    }
                    linkType={ProductionDetailLinkType.DetailLink}
                  />
                </div>
              </>
            );
          }}
        />
        <Container className={classes.reviewButtonRoot}>
          <ProductionDetailLink
            className={classes.reviewButton}
            linkType={ProductionDetailLinkType.ReviewButton}
            filter={{
              datePeriod: linkPeriod,
              ...ProductionCategoryValues.reduce((acc, c) => {
                acc[c] = ALL_FILTERS;
                return acc;
              }, {} as Record<ProductionCategory, string>),
              startDate: start.toDate(),
              endDate: end.toDate(),
            }}
          />
        </Container>
      </div>
    </Grid>
  );
}

export default PeriodContainer;
