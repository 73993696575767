import { gql, useApolloClient, useQuery } from '@apollo/client';
import useDebouncedPromise from 'edgeco/hooks/useDebouncedPromise';
import { useMemo } from 'react';
import { SortingRule } from 'react-table';
import {
  buildSort,
  filterToWhere,
  pageToCursor,
} from '../../../utils/graphql-utils';
import { GetTransactionsQuery } from '../@types';

const possibleFields = [
  'transDate',
  'accountNumber',
  'accountType',
  'client',
  'shortName',
  'householdName',
  'revenueCategory',
  'net',
  'gross',
  'repPayoutPercent',
  'payDate',
  'paymentNotes',
  'originalToBePaidDate',
  'payTo',
  'paymentMethod',
  'productType',
  'productCode',
  'productDescription',
  'transId',
  'maClearing',
  'rr1',
  'rr2',
  'buySell',
  'settleDate',
  'security',
  'shares',
  'price',
  'principal',
  'period',
  'accountBalance',
  'paymentTypeCategory',
];
export const GET_PRODUCTION_REP_TRANSACTIONS = gql`
  query GetTransactions(
    $pageSize: Int!
    $cursor: String
    $repIds: [Int!]!
    $order: [ProductionDetailModelSortInput!]
    $where: ProductionDetailModelFilterInput
    $showTransDate: Boolean!
    $showAccountNumber: Boolean!
    $showAccountType: Boolean!
    $showClient: Boolean!
    $showShortName: Boolean!
    $showHouseholdName: Boolean!
    $showRevenueCategory: Boolean!
    $showNet: Boolean!
    $showGross: Boolean!
    $showRepPayoutPercent: Boolean!
    $showPayDate: Boolean!
    $showPaymentNotes: Boolean!
    $showOriginalToBePaidDate: Boolean!
    $showPayTo: Boolean!
    $showPaymentMethod: Boolean!
    $showProductType: Boolean!
    $showProductCode: Boolean!
    $showProductDescription: Boolean!
    $showTransId: Boolean!
    $showMaClearing: Boolean!
    $showRr1: Boolean!
    $showRr2: Boolean!
    $showBuySell: Boolean!
    $showSettleDate: Boolean!
    $showSecurity: Boolean!
    $showShares: Boolean!
    $showPrice: Boolean!
    $showPrincipal: Boolean!
    $showPeriod: Boolean!
    $showAccountBalance: Boolean!
    $showPaymentTypeCategory: Boolean!
  ) {
    selectedReps @client @export(as: "repIds")
    viewer {
      transactionsByRepId(
        first: $pageSize
        after: $cursor
        repIds: $repIds
        order: $order
        where: $where
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        edges {
          node {
            transDate @include(if: $showTransDate)
            accountNumber @include(if: $showAccountNumber)
            accountType @include(if: $showAccountType)
            client @include(if: $showClient)
            shortName @include(if: $showShortName)
            householdName @include(if: $showHouseholdName)
            revenueCategory @include(if: $showRevenueCategory)
            net @include(if: $showNet)
            gross @include(if: $showGross)
            repPayoutPercent @include(if: $showRepPayoutPercent)
            payDate @include(if: $showPayDate)
            paymentNotes @include(if: $showPaymentNotes)
            originalToBePaidDate @include(if: $showOriginalToBePaidDate)
            payTo @include(if: $showPayTo)
            paymentMethod @include(if: $showPaymentMethod)
            productType @include(if: $showProductType)
            productCode @include(if: $showProductCode)
            productDescription @include(if: $showProductDescription)
            transId @include(if: $showTransId)
            maClearing @include(if: $showMaClearing)
            rr1 @include(if: $showRr1)
            rr2 @include(if: $showRr2)
            buySell @include(if: $showBuySell)
            settleDate @include(if: $showSettleDate)
            security @include(if: $showSecurity)
            shares @include(if: $showShares)
            price @include(if: $showPrice)
            principal @include(if: $showPrincipal)
            period @include(if: $showPeriod)
            accountBalance @include(if: $showAccountBalance)
            paymentTypeCategory @include(if: $showPaymentTypeCategory)
          }
        }
      }
    }
  }
`;

function getVariables(
  filters: FilterRequestFilter[],
  columns: string[],
  sortBy: SortingRule<ProductionDetail>[],
  pageSize: number = 40,
  pageIndex: number = 0
) {
  const fieldSelection = possibleFields.reduce((acc, curr) => {
    const varName = `show${curr.charAt(0).toUpperCase()}${curr.slice(1)}`;
    acc[varName] = columns.some((c) => c === curr);
    return acc;
  }, {} as Record<string, boolean>);
  return {
    pageSize,
    cursor: pageToCursor(pageIndex, pageSize),
    order: buildSort(sortBy),
    where: filterToWhere(filters),
    ...fieldSelection,
  };
}

export const useProductionTransactionsTable = (
  filters: FilterRequestFilter[],
  columns: string[],
  sortBy: SortingRule<ProductionDetail>[],
  pageSize: number = 40,
  pageIndex: number = 0
) => {
  const skip =
    !filters.find((f) => f.property === 'transDate' && f.operator === '>=') ||
    !filters.find((f) => f.property === 'transDate' && f.operator === '<=');
  const variables = useMemo(
    () => getVariables(filters, columns, sortBy, pageSize, pageIndex),
    [columns, filters, pageIndex, pageSize, sortBy]
  );

  const { data, loading, error, refetch } = useQuery<GetTransactionsQuery>(
    GET_PRODUCTION_REP_TRANSACTIONS,
    {
      fetchPolicy: skip ? 'cache-only' : 'cache-and-network',
      variables,
    }
  );
  const queryData = data?.viewer?.transactionsByRepId;
  const updateTableData = useDebouncedPromise(() => refetch(variables), 25);

  const pageCount = useMemo(
    () => Math.ceil((queryData?.totalCount ?? pageSize) / pageSize),
    [pageSize, queryData]
  );

  const pagedTransactionRecords: RecordSet<ProductionDetail[]> = useMemo(
    () => ({
      page: {
        pageIndex,
        pageSize,
        pageCount,
      },
      records: queryData?.edges?.map((x) => x.node) as ProductionDetail[],
    }),
    [pageCount, pageIndex, pageSize, queryData]
  );

  return {
    pagedTransactionRecords,
    loading: loading && !skip,
    error,
    refetchData: updateTableData,
    variables,
  };
};

export const useProductionTransactionsTableExport = (
  filters: FilterRequestFilter[],
  columns: string[],
  sortBy: SortingRule<ProductionDetail>[]
) => {
  const client = useApolloClient();
  const fetchData = async () => {
    let hasNextPage = true;
    const rv: ProductionDetail[] = [];
    let cursor: string | undefined | null;
    while (hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const currentResult = await client.query<GetTransactionsQuery>({
        query: GET_PRODUCTION_REP_TRANSACTIONS,
        variables: {
          ...getVariables(filters, columns, sortBy),
          pageSize: 500,
          cursor,
        },
      });
      const data = currentResult.data.viewer.transactionsByRepId;
      if (data?.edges) {
        hasNextPage = data.pageInfo.hasNextPage;
        cursor = data.pageInfo.endCursor;
        if (data.edges) {
          (data.edges.map((x) => x.node) as ProductionDetail[]).forEach((n) =>
            rv.push(n)
          );
        }
      } else {
        hasNextPage = false;
      }
    }
    return rv;
  };

  return {
    fetchData,
  };
};
