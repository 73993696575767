import React, { useCallback, useMemo, useRef, useState } from 'react';
import { makeStyles, createStyles, Theme, Button } from '@material-ui/core';

import { ToggleButtonGroup } from 'edgeco/components/buttons';
import ToggleIconButton from 'edgeco/components/buttons/ToggleIconButton';
import { themeExtensions } from 'edgeco/assets/theme';
import OutlinedInput from 'edgeco/components/input/OutlinedInput';
import { useQueryParams } from 'edgeco/hooks/useQueryParams';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    dataWrapper: {
      display: 'inline-flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingRight: theme.spacing(2),
      fontSize: '2.1rem',
      fontWeight: 400,
      '& > *': {
        flex: '1 1 auto',
        margin: 'auto',
      },
      '& > :nth-child(odd)': {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    selectedGoto: {
      '& $gotoLabel': {
        color: themeExtensions.color.white,
        borderRadius: 15,
        padding: '0 3px',
        backgroundColor: themeExtensions.color.data.teal,
        textAlign: 'center',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    gotoButton: {
      margin: 0,
    },
    gotoLabel: {
      height: '30px',
      minWidth: '30px',
      fontSize: '2.1rem',
      fontWeight: 400,
      lineHeight: '30px',
    },
    jumpToText: {
      fontSize: '2.1rem',
      fontWeight: 400,
      maxWidth: '100px',
      minWidth: '60px',
    },
    jumpToButton: {
      fontSize: '2.1rem',
      fontWeight: 400,
      padding: theme.spacing(1 / 2, 2),
      maxWidth: '60px',
      border: 'none',
      '&:hover': {
        border: 'none',
      },
    },
    chevron: {
      marginLeft: '-6px',
      marginRight: '-6px',
      '& $gotoLabel': {},
    },
  })
);

type TablePaginationActionsProps = {
  pageCount: number;
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
};

function getGotoRange(
  pageCount: number,
  currentPage: number
): [number, number] {
  const lastPage = pageCount - 1;
  const buttonCount = 5;
  const offset = Math.floor(buttonCount / 2);

  if (pageCount <= buttonCount) {
    return [0, lastPage];
  }
  const rangeStart = currentPage - offset;
  const rangeEnd = currentPage + offset;
  // beginning pages
  if (rangeStart < 0) {
    return [0, buttonCount - 1];
  }
  // end pages
  if (rangeEnd > lastPage) {
    return [pageCount - buttonCount, lastPage];
  }
  // middle pages
  return [rangeStart, rangeEnd];
}

function TablePagerActions({
  pageCount, // using Mui row count for page count
  page: currentPage,
  onChangePage,
}: TablePaginationActionsProps) {
  const { modifyQuery } = useQueryParams<PagedParams>();
  const jumpTextRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const [jumpValue, setJumpValue] = useState<number>();
  const showPrefixText = pageCount > 10;
  const showPagerJump = pageCount > 20;

  const convertToPageRange = useCallback(
    (pageValue: number) => Math.min(Math.max(pageValue, 1), pageCount),
    [pageCount]
  );

  const [gotoStart, gotoEnd] = useMemo(
    () => getGotoRange(pageCount, currentPage),
    [currentPage, pageCount]
  );

  // Separate handlers for blur & change so the user isn't blocked inputting values
  const validateJumpInputText = (evt: InputTextChangeEvent) => {
    const { value } = evt.target;
    if ((!value || value === '') && jumpTextRef.current !== null) {
      jumpTextRef.current.value = '';
    }
    const numberValue = parseInt(value);
    setJumpValue(convertToPageRange(numberValue));
  };

  const onJumpInputTextChange = (evt: InputTextChangeEvent) => {
    const { value } = evt.target;

    const numberValue = parseInt(value);
    if (Number.isNaN(numberValue) || numberValue === undefined) {
      setJumpValue(undefined);
      return;
    }
    setJumpValue(numberValue);
  };

  const handlePageChange = (evt: ButtonMouseEvent, value: number) => {
    if (value === undefined || value === null) return; // 0 is falsey, can't check !value
    modifyQuery(['pageIndex', value]);
    onChangePage(evt, value);
  };

  const handleJumpTo = (evt: ButtonMouseEvent) => {
    // These validations are redundant, but prevents any strange behaviour
    // if undefined or NaN
    if (!jumpValue || Number.isNaN(jumpValue)) return;

    const targetPage = convertToPageRange(jumpValue);
    handlePageChange(evt, targetPage - 1);
  };

  const onKeyPress = (evt: any) => {
    if (evt.key === 'Enter') {
      validateJumpInputText(evt);
      handleJumpTo(evt as any);
    }
  };

  const renderPrefixText = showPrefixText ? (
    <div className={classes.dataWrapper}>
      Showing {`${gotoStart + 1}-${gotoEnd + 1} out of ${pageCount}`} results
    </div>
  ) : null;

  const gotoPageButtons = useMemo(
    () =>
      Array(gotoEnd - gotoStart + 1)
        .fill(0)
        .map((_, idx) => {
          const targetIndex = gotoStart + idx;
          return (
            <ToggleIconButton
              value={targetIndex}
              key={`go-to-page-${targetIndex}`}
              disableRipple={true}
              disableFocusRipple={true}
              classes={{
                root: classes.gotoButton,
                selected: classes.selectedGoto,
                label: classes.gotoLabel,
              }}
            >
              {targetIndex + 1}
            </ToggleIconButton>
          );
        }),
    [
      classes.gotoButton,
      classes.gotoLabel,
      classes.selectedGoto,
      gotoEnd,
      gotoStart,
    ]
  );

  const renderJump = showPagerJump ? (
    <div className={classes.dataWrapper}>
      <span>Jump To </span>
      <OutlinedInput
        id="jump-to-page-textbox"
        type="text"
        value={jumpValue || ''}
        onBlur={validateJumpInputText}
        onChange={onJumpInputTextChange}
        className={classes.jumpToText}
        disableHtml5Buttons={true}
        onKeyPress={onKeyPress}
        inputRef={jumpTextRef}
        inputProps={{
          pattern: '[0-9]',
          size: Math.max(2, jumpTextRef.current?.value.length || 0),
        }}
      />
      <Button
        color="primary"
        variant="contained"
        className={classes.jumpToButton}
        onClick={handleJumpTo}
      >
        Go
      </Button>
    </div>
  ) : null;

  return (
    <div className={classes.root}>
      {renderPrefixText}

      <ToggleButtonGroup
        size="small"
        value={currentPage}
        onChange={handlePageChange}
        exclusive={true}
        contained={false}
      >
        {[
          <ToggleIconButton
            key={'pager-previous-page'}
            disableRipple={true}
            disabled={currentPage === 0}
            aria-label="previous page"
            variant="chevronLeft"
            value={currentPage - 1}
            selected={false}
            className={classes.chevron}
            classes={{
              label: classes.gotoLabel,
            }}
          />,
          ...gotoPageButtons,
          <ToggleIconButton
            key={'pager-next-page'}
            disableRipple={true}
            disabled={currentPage >= pageCount - 1}
            aria-label="next page"
            variant="chevronRight"
            value={currentPage + 1}
            selected={false}
            className={classes.chevron}
            classes={{
              label: classes.gotoLabel,
            }}
          />,
        ]}
      </ToggleButtonGroup>

      {renderJump}
    </div>
  );
}

export default TablePagerActions;
