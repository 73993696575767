import _uniqueId from 'lodash/uniqueId';
import { Cell } from 'recharts';
import Mask from '../Mask/Mask';
import CloneElement from '../../common/CloneElement';

export type CellRenderOptions = {
  data: ChartDataShape<any>[];
  fill?: string;
  activeFill?: string;
  match?: (...args: any[]) => boolean;
  pattern?: React.ReactElement<MaskProps, typeof Mask>;
  onActive?: () => any;
};
export default function renderBarCellPattern(props: CellRenderOptions) {
  const { data, fill, match, pattern, activeFill } = props;
  const id = _uniqueId();
  return data.map((d, index) => {
    return pattern && match && match(d) ? (
      <Cell
        key={`cell-${id}`}
        strokeWidth={4}
        stroke={fill}
        pattern={
          <CloneElement<MaskProps>
            key={`pattern-${id}-${index}`}
            element={pattern}
            fill={fill}
            activeFill={activeFill}
          />
        }
      />
    ) : (
      <Cell
        key={`cell-${id}`}
        strokeWidth={2}
        fill={fill}
        activeFill={activeFill}
      />
    );
  });
}
