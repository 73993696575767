import React, { useMemo, useState } from 'react';

import { useYearlyTopHouseholdsComparisonChart } from 'edgeco/graphql/advisor-summary/queries/topHouseholdsYearly';
import { getPastYears } from '../../../../libs';
import TopAccountsBarChart from './YearlyTopBarChart';

function TopPayoutAccounts() {
  const currentYear = new Date().getFullYear();
  const years = useMemo(() => getPastYears(6, false), []);
  const [selectedYear, setSelectedYear] = useState<number>();

  const onYearChange = (e: any, year: string) => {
    const comparisonYear = parseInt(year);
    setSelectedYear(comparisonYear);
  };

  const { loading, error, chartData, domain, populatedYears } =
    useYearlyTopHouseholdsComparisonChart(
      [currentYear, ...years],
      currentYear,
      selectedYear
    );

  return (
    <TopAccountsBarChart
      title="Top 5 Households"
      trackingName="HouseholdYearly"
      dataPoint="households"
      selectedYear={selectedYear}
      onYearChange={onYearChange}
      loading={loading}
      error={error}
      chartData={chartData}
      domain={domain}
      populatedYears={populatedYears}
    />
  );
}

export default TopPayoutAccounts;
