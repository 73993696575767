import { Switch, Redirect, Route } from 'react-router';

import RedirectErrorBoundary from 'edgeco/components/error/RedirectErrorBoundry';
import MessageRoot from 'edgeco/components/root/MessageRoot';
import { Feature } from 'edgeco/components/feature-flags/index';
import React from 'react';
import ErrorView from '../../views/message/ErrorView';
import LoggedOutView from '../../views/message/LoggedOutView';
import FutureFeature from '../../views/FutureFeature';
import ProductionDetailView from '../../views/production-detail/ProductionDetailView';
import DashboardView from '../../views/dashboard/DashboardView';
import ProfilePageView from '../../views/user-profile/ProfilePageView';
import OidcRoutes from './OidcRoutes';
import { Root } from '../../components/root';
import ExtendedRoute from './ExtendedRoute';
import { FeatureRoute } from './FeatureRoute';

const dashboardRoute = new ExtendedRoute({
  path: '/dashboard',
  component: DashboardView,
  routes: [],
});

const productionDetailRoute = new ExtendedRoute({
  path: '/production-detail',
  component: ProductionDetailView,
  exact: true,
  routes: [],
});

const profileRoute = new FeatureRoute({
  feature: Feature.ProfilePage,
  path: '/profile',
  component: ProfilePageView,
  exact: true,
  routes: [],
});

const errorRoute = new ExtendedRoute({
  path: '/(error|logged-out)',
  component: MessageRoot,
  routes: [
    new ExtendedRoute({
      path: '/logged-out',
      component: LoggedOutView,
    }),
    new ExtendedRoute({
      path: '/error/:errorCode?',
      component: ErrorView,
    }),
  ],
});

const futureRoute = new FeatureRoute({
  feature: Feature.Future1Page,
  path: '/future',
  component: FutureFeature,
  exact: true,
  routes: [],
});

const rootRoute: ExtendedRoute = new ExtendedRoute({
  path: '/',
  component: React.Fragment,
  exclusive: true,
  routes: [
    errorRoute,
    new ExtendedRoute({
      path: '/',
      component: RedirectErrorBoundary,
      routes: [
        new ExtendedRoute({
          path: '/',
          component: OidcRoutes,
          exclusive: true,
          routes: [
            new ExtendedRoute({
              exact: true,
              path: '/',
              component: () => <Redirect to="/dashboard" />,
            }),
            new ExtendedRoute({
              path: '/callback',
              component: () => <></>,
            }),
            new ExtendedRoute({
              path: '/(dashboard|production-detail)',
              component: Root,
              exclusive: true,
              routes: [dashboardRoute, productionDetailRoute],
            }),
            futureRoute,
            new ExtendedRoute({
              path: '/profile',
              component: Root,
              routes: [profileRoute],
            }),
            new ExtendedRoute({
              component: () => <Redirect to="/error/404" />,
            }),
          ],
        }),
      ],
    }),
  ],
});

// TODO: for some reason RouteWithSubRoutes breaks the production build.  Works in previous demo though.
export function RouteMap({
  extendedRoute = rootRoute,
  ...rest
}: {
  extendedRoute?: ExtendedRoute;
}) {
  const Container = extendedRoute.exclusive ? Switch : React.Fragment;
  return (
    <extendedRoute.Component {...rest}>
      <Container>
        {extendedRoute.routes?.map((childRoute, idx) => (
          <Route
            exact={childRoute.exact}
            path={childRoute.path}
            key={idx}
            render={(props) => (
              <RouteMap {...props} extendedRoute={childRoute} />
            )}
          />
        ))}
      </Container>
    </extendedRoute.Component>
  );
}
