import React, { PureComponent } from 'react';
import colorScheme from '../colorScheme';

export default class Stripes extends PureComponent<StripesProps> {
  static defaultProps: Partial<StripesProps> = {
    stroke: 2,
    background: colorScheme.colors.backgroundWhite,
  };

  render() {
    const { id, fill, activeFill, isActive, stroke, background } = this.props;

    return (
      <pattern
        // eslint-disable-next-line jsx-a11y/aria-role
        role="chart-bar-stripe"
        id={id}
        width="8"
        height="4"
        patternUnits="userSpaceOnUse"
        patternTransform="rotate(-45)"
      >
        {background && (
          <rect
            className="area-stripe-background"
            width="8"
            height="4"
            fill={background}
          />
        )}
        <rect
          className="area-stripe"
          width={`${stroke}`}
          height="4"
          fill={isActive ? activeFill : fill}
        />
      </pattern>
    );
  }
}
