import React, { PropsWithChildren } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Icon from 'edgeco/components/common/Icon';
import { CommandStatus } from 'edgeco/graphql/user-profile/@types';
import { withTooltip } from 'edgeco/components/data-visualization';

const useStyles = makeStyles(() => ({
  pendingIcon: {
    width: 24,
    height: 24,
    animationName: '$spin',
    animationDuration: '4000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
}));

const PendingIcon = React.memo(() => {
  const classes = useStyles();
  return (
    <div className={classes.pendingIcon} title="processing">
      <Icon variant="cached" />
    </div>
  );
});

const SaveFailed = withTooltip<{}>(
  React.forwardRef((_, ref: any) => {
    const theme = useTheme();
    return (
      <div ref={ref} title="processing">
        <Icon primaryColor={theme.extensions.color.warn} variant="warning" />
      </div>
    );
  })
);

type Props = PropsWithChildren<{
  // defaults to CommandStatus.Processed
  status?: CommandStatus | null;
}>;

function StatusContainer({
  status = CommandStatus.Processed,
  children,
}: Props) {
  return (
    <>
      {status === CommandStatus.Processing && <PendingIcon />}
      {status === CommandStatus.Failed && (
        <SaveFailed
          tooltipOptions={{
            content: 'Household was not processed, please try again later.',
          }}
        />
      )}
      {status === CommandStatus.Processed && children}
    </>
  );
}

export default StatusContainer;
