import {
  createNamedContext,
  useGenericContext,
  useGenericCreateContext,
} from 'edgeco/hooks/genericContext';

// TableContext should never be exported, it is not type safe and shouldn't be used directly
const TableContext = createNamedContext('TableContext', {
  onSaveFilters: () => {},
  reloadFromQuery: () => {},
});

export const useCreateTableContext = <T>(contextValues: TableContext<T>) => {
  return useGenericCreateContext(contextValues, TableContext);
};

export const useTableContext = <T = {}>() =>
  useGenericContext<TableContext<T>>(TableContext);
