import React from 'react';

const AccordionContext = React.createContext<AccordionContext | null>(
  null
) as React.Context<AccordionContext>;

AccordionContext.displayName = 'AccordionContext';
(AccordionContext.Provider as any).displayName = 'AccordionContext.Provider';
(AccordionContext.Consumer as any).displayName = 'AccordionContext.Consumer';

export default AccordionContext;
