import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useModal, { ModalProps } from './useModal';

type MessageBoxProps = Omit<ModalProps, 'content'> & {
  inlineTitle?: boolean;
  content?: ModalProps['content'] | string;
  width?: number;
};

const useStyles = makeStyles<EdgeCoTheme, { width: MessageBoxProps['width'] }>(
  ({ spacing, extensions: { color } }) => ({
    content: ({ width }) => ({
      padding: spacing(4),
      width,
    }),
    inlineTitle: {},
    inlineTitleTypography: {
      paddingTop: spacing(1),
      color: color.data.teal,
      marginBottom: spacing(1),
      fontWeight: 'bold',
    },
  })
);

export default function useMessageBox({
  title,
  inlineTitle = true,
  content,
  hideHeader,
  className,
  width,
  ...modalProps
}: MessageBoxProps) {
  const classes = useStyles({ width });
  const modalContent =
    typeof content === 'string' ? (
      <Typography>{content}</Typography>
    ) : (
      (content as React.ReactElement)
    );
  const newProps: ModalProps = {
    ...modalProps,
    className: clsx(className, classes.content),
    title,
    hideHeader: inlineTitle ? true : hideHeader,
    content: inlineTitle ? (
      <>
        <Typography variant="h3" className={classes.inlineTitleTypography}>
          {title}
        </Typography>
        {modalContent}
      </>
    ) : (
      modalContent
    ),
  };
  return useModal(newProps);
}
