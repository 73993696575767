// In general, withTooltip should be preferred over using the built in rechart tooltips
// if you're unable to use a HOC due to how recharts renders, as is the case with Donut/Pie
// charts, then this can be used as an alternative
import { useState, useCallback } from 'react';
import { CellProps } from 'recharts';
import { ActiveState, ActiveEntity } from './types';

export function useActiveState<TData = any>(
  data?: ReadonlyArray<TData>,
  isClickable?: boolean
): ActiveState {
  const [activeEntity, setActive] = useState<ActiveEntity<TData>>();
  const [lockedEntity, setLocked] = useState<ActiveEntity<TData>>();
  const [tooltipEntity, setTooltip] = useState<ActiveEntity<TData>>();
  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [lockedCoordinate, setLockedCoordinate] = useState<Coordinate>();
  const [tooltipCoordinate, setTooltipCoordinate] = useState<Coordinate>();

  const currentEntity = lockedEntity ?? tooltipEntity ?? activeEntity;
  const currentCoordinate = lockedCoordinate ?? tooltipCoordinate ?? coordinate;

  let payload: TooltipProps<TData> | undefined;
  if (data && currentEntity) {
    payload = {
      dataKey: currentEntity.key,
      data: data[currentEntity.index],
    };
  }

  const getIsActive = useCallback(
    (key, index) =>
      currentEntity?.key.toString() === key.toString() &&
      currentEntity?.index === index,
    [currentEntity]
  );

  const toggleLocked = (entity: ActiveEntity<TData>) => {
    if (
      lockedEntity?.index === entity.index &&
      // toString is necessary due to keys potentially being accessor functions
      lockedEntity?.key.toString() === entity.key.toString()
    ) {
      setLockedCoordinate(undefined);
      setLocked(undefined);
    } else {
      setLockedCoordinate(coordinate);
      setLocked(entity);
    }
  };
  return {
    activeEntity: currentEntity,
    isClickable: isClickable === true,
    setActive,
    getIsActive,
    toggleLocked,
    getCellProps: (entity) => {
      const cellProps: CellProps = {
        onMouseEnter: () => {
          setActive(entity);
        },
        onMouseLeave: () => {
          setActive(undefined);
        },
      };
      if (isClickable) {
        cellProps.onClick = () => {
          toggleLocked(entity);
        };
        cellProps.style = {
          cursor: 'pointer',
        };
      }
      return cellProps;
    },
    tooltip: {
      active: currentEntity !== undefined,
      contentProps: payload,
      coordinate: currentCoordinate,
      onPositionChange: useCallback((x: number, y: number) => {
        setCoordinate({ x, y });
      }, []),
      onMouseEnter: () => {
        setTooltipCoordinate(coordinate);
        setTooltip(activeEntity);
      },
      onMouseLeave: () => {
        setTooltipCoordinate(undefined);
        setTooltip(undefined);
      },
    },
  };
}
