import { makeStyles, Typography } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '.';

type ComparisonTabBarProps = {
  years: number[];
  selectedYear?: number;
  onSelect: (event: any, year: string) => any;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles<EdgeCoTheme, ComparisonTabBarProps>(
  ({
    spacing,
    extensions: {
      font: { infographicSelectionHeadingSm, infographicSelectionHeadingLg },
    },
    typography: { pxToRem },
  }) => ({
    root: ({ size }) =>
      size === 'small'
        ? {
            marginTop: spacing(1),
            marginLeft: spacing(9),
            fontSize: pxToRem(14),
          }
        : {
            marginTop: spacing(2),
          },
    title: ({ size }) =>
      (size === 'small'
        ? {
            ...infographicSelectionHeadingSm,
          }
        : {
            ...infographicSelectionHeadingLg,
            paddingBottom: '11px',
          }) as any,
    buttonGroup: {
      '& > *': {
        margin: spacing(1),
      },
      '& :first-child': {
        marginLeft: 0,
      },
    },
  })
);

export default function ComparisonButtonTabBar(props: ComparisonTabBarProps) {
  const { years, onSelect, selectedYear, size } = props;
  const { root, buttonGroup, title } = useStyles(props);
  if (!years || years.length === 0) return null;
  return (
    <div className={root}>
      <Typography className={title}>Compare YTD to past years</Typography>
      <ToggleButtonGroup
        contained={false}
        value={selectedYear}
        onChange={onSelect}
        className={buttonGroup}
        size={size}
      >
        {[...years].map((y) => (
          <ToggleButton
            variant="outlined"
            key={`toggle-year-${y}`}
            aria-label={y.toString()}
            value={y}
          >
            {y}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
