import Tile from 'edgeco/components/dashboard-widget/Tile';
import { Loading } from 'edgeco/components/loading';
import { makeStyles, Grid, Theme } from '@material-ui/core';
import { useAdvisorHighlightsTouts } from 'edgeco/graphql/advisor-summary/queries';
import { currencyFormatter, numberFormatter } from '../../../libs';
import { themeExtensions as thmExt } from '../../../assets/theme';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));
export default function PayEarnedTiles() {
  const { root } = useStyles();
  const { teal, eucalyptus, sushi } = thmExt.color.data;
  const colorRange = [teal, eucalyptus, sushi];

  const { advisorHightlightsToutData, loading, error } =
    useAdvisorHighlightsTouts();
  const getHeadline = (type: NumberFormat, value: number): string => {
    switch (type) {
      case 'CURRENCY':
        return currencyFormatter(value as number, 5) ?? value.toString();
      case 'CHANGE':
        return numberFormatter(value, 4, true) ?? '0';
      default:
        return value.toString();
    }
  };
  if (error) return <div />;
  if (loading || !advisorHightlightsToutData) return <Loading />;
  return (
    <Grid spacing={3} container className={root}>
      {advisorHightlightsToutData.map((t, idx) =>
        t ? (
          <Tile
            key={idx}
            color={colorRange[idx]}
            eyebrowText={t?.title}
            headlineText={getHeadline(t?.data.format, t?.data.value)}
            captionText={t?.description}
            breakpoints={{
              md: advisorHightlightsToutData.length < 3 ? 6 : 4,
              xs: 12,
            }}
          />
        ) : (
          <div />
        )
      )}
    </Grid>
  );
}
