import { useMemo, useState } from 'react';

import { useYearlyTopPayoutAccountsComparisonChart } from 'edgeco/graphql/advisor-summary/queries';
import { getPastYears } from '../../../../libs';
import TopAccountsBarChart, { ChartData } from './YearlyTopBarChart';

function TopPayoutAccounts(props: { topAccounts: KeyValueTypes[] }) {
  const currentYear = new Date().getFullYear();
  const years = useMemo(() => getPastYears(5, false), []);
  const [selectedYear, setSelectedYear] = useState<number>();
  const onYearChange = (e: any, year: string) => {
    const comparisonYear = parseInt(year);
    setSelectedYear(comparisonYear);
  };

  const { loading, error, chartData, domain, populatedYears } =
    useYearlyTopPayoutAccountsComparisonChart(
      [currentYear, ...years],
      currentYear,
      selectedYear
    );

  // sort bar graph data according to the order of top 5 accounts
  const chartDataSort = useMemo(
    () =>
      props.topAccounts.reduce<ChartData[]>((acc, key) => {
        const entry = chartData.find((y) => y.key === key);
        if (!entry) return acc;
        acc.push(entry);
        if (!entry.data.current) {
          entry.data.current = {
            name: key as string,
            percentOfTotal: 0,
            total: 0,
            value: 0,
            year: selectedYear!,
          };
        }

        if (!entry.data.comparison) {
          entry.data.comparison = {
            name: key as string,
            percentOfTotal: 0,
            total: 0,
            value: 0,
            year: selectedYear!,
          };
        }
        return acc;
      }, []),
    [chartData, props.topAccounts, selectedYear]
  );

  return (
    <TopAccountsBarChart
      title="Top 5 Revenue Accounts"
      trackingName="YearlyTopPayout"
      dataPoint="accounts"
      selectedYear={selectedYear}
      onYearChange={onYearChange}
      loading={loading}
      error={error}
      chartData={chartDataSort}
      domain={domain}
      populatedYears={populatedYears}
    />
  );
}

export default TopPayoutAccounts;
