import { PageWrapper } from 'edgeco/components/root';
import useAuth from 'edgeco/hooks/useAuth';
import { AppBar, makeStyles, Slide, useScrollTrigger } from '@material-ui/core';
import RepSelectionModalButton from 'edgeco/components/modal/RepSelectionModalButton';
import { isDesktop, isMobile } from 'react-device-detect';
import theme from 'edgeco/assets/theme';
import PayEarnedBreakdown from './pay-earned-breakdown/PayEarnedBreakdown';
import ProductionBreakdownWidget from './production-breakdown-history/ProductionBreakdownWidget';
import ProductionHistoryWidget from './production-history/ProductionHistoryWidget';
import SignificantRevenueAccounts from './significant-revenue-accounts/SignificantRevenueAccounts';
import PageFooter from '../../components/root/PageFooter';
import HouseholdDialog from '../../components/lists/accordion/AccordionList';

const useStyles = makeStyles(
  ({
    typography,
    extensions: { toolbarHeight, color },
    breakpoints,
    spacing,
    zIndex,
  }) => ({
    appBar: {
      zIndex: zIndex.drawer + 1,
      height: toolbarHeight,
      background: color.background,
      boxShadow: `0px 2px 4px 3px rgba(0,0,0,0.2),
                0px 4px 5px 3px rgba(0,0,0,0.14),
                0px 1px 10px 3px rgba(0,0,0,0.12)`,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      top: 'auto',
      bottom: 0,

      [theme.breakpoints.down('md')]: {
        height: 65,
      },
    },
    disclaimer: {
      fontSize: typography.pxToRem(14),
      color: color.tundora,
      paddingLeft: spacing(7),
      lineHeight: 1,
      [breakpoints.down('sm')]: {
        paddingLeft: spacing(1),
      },
    },
    repSelectionContainer: {
      height: toolbarHeight,
      padding: spacing(0, 0, 0, 3.5),
      position: 'absolute',
    },
    repSelectionButton: {
      width: '300px',
      marginRight: '45px',
      borderRadius: 16,
      borderWidth: 3,
      height: '52px',
      fontSize: '2.4rem',
      paddingLeft: '20px',
      paddingRight: '20px',
      '&:hover': {
        borderWidth: 1,
        backgroundColor: '#fff',
      },
    },
    relativeWrapper: {
      position: 'relative',
      top: -80,
      float: 'right',

      [breakpoints.down('md')]: {
        top: -20,
        float: 'none',
      },
    },
    mobileHeader: {
      textAlign: 'center',
    },
  })
);

function DashboardView() {
  const classes = useStyles();
  const { accountInfo } = useAuth();
  const visible = !useScrollTrigger({
    disableHysteresis: false,
    threshold: 0,
  });

  const disclaimer = (
    <div className={classes.disclaimer}>
      *Total amounts below are based on data that was acquired yesterday. Data
      gathering times vary.
    </div>
  );

  return (
    <>
      <PageWrapper
        title={
          <>
            {isMobile ? (
              <>
                <div className={classes.mobileHeader}>
                  {accountInfo?.account?.idTokenClaims.given_name}'s Dashboard
                </div>
                <Slide
                  appear={false}
                  in={visible}
                  timeout={{ enter: 225, exit: 195 }}
                  direction="up"
                >
                  <AppBar position={'fixed'} className={classes.appBar}>
                    {disclaimer}
                  </AppBar>
                </Slide>
              </>
            ) : (
              <>
                {accountInfo?.account?.name}'s Dashboard
                {disclaimer}
              </>
            )}
          </>
        }
      >
        <div className={classes.relativeWrapper}>
          {isDesktop && <RepSelectionModalButton />}
        </div>

        <PayEarnedBreakdown />
        <SignificantRevenueAccounts />
        <ProductionHistoryWidget />
        <ProductionBreakdownWidget />
        <HouseholdDialog></HouseholdDialog>
        <PageFooter />
      </PageWrapper>
    </>
  );
}

export default DashboardView;
