/**
 * Create a strongly typed Map from an Object.
 * Uses a function to find keys within the object.
 */
export function groupObjectsBy<TObject extends object>(
  objectList: Array<TObject>,
  keyGetter: (o: TObject) => NonNullable<TObject[keyof TObject]>
): Map<NonNullable<TObject[keyof TObject]>, TObject[]> {
  const map = new Map<NonNullable<TObject[keyof TObject]>, TObject[]>();
  objectList.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

/**
 * Merges the values of multiple maps with similar keys.  Does not overwrite the key value of previously set keys like destructuring does.
 */
export function mergeMaps<TKey, TValue>(
  ...maps: Map<TKey, TValue[]>[]
): Map<TKey, TValue[]> {
  const merged = new Map<TKey, TValue[]>();
  maps.forEach((map) => {
    [...map.entries()].forEach(([key, values]) => {
      const collection: Array<TValue> | undefined = merged.get(key);
      if (!collection) {
        merged.set(key, values);
      } else {
        merged.set(key, [...collection, ...values]); // collection references original collection from map argument.
      }
    });
  });
  return merged;
}

// https://github.com/slorber/awesome-imperative-promise
export type ImperativePromise<T> = {
  promise: Promise<T>;
  resolve: PromiseResolver<T>;
  reject: PromiseRejector<T>;
  cancel: TypedFunction<undefined, void>;
};

/**
 * Exposes the interal callbacks of a Promise and allows cancellation
 */
export function createImperativePromise<T>(
  wrapped?: Promise<T>
): ImperativePromise<T> {
  let resolve: PromiseResolver<T>;
  let reject: PromiseRejector<T>;

  // Grab an empty promise of type T
  const wrappedPromise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  // Call wrapped
  if (wrapped) {
    wrapped.then(
      (val) => {
        if (resolve) resolve(val);
      },
      (error) => {
        if (reject) reject(error);
      }
    );
  }

  return {
    promise: wrappedPromise,
    resolve: (value) => {
      if (resolve) resolve(value);
    },
    reject: (reason) => {
      if (reject) reject(reason);
    },
    cancel: () => {
      resolve = () => null;
      reject = () => null;
    },
  };
}

// Inspired by https://github.com/slorber/awesome-only-resolves-last-promise
/**
 * Wraps an async function. If called multilple times, it will cancel all previous unresolved promises.
 * example:
 * ```

const wrappedPromise = resolveLastPromise(fetch);
// or...
// const wrappedPromise = resolveLastPromise(async (url: string) => fetch(url));

const test1 = wrappedPromise(some-url/something?page=1)
const test2 = wrappedPromise(some-url/something?page=2)
const test3 = wrappedPromise(some-url/something?page=3)
```
test1 & test2 will never resolve, since test 3 is called immediately after.  test3 will resolve/reject if awaited.
 */
export function resolveLastPromise<
  TFunction extends (...args: any[]) => Promise<any>
>(asyncFunction: (...args: Parameters<TFunction>) => Promise<any>): TFunction {
  // cancel previous is set by a newly created wrapped promise.
  let cancelPrevious: TypedFunction<undefined, void>;

  const wrappedFunction = (...args: Parameters<TFunction>) => {
    if (cancelPrevious) cancelPrevious();
    const initialPromise = asyncFunction(...args);
    const { promise, cancel } = createImperativePromise(initialPromise);
    cancelPrevious = cancel;
    return promise;
  };

  return wrappedFunction as TFunction;
}
