import { gql, useQuery } from '@apollo/client';
import useCumulativeDomain from 'edgeco/components/data-visualization/hooks/useCumulativeDomain';
import { useMemo } from 'react';
import { groupObjectsBy } from '../../../utils/utility-functions';
import { GetYearlyTopPayoutAccountsQuery } from '../@types';

export const GET_YEARLY_TOP_PAYOUT_ACCOUNTS = gql`
  query GetYearlyTopPayoutAccounts($repIds: [Int!]!, $years: [Int!]) {
    selectedReps @client @export(as: "repIds")
    viewer {
      aggregateYearlyTopPayout(
        repIds: $repIds
        where: { year: { in: $years } }
      ) {
        year
        name: account
        value: payout
        total
      }
    }
  }
`;

export const useYearlyTopPayoutAccountsComparisonChart = (
  years: number[],
  currentYear: number,
  selectedYear?: number
) => {
  const { data, loading, error } = useQuery<GetYearlyTopPayoutAccountsQuery>(
    GET_YEARLY_TOP_PAYOUT_ACCOUNTS,
    {
      variables: {
        years,
      },
    }
  );
  const queryResult = data?.viewer?.aggregateYearlyTopPayout ?? [];

  const topPayoutAccountsYearlyData: Map<
    string | number,
    YearlyTopDataShape[]
  > = useMemo(() => {
    const results = queryResult.map(({ year, value, name, total }) => ({
      year,
      name,
      value,
      total,
      percentOfTotal: value / total,
    }));
    return groupObjectsBy(results, (d) => d.name);
  }, [queryResult]);

  const chartData = useMemo(
    () =>
      [...topPayoutAccountsYearlyData.entries()].map(([key, values]) => ({
        key,
        data: values.reduce((acc, curr) => {
          if (curr.year === currentYear) {
            acc.current = curr;
          }

          if (curr.year === selectedYear) {
            acc.comparison = curr;
          }

          return acc;
        }, {} as TopYearlyComparison),
      })),
    [currentYear, selectedYear, topPayoutAccountsYearlyData]
  );

  const domain = useCumulativeDomain(chartData, data?.selectedReps ?? [], [
    (d: ChartDataShape<TopYearlyComparison>) => d.data?.current?.value,
    (d: ChartDataShape<TopYearlyComparison>) => d.data?.comparison?.value,
  ]);

  const populatedYears = [...topPayoutAccountsYearlyData.values()]
    .flat()
    .reduce((acc, curr) => {
      if (curr.year !== currentYear) acc.add(curr.year);
      return acc;
    }, new Set<number>());

  return { chartData, domain, populatedYears, loading, error };
};
