import moment, { Moment, unitOfTime } from 'moment';

export function formatDate(period: DatePeriod, date?: Date | number) {
  if (period === 'T12') return 'T12';

  if (period === 'Quarter') {
    const momentDate = moment(date);
    return `Q${momentDate.quarter()} ${momentDate.year()}`;
  }

  const options: Intl.DateTimeFormatOptions = {};
  switch (period) {
    case 'Day':
      options.day = '2-digit';
      options.month = 'long';
      options.year = 'numeric';
      break;
    case 'Month':
    case 'MTD':
      options.month = 'long';
      options.year = 'numeric';
      break;
    case 'Year':
    case 'YTD':
      options.year = 'numeric';
      break;

    default:
  }

  const formatter = new Intl.DateTimeFormat('en-US', options);
  return formatter.format(date);
}

/**
 * Returns an array of the past years
 * @param back How many years back
 * @param inclusive Include the current year
 */
export const getPastYears = (back: number, inclusive: boolean = true) => {
  let year = new Date().getFullYear();
  if (!inclusive) year--;
  const rv = Array.from({ length: back }, (v, i) => year - back + i + 1);
  return rv;
};

export function periodToRange(
  period: DatePeriod,
  referenceDate: Moment = moment()
) {
  const start = referenceDate.clone();
  const end = referenceDate.clone();
  switch (period) {
    case 'Day':
    case 'Month':
    case 'Year':
    case 'Quarter': {
      const unit = period.toLowerCase() as unitOfTime.StartOf;
      return [start.startOf(unit), end.endOf(unit)];
    }
    case 'Yesterday': {
      return [
        start.add(-1, 'day').startOf('day'),
        end.add(-1, 'day').endOf('day'),
      ];
    }
    case 'YTD': {
      return [start.startOf('year'), end.endOf('day')];
    }
    case 'MTD': {
      return [start.startOf('month'), end.endOf('day')];
    }
    case 'T12':
      return [
        start.add(-1, 'year').startOf('month'),
        end.add(-1, 'month').endOf('month'),
      ];
    default:
      throw new Error('Unrecognized DatePeriod');
  }
}

/**
 * Returns true if the start and end date passed are the bounds of the unit
 * @example isRangeBounds('quarter', moment('2021-01-01'), moment('2021-03-31')) // true
 * @example isRangeBounds('quarter', moment('2021-02-14'), moment('2021-03-31')) // false
 * @param unit - the unit of time you want to check
 * @param startDate
 * @param endDate
 * @param granularity - the granularity to test, defaults to 'date'
 * @param useTodayAsEnd - if true, today will be treated as a valid end date, defaults to false
 */
export const isRangeBounds = (
  unit: unitOfTime.All & unitOfTime.StartOf,
  startDate?: Moment,
  endDate?: Moment,
  granularity: unitOfTime.StartOf = 'date',
  useTodayAsEnd: boolean = false
) =>
  startDate &&
  endDate &&
  startDate.get(unit) === endDate.get(unit) &&
  startDate.isSame(startDate.clone().startOf(unit), granularity) &&
  (endDate.isSame(endDate.clone().endOf(unit), granularity) ||
    (useTodayAsEnd && endDate.isSame(moment(), granularity)));
