import { Component } from 'react';
import { RouteChildrenProps } from 'react-router';

export default class RedirectErrorBoundary extends Component<
  RouteChildrenProps,
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.props?.history?.push('/error');
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
