import { Container, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    maxWidth: 630,
  },
});

const TabContent = ({
  left,
  right,
}: {
  left: NonNullable<React.ReactNode>;
  right: NonNullable<React.ReactNode>;
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={6}>
      <Grid item md={5} xs={12}>
        <Container className={classes.container}>{left}</Container>
      </Grid>
      <Grid item md={7} xs={12}>
        <Container className={classes.container}>{right}</Container>
      </Grid>
    </Grid>
  );
};

export default TabContent;
