import { InMemoryCache } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import appConfig from 'edgeco/config/appConfig';

import { TypedTypePolicies } from './advisor-summary/@types';
import { TypedTypePolicies as UserProfileTypePolicies } from './user-profile/@types';

const cachePersistence = new LocalStorageWrapper(window.localStorage);

// TODO: We could refine the cache merge/read properties to better retain data. Expecially with connections (prodDetail)
const typePolicies: TypedTypePolicies & UserProfileTypePolicies = {
  AdvisorSummaryUser: {
    fields: {
      reps: {
        merge(existing: Rep[], incoming: Rep[]) {
          return incoming;
        },
      },
      aggregatePayEarned: {
        merge: true,
      },
      aggregateProductionBreakdownByYear: {
        merge(existing, incoming) {
          return incoming; // incoming is default. This is space for adding telemetry
        },
      },
      aggregateProductionHistoryByYear: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      aggregateRepHighlights: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      aggregateTopPayout: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      aggregateYearlyTopPayout: {
        merge(existing, incoming) {
          return incoming;
        },
      },
    },
  },
  AccountModel: {
    keyFields: ['account'],
  },
  ProductionDetailRecord: {
    keyFields: ['transId'],
  },
  Household: {
    keyFields: ['householdId'],
  },
  Query: {
    fields: {
      viewer: {
        merge(existing, incoming) {
          return { ...existing, ...incoming };
        },
      },
      selectedReps: {
        read(existing) {
          return existing;
        },
      },
    },
  },
};

const cache: InMemoryCache = new InMemoryCache({
  typePolicies,
});
const currentCachePrefix = appConfig.appName;
const currentCacheSuffix = appConfig.appVersion;

const cachePersistor = new CachePersistor({
  cache,
  storage: cachePersistence,
  debug: process.env.REACT_APP_DEPLOY_ENV !== 'production',
  key: `${currentCachePrefix}-${currentCacheSuffix}`,
  trigger: 'write',
  debounce: 1000,
});
export {
  cache,
  cachePersistence,
  cachePersistor,
  currentCachePrefix,
  currentCacheSuffix,
};
