import { PropsWithChildren } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { themeExtensions as thmExt } from '../../../assets/theme';

export function getContentStyle(theme: Theme, props: any = {}) {
  const { contentStyle = {} } = props;
  return {
    border: `1px solid ${thmExt.color.borderLight}`,
    borderRadius: '8px',
    boxShadow: thmExt.boxShadow,
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    width: props.width,
    height: props.height,
    '& a': {
      color: thmExt.color.text.primary,
    },
    backgroundColor: thmExt.color.background,
    ...contentStyle,
  };
}

const useStyles = makeStyles((theme) => ({
  root: (props) => getContentStyle(theme, props),
}));

function TooltipContent(props: PropsWithChildren<any>) {
  const { children, ...rest } = props;
  const classes = useStyles(rest);
  return <div className={classes.root}>{children}</div>;
}

export default TooltipContent;
