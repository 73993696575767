import { themeExtensions } from '../../../assets/theme';
// TODO: Ticks are not applying custom style

// There is no actual implementation of XAxis. Recharts render function grabs the props only.

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function XAxisWrapper(_: XAxisProps) {
  return null;
}

XAxisWrapper.displayName = 'XAxis';
XAxisWrapper.defaultProps = {
  allowDecimals: true,
  hide: false,
  orientation: 'bottom',
  width: 0,
  height: 30,
  mirror: false,
  xAxisId: 0,
  type: 'category',
  domain: [0, 'auto'],
  padding: { left: 0, right: 0 },
  allowDataOverflow: false,
  scale: 'auto',
  reversed: false,
  allowDuplicatedCategory: false,
  tick: { fill: themeExtensions.color.black },
  tickLine: false,
  dataKey: 'key',
  interval: 0,
  minTickGap: 5,
  tickMargin: 8,
};

export default XAxisWrapper;
