import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase,
  faExclamationTriangle,
  faQuestionCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import LayeredIcon from '../common/LayeredIcon';

const useStyles = makeStyles({
  sm: {},
  md: {},
  databaseIcon: {},
  backgroundCircle: {},
  warn: {},
  info: {},
  severityIcon: {},
  iconContainer: {
    color: '#EDEDED',
    margin: 'auto',
  },
  layeredIcon: {},
  captionContainer: {
    textAlign: 'center',
  },
  caption: {
    fontWeight: 700,
    height: '100%',
    color: '#EDEDED',
    fontSize: 37,
    '&$md': {
      fontSize: 72,
    },
  },
  description: {
    '&$captionHidden': {
      marginTop: 50,
      fontSize: '2.4rem',
    },
  },
  root: {
    '&$sm': {
      width: 320,
    },
    '&$md': {
      width: 610,
    },
    '& $iconContainer': {
      '&$sm': {
        width: 150,
        height: 150,
      },
      '&$md': {
        width: 245,
        height: 280,
      },
    },
    '& $layeredIcon': {
      '&$sm': {
        width: 150,
        height: 150,
      },
      '&$md': {
        width: 245,
        height: 280,
      },
    },
    '& $databaseIcon': {
      width: 140,
      height: 145,
      bottom: 'initial',
      '&$md': {
        width: 245,
        height: 280,
      },
    },
    '& $backgroundCircle': {
      top: 'initial',
      width: 50,
      height: 50,
      '&$info': {
        width: 85,
        height: 75,
      },
      '&$md': {
        width: 100,
        height: 100,
        '&$info': {
          bottom: -10,
          width: 170,
          height: 150,
        },
      },
    },
    '& $severityIcon': {
      top: 'initial',
      width: 85,
      height: 75,
      '&$md': {
        width: 170,
        height: 150,
        '&$info': {
          bottom: -10,
        },
      },
    },
  },
  captionHidden: {},
});

export type MissingDataProps = {
  size?: 'sm' | 'md';
  variant?: 'warn' | 'info';
  description?: React.ReactNode;
  className?: string;
  hideCaption?: boolean;
};

function MissingData({
  size = 'md',
  variant = 'warn',
  description,
  className,
  hideCaption = false,
}: MissingDataProps) {
  const classes = useStyles();
  const colors = useTheme().extensions.color;
  const computedClass = clsx(classes[size], classes[variant]);
  return (
    <div className={clsx(className, classes.root, computedClass)}>
      <div className={clsx(classes.iconContainer, computedClass)}>
        <LayeredIcon className={clsx(classes.layeredIcon, computedClass)}>
          <FontAwesomeIcon
            className={clsx(classes.databaseIcon, computedClass)}
            color="#EDEDED"
            icon={faDatabase}
          />
          <FontAwesomeIcon
            className={clsx(classes.backgroundCircle, computedClass)}
            color="#FFFFFF"
            icon={faCircle}
          />
          <FontAwesomeIcon
            className={clsx(classes.severityIcon, computedClass)}
            color={colors[variant]}
            icon={variant === 'info' ? faQuestionCircle : faExclamationTriangle}
          />
        </LayeredIcon>
      </div>
      <div className={clsx(classes.captionContainer, computedClass)}>
        {!hideCaption && (
          <div className={clsx(classes.caption, computedClass)}>
            No Data Available
          </div>
        )}
        <div
          className={clsx(
            classes.description,
            hideCaption && classes.captionHidden
          )}
        >
          {description}
        </div>
      </div>
    </div>
  );
}

export default MissingData;
