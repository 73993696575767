import React, { Context, createContext, useContext } from 'react';

const getTypedContext = <T>(reactContext: any) =>
  reactContext as unknown as Context<T>;

// This is a helper function for creating generic contexts, it is not type safe and shouldn't be used directly
// this can be combined with the hook generation function below to get a type safe generic context
export const createContextUnsafe = () => React.createContext({});

// Contexts and Generics don't play well together
// this allows for better typing by the consuming code
export const useGenericCreateContext = <TContext>(
  contextValues: TContext,
  reactContext: any
) => {
  const TypedContext = getTypedContext<TContext>(reactContext);
  const context = useContext(TypedContext);
  Object.entries(contextValues).forEach(([key, value]) => {
    (context as StringIndexable)[key] = value;
  });
  return {
    value: context,
    Provider: TypedContext.Provider,
  };
};

export const useGenericContext = <T>(reactContext: any) =>
  useContext(getTypedContext<T>(reactContext));

export const createNamedContext = <TContext>(
  contextName: string,
  defaultValue: TContext
) => {
  const context = createContext(defaultValue);
  context.displayName = contextName.endsWith('Context')
    ? contextName
    : `${contextName}Context`;
  // these properties aren't exposed in the type but are used by the react development
  // tools to provide a name to the components
  (context.Provider as any).displayName = `${contextName}.Provider`;
  (context.Consumer as any).displayName = `${contextName}.Consumer`;
  return context;
};
