import { useEffect, DependencyList } from 'react';
import CancellationToken from '../utils/CancellationToken';
import CancellationTokenSource from '../utils/CancellationTokenSource';

/**
 * Reacts implementation of useEffect doesn't work with async functions, this hook can be used as a workaround
 *
 * @param effect Imperative function that can return a cleanup function
 * @param deps If present, effect will only activate if the values in the list change.
 */
export default function useEffectAsync(
  effect: (cancellationToken: CancellationToken) => Promise<void>,
  deps?: DependencyList,
  cleanup?: () => void | undefined
) {
  useEffect(
    () => {
      const tokenSource = new CancellationTokenSource();
      const promise = effect(tokenSource.token);
      return () => {
        tokenSource.cancel();
        if (cleanup) {
          promise.finally(cleanup);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}
