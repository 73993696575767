import React, { useMemo, useState, useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import match from 'autosuggest-highlight/match';

import { useUncontrolled } from 'uncontrollable';
import produce from 'immer';
import { useLogger } from '../../libs/logging/useLogger';
import { moveListMapItem } from './transfer-utils';

export type TransferListProps = {
  searchText?: string;
  setSearchText?: (text: string) => void;
  checkedItems?: Set<string>;
  setCheckedItems?: SetState<Set<string>>;
  // The primary map that's used for applied list changes

  listMap?: TransferListMap;
  setListMap?: SetState<TransferListMap | undefined>;

  listDefinition: TransferListMap;
};

// TODO: this wasn't created to be modular, but has a lot of logic. Either extract logic for other types of transfer lists or cleanup to be more modular.
function useTransferList(props: TransferListProps) {
  const logger = useLogger('components.lists.transfer');

  const controlledProps = useUncontrolled(props, {
    searchText: 'setSearchText',
    checkedItems: 'setCheckedItems',
    listMap: 'setListMap',
  }) as RequireSome<
    TransferListProps,
    | 'searchText'
    | 'setSearchText'
    | 'checkedItems'
    | 'setCheckedItems'
    | 'listMap'
    | 'setListMap'
  >;

  const {
    searchText = 'test',
    setSearchText,
    checkedItems = new Set<string>(),
    setCheckedItems,
    setListMap,
    listDefinition,
  } = controlledProps;

  const listMap = useMemo<TransferListMap>(
    () => controlledProps.listMap ?? produce(listDefinition, () => {}),
    [controlledProps.listMap, listDefinition]
  );

  const [expanded, setExpanded] = useState(new Set<GroupType>());

  const handleCollapsible = useCallback(
    (group: GroupType) => {
      const updated = new Set(expanded);
      if (updated.has(group)) {
        updated.delete(group);
      } else {
        updated.add(group);
      }
      setExpanded(updated);
    },
    [expanded]
  );

  const initialCheckedItems = new Set<string>();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setSearchText(text);
    if (text.length < 3) {
      setExpanded(new Set());
      return;
    }

    const matches = [...listDefinition.values()]
      .flat()
      .filter((x) => match(x.searchText, text).length > 0)
      .map((x) => x.homeGroup);
    const updated = new Set([...matches]);
    setExpanded(updated);
  };

  const checkAllItems = useCallback(
    (group: GroupType) => {
      setCheckedItems((prevChecked: Set<string>) => {
        let updated = new Set<string>(prevChecked);
        const allGroupValues =
          listDefinition.get(group)?.map((x) => x.id) ?? [];
        // Check to see if all items are checked in group
        const groupIsSelected = allGroupValues.every((x) => updated.has(x));
        if (groupIsSelected) {
          allGroupValues.forEach((x) => updated.delete(x));
        } else {
          updated = new Set<string>([...allGroupValues, ...updated]);
        }
        return updated;
      });
    },
    [listDefinition, setCheckedItems]
  );

  const handleChecked = useCallback(
    (items: TransferItem[], currentGroup: GroupType, checkAll?: boolean) => {
      if (checkAll) {
        checkAllItems(currentGroup);
        return;
      }
      setCheckedItems((prevChecked: Set<string>) => {
        const updated = new Set<string>(prevChecked);
        items.forEach((item) => {
          if (updated.has(item.id)) {
            updated.delete(item.id);
          } else {
            updated.add(item.id);
          }
        });
        return updated;
      });
    },
    [checkAllItems, setCheckedItems]
  );

  const onManualTransfer = (
    destination: GroupType,
    ...items: TransferItem[]
  ) => {
    let updated = new Map(listMap);

    items.forEach((item) => {
      const sourceIndex = updated
        ?.get(item.currentGroup)
        ?.findIndex((e) => e.id === item.id);
      if (sourceIndex === undefined || sourceIndex === -1) return;

      const transfer = {
        source: {
          index: sourceIndex,
          locationId: item.currentGroup,
        },
        destination: {
          index: updated.get(destination)?.length ?? 0,
          locationId: destination,
        },
      };
      updated = moveListMapItem(transfer, updated);
    });
    setCheckedItems(initialCheckedItems);
    setListMap(updated);
    logger.debug('manually transferred', updated);
  };

  const onDragEnd = (result: DropResult) => {
    // Convert Drop Result to List Transfer shape
    const source = {
      locationId: result.source.droppableId,
      index: result.source.index,
    };
    const destination = result.destination && {
      locationId: result.destination.droppableId,
      index: result.destination.index,
    };
    if (!destination) return;
    const remapped = moveListMapItem({ source, destination }, listMap);

    setListMap(remapped);
  };

  return {
    checkedItems,
    setCheckedItems,
    handleChecked,

    listMap,
    onDragEnd,
    onManualTransfer,

    searchText,
    handleSearchChange,

    expanded,
    handleCollapsible,

    listDefinition,
  };
}

export default useTransferList;
