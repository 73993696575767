import moment, { Moment } from 'moment';
// Sets the standard query format to a determinate ISO 8601 string for easy rehydration.  Could parse this without moment, but it makes it easier.  Javascripts toIsoString sets to this format by default
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
// YYYY-MM-DDTHH:mm:ss.sssZ
const DATE_FORMAT = moment.ISO_8601;
export const getLocation = (href: string) => {
  const match = href.match(
    // eslint-disable-next-line no-useless-escape
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  );
  return (
    match && {
      href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      path: match[5],
      search: match[6],
      hash: match[7],
    }
  );
};

export const getPath = (href: string) => {
  const location = getLocation(href);
  let { path }: any = location;
  const { search, hash }: any = location;

  if (search) {
    path += search;
  }

  if (hash) {
    path += hash;
  }

  return path;
};

function formatSearchParamValue(value: any) {
  if (value instanceof Date) {
    return value.toISOString();
  }
  if (moment.isMoment(value)) {
    return (value as Moment).toISOString();
  }
  return value?.toString();
}

function formatSearchParam(
  searchParams: URLSearchParams,
  key: string,
  value: any,
  action: 'set' | 'append'
) {
  if (action === 'set') {
    searchParams.delete(key);
  }
  if (Array.isArray(value)) {
    if (value.length > 0) {
      // set or append the first array value, setting multiple times would overwrite
      searchParams[action](key, formatSearchParamValue(value[0]));

      // append the remaining values
      value.slice(1).forEach((arrayValue) => {
        const formatted = formatSearchParamValue(arrayValue);
        if (formatted !== undefined) {
          searchParams.append(key, formatted);
        }
      });
    }
  } else {
    const formatted = formatSearchParamValue(value);
    if (formatted !== undefined) {
      searchParams[action](key, formatted);
    }
  }
}

export function appendSearchValue(
  searchParams: URLSearchParams,
  [key, value]: [any, any]
): URLSearchParams {
  formatSearchParam(searchParams, key.toString(), value, 'append');
  return searchParams;
}

export function setSearchValue(
  searchParams: URLSearchParams,
  [key, value]: [any, any]
): URLSearchParams {
  formatSearchParam(searchParams, key.toString(), value, 'set');
  return searchParams;
}

export const buildQueryString = (obj: object) => {
  const searchParams = Object.entries(obj).reduce(
    setSearchValue,
    new URLSearchParams()
  );
  return `?${searchParams.toString()}`;
};

const tryParseDate = (value: string): Moment | boolean => {
  const converted = moment.utc(value, DATE_FORMAT, true);
  return converted.isValid() ? converted.clone().local() : false;
};

type QueryValue = Moment | string | number;
export const convertQueryToValue = (
  value: string[] | string
): QueryValue | QueryValue[] => {
  let flatValue: string;
  if (Array.isArray(value)) {
    if (value.length > 1) {
      return value.map((v) => convertQueryToValue(v)) as QueryValue[];
    }
    [flatValue] = value;
  } else {
    flatValue = value as string;
  }

  // Is number
  if (/^[+-]?\d+(\.\d+)?$/.test(flatValue)) {
    return parseFloat(flatValue);
  }

  // Is date
  const result = tryParseDate(flatValue);
  if (result) return result as Moment;

  // Is other/string
  return flatValue;
};

export function getTnsUrl() {
  let tnsSubdomain: string;
  if (process.env.REACT_APP_DEPLOY_ENV.includes('development')) {
    tnsSubdomain = 'dev';
  } else if (
    process.env.REACT_APP_DEPLOY_ENV === 'qa' ||
    process.env.REACT_APP_DEPLOY_ENV === 'uat'
  ) {
    tnsSubdomain = 'qa';
  } else {
    tnsSubdomain = 'secure';
  }

  return `https://${tnsSubdomain}.macg.com`;
}
