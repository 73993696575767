import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Uuid: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  node?: Maybe<Node>;
  viewer?: Maybe<Viewer>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateHousehold?: Maybe<HouseholdPayload>;
  DeactivateHousehold?: Maybe<HouseholdPayload>;
  AddAccountsToHousehold?: Maybe<HouseholdPayload>;
  RemoveAccountsFromHousehold?: Maybe<HouseholdPayload>;
  UpdateHouseholdName?: Maybe<HouseholdPayload>;
  AddReportToUser: Scalars['Uuid'];
  RemoveReportFromUser: Scalars['Boolean'];
};

export type MutationCreateHouseholdArgs = {
  input: CreateHouseholdInput;
};

export type MutationDeactivateHouseholdArgs = {
  input: DeleteHouseholdInput;
};

export type MutationAddAccountsToHouseholdArgs = {
  input: HouseholdAccountChangesInput;
};

export type MutationRemoveAccountsFromHouseholdArgs = {
  input: HouseholdAccountChangesInput;
};

export type MutationUpdateHouseholdNameArgs = {
  input: HouseholdNameChangeInput;
};

export type MutationAddReportToUserArgs = {
  input: AddUserReportInput;
};

export type MutationRemoveReportFromUserArgs = {
  input: RemoveUserReportInput;
};

export type IUserError = {
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type UserError = IUserError & {
  __typename?: 'UserError';
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Payload = {
  __typename?: 'Payload';
  request: Request;
  errors?: Maybe<Array<IUserError>>;
  partitionKey: Scalars['String'];
};

export type Request = {
  __typename?: 'Request';
  status: RequestStatus;
  correlationId: Scalars['Uuid'];
};

/** The node interface is implemented by entities that have a global unique identifier. */
export type Node = {
  id: Scalars['ID'];
};

export type Household = Node & {
  __typename?: 'Household';
  accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy: Scalars['Uuid'];
  createdOn: Scalars['DateTime'];
  documentType?: Maybe<Scalars['String']>;
  householdId: Scalars['Uuid'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  managers?: Maybe<Array<Maybe<HouseholdManager>>>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CommandStatus>;
};

export type ProductionDetailUserReport = {
  __typename?: 'ProductionDetailUserReport';
  revenueCategories: Array<RevenueCategoryPaymentType>;
  visibleColumns?: Maybe<Array<Maybe<Scalars['String']>>>;
  compareTo: Scalars['Int'];
  reportName?: Maybe<Scalars['String']>;
  id?: Maybe<GuidEntityId>;
};

export enum RevenueCategoryPaymentType {
  HedgeFunds = 'HEDGE_FUNDS',
  Dpp = 'DPP',
  PartnershipFees = 'PARTNERSHIP_FEES',
  PartnershipCommission = 'PARTNERSHIP_COMMISSION',
  PartnershipClearing = 'PARTNERSHIP_CLEARING',
  BrokerageCommissions = 'BROKERAGE_COMMISSIONS',
  MutualFundNfs = 'MUTUAL_FUND_NFS',
  OverridePaymentMacc = 'OVERRIDE_PAYMENT_MACC',
  MutualFund12B1 = 'MUTUAL_FUND12_B1',
  Annuities = 'ANNUITIES',
  MutualFundComm = 'MUTUAL_FUND_COMM',
  MiscCostsMacc = 'MISC_COSTS_MACC',
  MiscCostsMafm = 'MISC_COSTS_MAFM',
  StreetscapeBilling = 'STREETSCAPE_BILLING',
  MiscRevenueMacc = 'MISC_REVENUE_MACC',
  MafsFees = 'MAFS_FEES',
  MapsFees = 'MAPS_FEES',
  MiscRevenueMafm = 'MISC_REVENUE_MAFM',
  OverridePaymentMafm = 'OVERRIDE_PAYMENT_MAFM',
  AdvChNfs = 'ADV_CH_NFS',
  AdvChTns = 'ADV_CH_TNS',
  AlternativeAssetRevenue = 'ALTERNATIVE_ASSET_REVENUE',
  AdvisorTechnologyBilling = 'ADVISOR_TECHNOLOGY_BILLING',
}

/** The current viewer. */
export type Viewer = {
  __typename?: 'viewer';
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Uuid']>;
  households: Array<Household>;
  reports: Array<ProductionDetailUserReport>;
};

export type CreateHouseholdInput = {
  name: Scalars['String'];
  householdId: Scalars['Uuid'];
};

export type DeleteHouseholdInput = {
  householdId: Scalars['Uuid'];
};

export type HouseholdAccountChangesInput = {
  householdId: Scalars['Uuid'];
  accounts: Array<Scalars['String']>;
};

export type HouseholdNameChangeInput = {
  householdId: Scalars['Uuid'];
  newName: Scalars['String'];
};

export type AddUserReportInput = {
  userId: Scalars['Uuid'];
  report: ProductionDetailInput;
};

export type RemoveUserReportInput = {
  userId: Scalars['Uuid'];
  reportId: Scalars['Uuid'];
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
}

export type HouseholdPayload = {
  __typename?: 'HouseholdPayload';
  householdState?: Maybe<Household>;
  request: Request;
  errors?: Maybe<Array<IUserError>>;
  partitionKey: Scalars['String'];
};

export enum RequestStatus {
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
}

export type HouseholdManager = {
  __typename?: 'HouseholdManager';
  compareTo: Scalars['Int'];
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<GuidEntityId>;
};

export type GuidEntityId = {
  __typename?: 'GuidEntityId';
  compareTo: Scalars['Int'];
  identifier: Scalars['Uuid'];
};

export type ProductionDetailInput = {
  revenueCategories: Array<RevenueCategoryPaymentType>;
  visibleColumns: Array<Scalars['String']>;
  name: Scalars['String'];
};

export enum CommandStatus {
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Failed = 'FAILED',
}

export type AddAccountsMutationVariables = Exact<{
  householdId: Scalars['Uuid'];
  accounts: Array<Scalars['String']>;
}>;

export type AddAccountsMutation = { __typename?: 'Mutation' } & {
  AddAccountsToHousehold?: Maybe<
    { __typename?: 'HouseholdPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type CreateHouseholdMutationVariables = Exact<{
  householdId: Scalars['Uuid'];
  name: Scalars['String'];
}>;

export type CreateHouseholdMutation = { __typename?: 'Mutation' } & {
  result?: Maybe<
    { __typename?: 'HouseholdPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
      householdState?: Maybe<
        { __typename?: 'Household' } & Pick<
          Household,
          'householdId' | 'name' | 'accounts' | 'status'
        >
      >;
    }
  >;
};

export type DeleteHouseholdMutationVariables = Exact<{
  householdId: Scalars['Uuid'];
}>;

export type DeleteHouseholdMutation = { __typename?: 'Mutation' } & {
  DeactivateHousehold?: Maybe<
    { __typename?: 'HouseholdPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type RemoveAccountMutationVariables = Exact<{
  householdId: Scalars['Uuid'];
  account: Scalars['String'];
}>;

export type RemoveAccountMutation = { __typename?: 'Mutation' } & {
  RemoveAccountsFromHousehold?: Maybe<
    { __typename?: 'HouseholdPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type RenameHouseholdMutationVariables = Exact<{
  householdId: Scalars['Uuid'];
  name: Scalars['String'];
}>;

export type RenameHouseholdMutation = { __typename?: 'Mutation' } & {
  UpdateHouseholdName?: Maybe<
    { __typename?: 'HouseholdPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'UserError' } & Pick<UserError, 'code' | 'message'>
        >
      >;
    }
  >;
};

export type GetHouseholdFragment = { __typename?: 'Household' } & Pick<
  Household,
  'name' | 'householdId' | 'accounts'
>;

export type NewTodoFragment = { __typename?: 'Household' } & Pick<
  Household,
  'householdId' | 'name' | 'accounts' | 'status'
>;

export type AddUserReportMutationVariables = Exact<{
  userID: Scalars['Uuid'];
  report: ProductionDetailInput;
}>;

export type AddUserReportMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'AddReportToUser'
>;

export type NewReportFragment = {
  __typename?: 'ProductionDetailUserReport';
} & Pick<
  ProductionDetailUserReport,
  'reportName' | 'revenueCategories' | 'visibleColumns'
> & {
    id?: Maybe<
      { __typename?: 'GuidEntityId' } & Pick<GuidEntityId, 'identifier'>
    >;
  };

export type RemoveReportFromUserMutationVariables = Exact<{
  reportId: Scalars['Uuid'];
  userId: Scalars['Uuid'];
}>;

export type RemoveReportFromUserMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'RemoveReportFromUser'
>;

export type GetHouseholdQueryVariables = Exact<{ [key: string]: never }>;

export type GetHouseholdQuery = { __typename?: 'Query' } & {
  viewer?: Maybe<
    { __typename?: 'viewer' } & {
      households: Array<
        { __typename?: 'Household' } & Pick<
          Household,
          'householdId' | 'name' | 'accounts' | 'status'
        >
      >;
    }
  >;
};

export type GetUserReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserReportsQuery = { __typename?: 'Query' } & {
  viewer?: Maybe<
    { __typename?: 'viewer' } & {
      reports: Array<
        { __typename?: 'ProductionDetailUserReport' } & Pick<
          ProductionDetailUserReport,
          'reportName' | 'revenueCategories' | 'visibleColumns'
        > & {
            id?: Maybe<
              { __typename?: 'GuidEntityId' } & Pick<GuidEntityId, 'identifier'>
            >;
          }
      >;
    }
  >;
};

export const GetHouseholdFragmentDoc = gql`
  fragment GetHousehold on Household {
    name
    householdId
    accounts
  }
`;
export const NewTodoFragmentDoc = gql`
  fragment NewTodo on Household {
    householdId
    name
    accounts
    status
  }
`;
export const NewReportFragmentDoc = gql`
  fragment NewReport on ProductionDetailUserReport {
    id {
      identifier
    }
    reportName
    revenueCategories
    visibleColumns
  }
`;
export const AddAccountsDocument = gql`
  mutation AddAccounts($householdId: Uuid!, $accounts: [String!]!) {
    AddAccountsToHousehold(
      input: { householdId: $householdId, accounts: $accounts }
    ) {
      errors {
        code
        message
      }
    }
  }
`;
export type AddAccountsMutationFn = Apollo.MutationFunction<
  AddAccountsMutation,
  AddAccountsMutationVariables
>;

/**
 * __useAddAccountsMutation__
 *
 * To run a mutation, you first call `useAddAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountsMutation, { data, loading, error }] = useAddAccountsMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      accounts: // value for 'accounts'
 *   },
 * });
 */
export function useAddAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAccountsMutation,
    AddAccountsMutationVariables
  >
) {
  return Apollo.useMutation<AddAccountsMutation, AddAccountsMutationVariables>(
    AddAccountsDocument,
    baseOptions
  );
}
export type AddAccountsMutationHookResult = ReturnType<
  typeof useAddAccountsMutation
>;
export type AddAccountsMutationResult =
  Apollo.MutationResult<AddAccountsMutation>;
export type AddAccountsMutationOptions = Apollo.BaseMutationOptions<
  AddAccountsMutation,
  AddAccountsMutationVariables
>;
export const CreateHouseholdDocument = gql`
  mutation CreateHousehold($householdId: Uuid!, $name: String!) {
    result: CreateHousehold(input: { householdId: $householdId, name: $name }) {
      errors {
        code
        message
      }
      householdState {
        householdId
        name
        accounts
        status @client
      }
    }
  }
`;
export type CreateHouseholdMutationFn = Apollo.MutationFunction<
  CreateHouseholdMutation,
  CreateHouseholdMutationVariables
>;

/**
 * __useCreateHouseholdMutation__
 *
 * To run a mutation, you first call `useCreateHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHouseholdMutation, { data, loading, error }] = useCreateHouseholdMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateHouseholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHouseholdMutation,
    CreateHouseholdMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateHouseholdMutation,
    CreateHouseholdMutationVariables
  >(CreateHouseholdDocument, baseOptions);
}
export type CreateHouseholdMutationHookResult = ReturnType<
  typeof useCreateHouseholdMutation
>;
export type CreateHouseholdMutationResult =
  Apollo.MutationResult<CreateHouseholdMutation>;
export type CreateHouseholdMutationOptions = Apollo.BaseMutationOptions<
  CreateHouseholdMutation,
  CreateHouseholdMutationVariables
>;
export const DeleteHouseholdDocument = gql`
  mutation DeleteHousehold($householdId: Uuid!) {
    DeactivateHousehold(input: { householdId: $householdId }) {
      errors {
        code
        message
      }
    }
  }
`;
export type DeleteHouseholdMutationFn = Apollo.MutationFunction<
  DeleteHouseholdMutation,
  DeleteHouseholdMutationVariables
>;

/**
 * __useDeleteHouseholdMutation__
 *
 * To run a mutation, you first call `useDeleteHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHouseholdMutation, { data, loading, error }] = useDeleteHouseholdMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useDeleteHouseholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHouseholdMutation,
    DeleteHouseholdMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteHouseholdMutation,
    DeleteHouseholdMutationVariables
  >(DeleteHouseholdDocument, baseOptions);
}
export type DeleteHouseholdMutationHookResult = ReturnType<
  typeof useDeleteHouseholdMutation
>;
export type DeleteHouseholdMutationResult =
  Apollo.MutationResult<DeleteHouseholdMutation>;
export type DeleteHouseholdMutationOptions = Apollo.BaseMutationOptions<
  DeleteHouseholdMutation,
  DeleteHouseholdMutationVariables
>;
export const RemoveAccountDocument = gql`
  mutation RemoveAccount($householdId: Uuid!, $account: String!) {
    RemoveAccountsFromHousehold(
      input: { householdId: $householdId, accounts: [$account] }
    ) {
      errors {
        code
        message
      }
    }
  }
`;
export type RemoveAccountMutationFn = Apollo.MutationFunction<
  RemoveAccountMutation,
  RemoveAccountMutationVariables
>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRemoveAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >(RemoveAccountDocument, baseOptions);
}
export type RemoveAccountMutationHookResult = ReturnType<
  typeof useRemoveAccountMutation
>;
export type RemoveAccountMutationResult =
  Apollo.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = Apollo.BaseMutationOptions<
  RemoveAccountMutation,
  RemoveAccountMutationVariables
>;
export const RenameHouseholdDocument = gql`
  mutation RenameHousehold($householdId: Uuid!, $name: String!) {
    UpdateHouseholdName(input: { householdId: $householdId, newName: $name }) {
      errors {
        code
        message
      }
    }
  }
`;
export type RenameHouseholdMutationFn = Apollo.MutationFunction<
  RenameHouseholdMutation,
  RenameHouseholdMutationVariables
>;

/**
 * __useRenameHouseholdMutation__
 *
 * To run a mutation, you first call `useRenameHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameHouseholdMutation, { data, loading, error }] = useRenameHouseholdMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameHouseholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RenameHouseholdMutation,
    RenameHouseholdMutationVariables
  >
) {
  return Apollo.useMutation<
    RenameHouseholdMutation,
    RenameHouseholdMutationVariables
  >(RenameHouseholdDocument, baseOptions);
}
export type RenameHouseholdMutationHookResult = ReturnType<
  typeof useRenameHouseholdMutation
>;
export type RenameHouseholdMutationResult =
  Apollo.MutationResult<RenameHouseholdMutation>;
export type RenameHouseholdMutationOptions = Apollo.BaseMutationOptions<
  RenameHouseholdMutation,
  RenameHouseholdMutationVariables
>;
export const AddUserReportDocument = gql`
  mutation AddUserReport($userID: Uuid!, $report: ProductionDetailInput!) {
    AddReportToUser(input: { userId: $userID, report: $report })
  }
`;
export type AddUserReportMutationFn = Apollo.MutationFunction<
  AddUserReportMutation,
  AddUserReportMutationVariables
>;

/**
 * __useAddUserReportMutation__
 *
 * To run a mutation, you first call `useAddUserReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserReportMutation, { data, loading, error }] = useAddUserReportMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      report: // value for 'report'
 *   },
 * });
 */
export function useAddUserReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserReportMutation,
    AddUserReportMutationVariables
  >
) {
  return Apollo.useMutation<
    AddUserReportMutation,
    AddUserReportMutationVariables
  >(AddUserReportDocument, baseOptions);
}
export type AddUserReportMutationHookResult = ReturnType<
  typeof useAddUserReportMutation
>;
export type AddUserReportMutationResult =
  Apollo.MutationResult<AddUserReportMutation>;
export type AddUserReportMutationOptions = Apollo.BaseMutationOptions<
  AddUserReportMutation,
  AddUserReportMutationVariables
>;
export const RemoveReportFromUserDocument = gql`
  mutation RemoveReportFromUser($reportId: Uuid!, $userId: Uuid!) {
    RemoveReportFromUser(input: { reportId: $reportId, userId: $userId })
  }
`;
export type RemoveReportFromUserMutationFn = Apollo.MutationFunction<
  RemoveReportFromUserMutation,
  RemoveReportFromUserMutationVariables
>;

/**
 * __useRemoveReportFromUserMutation__
 *
 * To run a mutation, you first call `useRemoveReportFromUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReportFromUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReportFromUserMutation, { data, loading, error }] = useRemoveReportFromUserMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveReportFromUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveReportFromUserMutation,
    RemoveReportFromUserMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveReportFromUserMutation,
    RemoveReportFromUserMutationVariables
  >(RemoveReportFromUserDocument, baseOptions);
}
export type RemoveReportFromUserMutationHookResult = ReturnType<
  typeof useRemoveReportFromUserMutation
>;
export type RemoveReportFromUserMutationResult =
  Apollo.MutationResult<RemoveReportFromUserMutation>;
export type RemoveReportFromUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveReportFromUserMutation,
  RemoveReportFromUserMutationVariables
>;
export const GetHouseholdDocument = gql`
  query GetHousehold {
    viewer {
      households {
        householdId
        name
        accounts
        status @client
      }
    }
  }
`;

/**
 * __useGetHouseholdQuery__
 *
 * To run a query within a React component, call `useGetHouseholdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseholdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHouseholdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHouseholdQuery,
    GetHouseholdQueryVariables
  >
) {
  return Apollo.useQuery<GetHouseholdQuery, GetHouseholdQueryVariables>(
    GetHouseholdDocument,
    baseOptions
  );
}
export function useGetHouseholdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHouseholdQuery,
    GetHouseholdQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetHouseholdQuery, GetHouseholdQueryVariables>(
    GetHouseholdDocument,
    baseOptions
  );
}
export type GetHouseholdQueryHookResult = ReturnType<
  typeof useGetHouseholdQuery
>;
export type GetHouseholdLazyQueryHookResult = ReturnType<
  typeof useGetHouseholdLazyQuery
>;
export type GetHouseholdQueryResult = Apollo.QueryResult<
  GetHouseholdQuery,
  GetHouseholdQueryVariables
>;
export const GetUserReportsDocument = gql`
  query GetUserReports {
    viewer {
      reports {
        id {
          identifier
        }
        reportName
        revenueCategories
        visibleColumns
      }
    }
  }
`;

/**
 * __useGetUserReportsQuery__
 *
 * To run a query within a React component, call `useGetUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserReportsQuery,
    GetUserReportsQueryVariables
  >
) {
  return Apollo.useQuery<GetUserReportsQuery, GetUserReportsQueryVariables>(
    GetUserReportsDocument,
    baseOptions
  );
}
export function useGetUserReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserReportsQuery,
    GetUserReportsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetUserReportsQuery, GetUserReportsQueryVariables>(
    GetUserReportsDocument,
    baseOptions
  );
}
export type GetUserReportsQueryHookResult = ReturnType<
  typeof useGetUserReportsQuery
>;
export type GetUserReportsLazyQueryHookResult = ReturnType<
  typeof useGetUserReportsLazyQuery
>;
export type GetUserReportsQueryResult = Apollo.QueryResult<
  GetUserReportsQuery,
  GetUserReportsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    IUserError: ['UserError'],
    Node: ['Household'],
  },
};
export default result;
