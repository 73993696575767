import { Button, makeStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';

const useStyles = makeStyles(({ spacing, extensions }) => ({
  container: {
    margin: spacing(1.5),
  },
  button: {
    marginRight: spacing(0.5),
    minWidth: 0, // override Mui
    with: 55,
    border: extensions.borders.light,
  },
}));

type QuarterButtonProps = {
  date: Moment;
  onClick: (start: Moment, end: Moment) => void;
};

function getCompletedQuarters(date: Moment) {
  const lastQuarter = date.isBefore(moment().startOf('year'))
    ? 4
    : date.quarter();
  return Array(lastQuarter)
    .fill(undefined)
    .map((_, i) => i + 1);
}

export default function QuarterButtons({ date, onClick }: QuarterButtonProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {getCompletedQuarters(date).map((quarter) => (
        <Button
          key={`quarter-${quarter}`}
          className={classes.button}
          variant="outlined"
          onClick={() => {
            const start = date.clone().quarter(quarter).startOf('quarter');
            const end = start.clone().endOf('quarter');
            onClick(start, end);
          }}
        >
          Q{quarter}
        </Button>
      ))}
    </div>
  );
}
