import { Divider, List, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import Checkbox, { CheckboxProps } from '../input/Checkbox';
import { themeExtensions } from '../../assets/theme';
import ListItem from './ListItem';

const useStyles = makeStyles((theme: Theme) => ({
  listStyle: {
    padding: theme.spacing(0, 3),
  },
  listTextStyle: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
  listItemActionStyle: {
    minWidth: 0,
  },
  selectAllItem: {},
  rootList: {},
  pinnedListItem: {},
}));

type CheckboxListType = {
  listItems: TransferItem[];
  listId: GroupType;
  checkedItems: Set<string>;
  handleCheck: (
    items: TransferItem[],
    listId: GroupType,
    checkAll?: boolean
  ) => void;
  showSelectAll?: boolean;
  checkBoxProps?: Partial<CheckboxProps>;
  listProps?: import('@material-ui/core').ListProps;
  renderListItem?: ListItemProps | ((item: TransferItem) => ListItemProps);
  classes?: Partial<ReturnType<typeof useStyles>>;
};

// TODO: design checkbox icon.  Current implementation of font awesome and material don't have border + fill.
// Layering Font Awesome checkboxes to achieve border - fill effect
function CheckboxList({
  showSelectAll,
  listItems,
  listId,
  checkedItems,
  handleCheck,
  renderListItem,
  listProps,
  classes,
  checkBoxProps = {
    borderColor: themeExtensions.color.borderLight,
    backgroundColor: themeExtensions.color.white,
    checkColor: themeExtensions.color.emperor,
  },
}: CheckboxListType) {
  const renderedClasses = useStyles({ classes });

  const mainListItems = listItems.filter((x) => !x.isPinned);
  const pinnedListItems = listItems.filter((x) => x.isPinned);

  const transferItemToListItem = (item: TransferItem) => {
    const labelId = `checkbox-list-label-${item.label}`;
    const renderedProps =
      typeof renderListItem === 'function'
        ? renderListItem(item)
        : renderListItem;

    const isChecked = renderedProps?.disabled || checkedItems.has(item.id);

    const { className, ...rendered } = renderedProps || {};

    return (
      <ListItem
        key={item.id}
        dense
        button
        disableGutters={true}
        className={clsx(
          renderedClasses.listStyle,
          className,
          item.isPinned && renderedClasses.pinnedListItem
        )}
        isNarrow={true}
        onClick={() => handleCheck([item], listId)}
        primaryAction={{
          props: {
            className: renderedClasses.listItemActionStyle,
          },
          children: (
            <Checkbox
              isChecked={isChecked}
              labelId={labelId}
              {...checkBoxProps}
            />
          ),
        }}
        {...rendered}
      />
    );
  };
  return (
    <List
      key={listId}
      {...listProps}
      className={clsx(listProps?.className, renderedClasses.rootList)}
    >
      {showSelectAll && (
        <ListItem
          key={`select-all-${listId}`}
          dense
          button
          disableGutters={true}
          className={clsx(
            renderedClasses.listStyle,
            renderedClasses.selectAllItem
          )}
          isNarrow={true}
          onClick={() => handleCheck([], listId, true)}
          text={{
            primary: (
              <div className={renderedClasses.listTextStyle}>Select All</div>
            ),
          }}
          primaryAction={{
            props: {
              className: renderedClasses.listItemActionStyle,
            },
            children: (
              <Checkbox
                isChecked={listItems.every((x) => checkedItems.has(x.id))}
                labelId={listId.toString()}
                {...checkBoxProps}
              />
            ),
          }}
        />
      )}

      {mainListItems.map((item) => transferItemToListItem(item))}

      {pinnedListItems.length > 0 && (
        <>
          <Divider
            style={{
              marginTop: '6px',
              marginBottom: '6px',
              backgroundColor: themeExtensions.color.borderLight,
            }}
          />
          {pinnedListItems.map((item) => transferItemToListItem(item))}
        </>
      )}
    </List>
  );
}

export default CheckboxList;
