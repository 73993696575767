import { useMemo, useState } from 'react';
import { getChartDomain } from '../chartUtils';

export default function useCumulativeDomain(
  data: any,
  selectedReps: number[] = [],
  dataAccessors?: DataAccessor[]
): [number, number] {
  const [previousReps, setPreviousReps] = useState<number[]>();

  const domainState = useState(100);
  let [domainMax] = domainState;
  const [, setDomainMax] = domainState;

  if (
    selectedReps.length !== previousReps?.length ||
    selectedReps.some((s) => !previousReps?.includes(s))
  ) {
    setDomainMax(100);
    setPreviousReps(selectedReps);
    domainMax = 100;
  }

  const domain = getChartDomain(data, domainMax, 0, dataAccessors);
  if (domain[1] > domainMax) {
    setDomainMax(domain[1]);
  }

  const min = domain[0];
  const max = domain[1];
  return useMemo<[number, number]>(() => [min, max], [max, min]);
}
