import { Grid, GridSize, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { themeExtensions as thmExt } from '../../assets/theme';
import DraggableList from '../lists/DraggableList';

import { SELECTION_GROUP } from './constants';

import IconButton from '../buttons/IconButton';

const useStyles = makeStyles({
  scrollContainer: {
    overflowY: 'auto',
    maxHeight: 'inherit',
  },
  baseListStyle: {
    padding: 0,
  },
  rightStyle: {
    backgroundColor: thmExt.color.alabaster,
  },
  listText: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: '2.1rem',
  },
});

type RightListProps = {
  items: TransferItem[];
  classes: Partial<ReturnType<typeof useStyles>>;
  onManualTransfer: (destination: GroupType, ...items: TransferItem[]) => void;
  renderOptions: TransferListOptions;
  gridSize?: boolean | 'auto' | GridSize | undefined;
};

function RightList({
  classes,
  items,
  onManualTransfer,
  gridSize,
}: RightListProps) {
  const renderedClasses = useStyles({ classes });

  const renderRightList = (
    <DraggableList
      listId={SELECTION_GROUP}
      listItems={items}
      listProps={{
        className: renderedClasses.baseListStyle,
      }}
      renderListItem={(item) =>
        ({
          isRounded: true,
          text: {
            primary: (
              <div className={renderedClasses.listText}>{item.label}</div>
            ),
          },
          secondaryAction: {
            iconButton: (
              <IconButton
                variant="trash"
                primaryColor={thmExt.color.iconPrimary}
                onClick={() => onManualTransfer(item.homeGroup, item)}
              />
            ),
          },
        } as DraggableListItemProps)
      }
    />
  );

  return (
    <Grid
      item
      xs={gridSize ?? 'auto'}
      className={clsx(
        renderedClasses.rightStyle,
        renderedClasses.scrollContainer
      )}
    >
      {renderRightList}
    </Grid>
  );
}

export default RightList;
