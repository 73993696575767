import { makeStyles, Theme } from '@material-ui/core';
import { themeExtensions as thmExt } from '../../../assets/theme';

const useStyles = makeStyles<Theme, LegendEntryProps>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  symbol: (props) => ({
    marginRight: theme.spacing(1),
    border: `2px solid ${thmExt.color.borderLight}`,
    width: '25px',
    height: '25px',
    background: props.color,
  }),
  label: {
    ...thmExt.font.infographicLabel,
  },
}));

type LegendEntryProps = {
  label: string | Date | number;
  color: string;
};

function LegendEntry(props: LegendEntryProps) {
  const { label } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <div className={classes.symbol} />
      <span className={classes.label}>{label}</span>
    </div>
  );
}

export default LegendEntry;
