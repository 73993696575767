import { createStyles, Grid, StyledComponentProps } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import DateFilter from './DateFilter';
import SelectFilter from './SelectFilter';
import { FilterContainerClassKey } from './FilterContainer';

const styles = (theme: EdgeCoTheme) =>
  createStyles({
    filterContainer: {},
    filterGroup: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: theme.spacing(1),
    },
    filterGroupTitle: {
      fontSize: '2.1rem',
      width: '100%',
      borderBottom: theme.extensions.borders.light,
    },
    empty: {},
    titleContainer: {
      flex: 1,
    },
  });

export type FilterGroupClassKey = keyof ReturnType<typeof styles>;

type Props = {
  classes: Disjoint<
    WithStyles<FilterGroupClassKey>['classes'],
    NonNullable<StyledComponentProps<FilterContainerClassKey>['classes']>
  >;
  filters: TableFilterCollection;
  depth?: number;
  breakpoints?: Breakpoints;
};

function PureTableFilterGroup({
  filters,
  classes,
  breakpoints,
  depth = 0,
}: Props) {
  const {
    filterContainer,
    filterGroup,
    titleContainer,
    filterGroupTitle,
    empty,
    ...filterClasses
  } = classes;
  return (
    <Grid container className={filterContainer}>
      {filters.map((filter, index) => {
        if (filter.kind === 'group') {
          return (
            <Grid
              key={filter.id!}
              item
              xs={12}
              {...breakpoints}
              {...filter.breakpoints?.group}
              className={filterGroup}
            >
              <div className={titleContainer}>
                <div className={clsx(filterGroupTitle, !filter.title && empty)}>
                  {filter.title}
                </div>
              </div>
              <PureTableFilterGroup
                breakpoints={filter.breakpoints?.filters}
                classes={classes}
                filters={filter.filters}
                depth={depth + 1}
              />
            </Grid>
          );
        }
        const filterProps = {
          key: filter.id!,
          breakpoints,
          classes: filterClasses,
          index,
          filter,
          depth,
        };
        return filter.kind === 'date' ? (
          <DateFilter {...filterProps}></DateFilter>
        ) : (
          <SelectFilter
            {...filterProps}
            filter={filter as SelectFilter}
          ></SelectFilter>
        );
      })}
    </Grid>
  );
}

const TableFilterGroup = withStyles(styles, { name: 'TableFilterGroup' })(
  PureTableFilterGroup
);
export default TableFilterGroup;
export type TableFilterGroupInstance = typeof TableFilterGroup;
export type TableFilterGroupProps =
  React.ComponentProps<TableFilterGroupInstance>;
