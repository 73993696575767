import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 1040,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 105,
  },
});

function FeatureFeature() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.title} variant="h2">
          This is template for page or component in the future. If you're in UAT
          or QA you shouldn't see this.
        </Typography>
      </div>
    </div>
  );
}

export default FeatureFeature;
