import { makeStyles } from '@material-ui/core';
import { PageWrapper } from 'edgeco/components/root';
import PageFooter from 'edgeco/components/root/PageFooter';
import useAuth from 'edgeco/hooks/useAuth';
import { AccordionList } from 'edgeco/components/lists/accordion';
import AccordionHeaderAndSubs from 'edgeco/components/lists/accordion/AccordionHeaderAndSubs';
import CreateYourTeams from './create-your-teams/CreateYourTeams';
import PersonalizeHeadlines from './personalize-headlines/PersonalizeHeadlines';
import ManageYourReports from './manage-your-reports/MangeYourReports';
import CreateYourHousehold from './create-your-household/CreateYourHousehold';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: 'auto',
    marginLeft: 'auto',

    [theme.breakpoints.up('md')]: {
      // medium: 960px or larger
      width: 920,
    },

    [theme.breakpoints.up('lg')]: {
      // large: 1280px or larger
      width: 1170,
    },
    [theme.breakpoints.up('xl')]: {
      // extra-large: 1920px or larger
      width: 1366,
    },
  },
}));

const groups = [
  {
    text: 'Create Your Teams',
    subText:
      'Total amounts below are based on data that was acquired yesterday. Data gathering times vary.',
    Container: () => {
      return <CreateYourTeams />;
    },
    entries: [],
  },
  {
    text: 'Personalize Your Headlines',
    subText:
      'Total amounts below are based on data that was acquired yesterday. Data gathering times vary.',
    Container: () => {
      return <PersonalizeHeadlines />;
    },
    entries: [],
  },
  {
    text: 'Manage Your Reports',
    subText:
      'Total amounts below are based on data that was acquired yesterday. Data gathering times vary.',
    Container: () => {
      return <ManageYourReports />;
    },
    entries: [],
  },
  {
    text: 'Create Your Household',
    subText:
      'Total amounts below are based on data that was acquired yesterday. Data gathering times vary.',
    Container: () => {
      return <CreateYourHousehold />;
    },
    entries: [],
  },
].map((d) => ({ ...d, id: d.text }));

function ProfilePageView() {
  const classes = useStyles();
  const { accountInfo } = useAuth();
  return (
    <>
      <PageWrapper title={`${accountInfo?.account?.name}'s Profile`}>
        <div className={classes.container}>
          <AccordionList
            Header={
              AccordionHeaderAndSubs as React.ElementType<AccordionHeaderProps>
            }
            groups={groups}
          />
        </div>
        <PageFooter />
      </PageWrapper>
    </>
  );
}

export default ProfilePageView;
