import React, { ReactNode, useMemo, useState } from 'react';
import { LineProps } from 'recharts';
import { Line } from '..';

export default function useBringLineForward(children: ReactNode) {
  const [topLine, setTopLine] = useState<number>(-1);
  return useMemo(() => {
    const cloneLine = (element: React.ReactElement<LineProps>, index: number) =>
      React.cloneElement(element, {
        onClick: () => setTopLine(index),
        onMouseOver: () => setTopLine(index),
      });

    const childArray = React.Children.toArray(children);
    const mappedChildren = childArray.map((c, index) => {
      if (topLine === index) return null;
      if (React.isValidElement(c) && c.type === Line) {
        return cloneLine(c, index);
      }
      return c;
    });

    if (topLine !== -1) {
      mappedChildren.push(
        cloneLine(childArray[topLine] as React.ReactElement<LineProps>, topLine)
      );
    }

    return mappedChildren;
  }, [children, topLine]);
}
