import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AccordionContainer,
  AccordionContext,
  AccordionItem,
  AccordionList,
} from 'edgeco/components/lists/accordion';
import IconButton from 'edgeco/components/buttons/IconButton';
import {
  Button,
  Container,
  Divider,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { TextBox } from 'edgeco/components/input';
import { Household, Account } from 'edgeco/types/account';
import { useAccountsById } from 'edgeco/graphql/advisor-summary/queries/accountsById';
import produce from 'immer';
import StatusContainer from './StatusContainer';

const useStyles = makeStyles(({ spacing }) => ({
  nameContainer: {
    padding: spacing(1.5),
    height: 80,
    paddingTop: 5,
  },
  nameButton: {
    height: '100%',
  },
  addAccount: {
    height: 20,
    width: 60,
    fontSize: '1.4rem',
  },
  listItem: {
    maxWidth: 350,
    marginLeft: spacing(1),
  },
  accordionContainer: {
    height: 370,
    overflow: 'auto',
  },
  accountName: {
    display: 'inline-block',
    fontWeight: 'bold',
    width: 80,
    marginRight: 20,
  },
  noAccounts: {
    textAlign: 'center',
    padding: spacing(1),
  },
  deleteHousehold: {
    paddingLeft: spacing(1),
  },
  cacheIcon: {
    width: 24,
    height: 24,
    animationName: '$spin',
    animationDuration: '4000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
}));

type HouseholdListProps = {
  households: Household[];
  onAddHousehold: (name: string) => void;
  onRenameHousehold: (id: string, name: string) => void;
  onDeleteHousehold: (id: string) => void;
  onAddAccounts: (household: Household) => void;
  onDeleteAccount: (household: Household, account: string) => void;
};

function HouseholdList({
  onAddAccounts,
  onRenameHousehold,
  onDeleteAccount,
  onDeleteHousehold,
  onAddHousehold,
  households,
}: HouseholdListProps) {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState<string | null>();
  const [householdName, setHouseholdName] = useState<string>('');
  const [dirty, setDirty] = useState<boolean>(false);

  const accountsToLoad: string[] = useMemo(
    () => households?.flatMap((hh) => [...hh.accounts]) ?? [],
    [households]
  );
  const { accounts } = useAccountsById(accountsToLoad);
  const [accountMap, setAccountMap] = useState(new Map<string, Account>());
  useEffect(
    () => {
      setAccountMap(
        produce(accountMap, (draft) => {
          accounts.forEach((a) => draft.set(a.account, a));
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts]
  );

  const selectHousehold = useCallback((household?: Household) => {
    setDirty(false);
    setSelectedId(household?.id);
    setHouseholdName(household?.name ?? '');
  }, []);

  const config: AccordionGroup[] = households
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 2))
    .map<AccordionGroup>((hh) => ({
      text: hh.name,
      id: hh.id,
      entries: Array.from(hh.accounts).map((a) => ({
        ...(accountMap.get(a) ?? {}),
        id: a,
      })),
    }));

  const expanded = useMemo(() => {
    const rv = new Map<string, boolean>();
    if (selectedId) rv.set(selectedId, true);
    return rv;
  }, [selectedId]);

  const contextValue: AccordionContext = {
    expanded,
    toggle: (id) => {
      const open = !expanded.get(id);
      if (open) {
        const household = households.find((h) => h.id === id)!;
        selectHousehold(household);
      } else {
        selectHousehold();
      }
    },
  };
  const theme = useTheme<EdgeCoTheme>();
  const handleNameChange = (name: string) => {
    setHouseholdName(name);
  };

  return (
    <>
      <Container className={classes.nameContainer}>
        <TextBox
          label={`${selectedId ? 'Edit' : 'Create'} Household`}
          onChange={(event) => handleNameChange(event.target.value)}
          maxLength={30}
          inputProps={{
            minLength: 1,
          }}
          onBlur={() => setDirty(false)}
          error={dirty && householdName?.trim().length < 1}
          debounce
          value={householdName}
          actions={
            <div className={classes.nameButton}>
              <Button
                className={classes.nameButton}
                variant="contained"
                color="secondary"
                onClick={() => {
                  if (householdName?.trim().length < 1) {
                    if (!dirty) setDirty(true);
                    return;
                  }
                  if (selectedId) {
                    contextValue.toggle(selectedId);
                    onRenameHousehold(selectedId, householdName);
                  } else {
                    onAddHousehold(householdName);
                  }
                  selectHousehold();
                }}
              >
                {selectedId ? 'Edit' : 'Add'}
              </Button>
            </div>
          }
        />
      </Container>
      <Divider />
      <div className={classes.accordionContainer}>
        <AccordionContext.Provider value={contextValue}>
          <AccordionList
            groups={config}
            classes={{
              item: classes.listItem,
            }}
            onToggle={(id, open) => {
              if (open) {
                selectHousehold(households.find((h) => h.id === id));
              } else {
                selectHousehold();
              }
            }}
            HeaderActions={({ group }) => {
              const household = households.find((h) => h.id === group.id);
              if (!household) return null;
              return (
                <StatusContainer status={household.status}>
                  <Button
                    className={classes.addAccount}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      selectHousehold(household);
                      onAddAccounts(household!);
                    }}
                  >
                    Add
                  </Button>
                  <IconButton
                    className={classes.deleteHousehold}
                    variant="trash-alt"
                    size="small"
                    onClick={() => {
                      if (selectedId === group.id) {
                        selectHousehold();
                      }
                      onDeleteHousehold(group.id);
                    }}
                  />
                </StatusContainer>
              );
            }}
            Container={({ entries, ...rest }) => {
              return entries.length ? (
                <AccordionContainer {...rest} entries={entries} />
              ) : (
                <div className={classes.noAccounts}>
                  There are no accounts in this household.
                </div>
              );
            }}
            Item={({ item: account, ...rest }: AccordionItemProps<Account>) => {
              return (
                <AccordionItem
                  {...rest}
                  item={
                    <div>
                      <div className={classes.accountName}>
                        {account.account}
                      </div>
                      <span>{account.shortName}</span>
                    </div>
                  }
                  Actions={({ group: { id } }) => (
                    <>
                      <IconButton
                        primaryColor={theme.extensions.color.iconPrimary}
                        variant="trash"
                        size="small"
                        onClick={() => {
                          const hh = households.find((h) => h.id === id);
                          if (hh) {
                            onDeleteAccount(hh, account.account);
                          }
                        }}
                      />
                    </>
                  )}
                />
              );
            }}
          />
        </AccordionContext.Provider>
      </div>
    </>
  );
}

export default HouseholdList;
