import { PropsWithChildren } from 'react';
import { ScatterProps, Scatter } from 'recharts';
import { renderChartWrapper } from './renderChartWrapper';

type Props = ScatterProps;
function ScatterWrapper({
  dataKey,
  children,
  name,
  ...rest
}: PropsWithChildren<Props>) {
  const nameOverride = name ?? (dataKey as string)?.split('.').pop();

  return (
    <Scatter name={nameOverride} dataKey={dataKey} {...rest}>
      {children}
    </Scatter>
  );
}

export default renderChartWrapper(Scatter, ScatterWrapper);
