import React, { useState } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import moment, { Moment } from 'moment';
import { useUncontrolled } from 'uncontrollable';
import Select from '../Select';
import { renderValue, useConfirmSelectState } from '../selectUtil';
import DateRangeListContainer from './DateRangeListContainer';
import ConfirmSelectContainer, {
  ConfirmSelectContainerProps,
} from '../ConfirmSelectContainer';

const styles = (theme: EdgeCoTheme) =>
  createStyles({
    actionsCustom: {
      justifyContent: 'flex-start',
      borderTop: 'none',
      boxShadow: 'none',
      height: 65,
      '&::before': {
        display: 'block',
        position: 'absolute',
        bottom: 55,
        left: 20,
        content: '""',
        borderTop: theme.extensions.borders.light,
        height: 1,
        width: 415,
      },
    },
    selectMenu: {},
  });

export type DateSelectValue = {
  datePeriod?: DatePeriod;
  startDate?: Moment;
  endDate?: Moment;
};

export type DateRangeSelectProps = Overwrite<
  ConfirmSelectProps,
  {
    classes: WithStyles<typeof styles>['classes'] &
      ConfirmSelectProps['classes'];
    startDate?: Moment;
    endDate?: Moment;
    onChange?: (
      event: React.ChangeEvent<{
        value: DateSelectValue;
      }>,
      child?: React.ReactNode
    ) => void;
    info?: React.ReactNode;
    displayMonths?: [Moment, Moment];
    onDisplayMonthsChange?: (displayMonths: [Moment, Moment]) => void;
  }
>;

export type DateSelectChangeEvent = React.ChangeEvent<{
  value: DateSelectValue;
}>;

function setTarget<T>(
  event: T,
  value?: DateSelectValue
): T & { target: { value: DateSelectValue } } {
  Object.defineProperty(event, 'target', {
    writable: true,
    value: { value },
  });
  return event as T & { target: { value: DateSelectValue } };
}

function DateRangeSelect(props: DateRangeSelectProps) {
  const controlledProps = useUncontrolled(props, {
    displayMonths: 'onDisplayMonthsChange',
  });

  const {
    value: valueProp,
    startDate: startDateProp,
    endDate: endDateProp,
    defaultValue: defaultValueProp,
    editValue: editValueProp,
    renderValue: renderValueProp,
    displayMonths,
    onEditChange,
    onChange,
    displayEmpty,
    classes,
    info,
    onDisplayMonthsChange,
    setEditValue,
    ...childSelectProps
  } = controlledProps;

  const [
    startDisplayMonth = (startDateProp ?? moment()).startOf('D'),
    endDisplayMonth = (endDateProp ?? moment()).startOf('D'),
  ] = displayMonths ?? [];

  const { multiple, children } = childSelectProps;
  const { actionsCustom, ...childSelectClasses } = classes;

  const {
    open,
    setOpen,
    editValue,
    value,
    handleClose,
    handleSubmit,
    handleCancel,
    handleChange,
    setEditValue: setEditDates,
  } = useConfirmSelectState(props as ConfirmSelectProps); // onChange doesn't match because we know our event value type

  const [[startDate, endDate], setDates] = useState([
    startDateProp || moment(),
    endDateProp || moment(),
  ]);

  const { startDate: editStartDate, endDate: editEndDate } =
    editValue as DateSelectValue;

  const renderValueSelect = () => {
    if (value === 'Custom')
      return `${startDate.format('MM/DD/YY')} to ${endDate.format('MM/DD/YY')}`;
    const childArray = React.Children.toArray(children) as SelectChildType[];
    return renderValue(childArray, value, renderValueProp, displayEmpty);
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ value: DateSelectValue }>,
    child: React.ReactNode
  ) => {
    const populatedEvent = setTarget(event, event.target.value);
    const { startDate: start, endDate: end } = populatedEvent.target.value;
    setEditDates?.([start!.clone(), end!.clone()]);
    onDisplayMonthsChange?.([start!.clone(), end!.clone()]);
    handleChange(populatedEvent, child);
  };

  const handleDateChange = (start: Moment, end: Moment) => {
    setEditDates?.([start.clone(), end.clone()]);
    onDisplayMonthsChange?.([start!.clone(), end!.clone()]);
    handleChange(
      setTarget({} as any, {
        datePeriod: 'Custom',
        startDate: start,
        endDate: end,
      })
    );
  };

  const dateContainerProps = {
    value: editValue,
    startDate: editStartDate,
    endDate: editEndDate,
    onChange: handleDateChange,
    startDisplayMonth,
    endDisplayMonth,
    info,
  };

  const confirmContainerProps: ConfirmSelectContainerProps = {
    classes: {
      actions: actionsCustom,
    },
    handleSubmit: (event) => {
      setTarget(event, { endDate: editStartDate, startDate: editEndDate });
      handleSubmit(event);
      setDates([editStartDate!.clone(), editEndDate!.clone()]);
    },
    handleCancel: (event) => {
      setTarget(event, { startDate, endDate });
      handleCancel(event);
      setEditDates?.([startDate.clone(), endDate.clone()]);
    },
    cancelButton: { text: 'Cancel' },
    submitButton: { text: 'Apply' },
    ListContainer: DateRangeListContainer,
    ListContainerProps: dateContainerProps,
  };

  return (
    <Select
      {...childSelectProps}
      classes={childSelectClasses}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      value={multiple && !editValue ? [] : editValue}
      onChange={handleSelectChange as any}
      displayEmpty={true}
      renderValue={renderValueSelect}
      ListContainer={ConfirmSelectContainer}
      ListContainerProps={confirmContainerProps}
    />
  );
}

export default withStyles(styles)(DateRangeSelect);
