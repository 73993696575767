// The select from Material-UI doesn't provide a good way to customize the container that
// the list is rendered in, this component is meant to mimic the functionality of the
// built in select using the base building blocks provided by material
import * as React from 'react';
import {
  Input,
  OutlinedInput,
  FilledInput,
  withStyles,
  useFormControl,
  Theme,
  StyleRulesCallback,
  WithStyles,
} from '@material-ui/core';
import { mergeClasses } from '@material-ui/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import {
  styles as materialSelectStyles,
  SelectVariant,
} from '@material-ui/core/Select/Select';
import { Classes } from '@material-ui/styles/mergeClasses/mergeClasses';
import NativeSelectInput from '@material-ui/core/NativeSelect/NativeSelectInput';
import formControlState from '@material-ui/core/FormControl/formControlState';
import SelectInput from './SelectInput';

export const styles: StyleRulesCallback<Theme, SelectProps, SelectClassKey> = (
  theme
) => ({
  nativeInput: {},
  ...materialSelectStyles(theme),
  /* Styles applied to the `Paper` component. */
  paper: {
    // specZ: The maximum height of a simple menu should be one or more rows less than the view
    // height. This ensures a tappable area outside of the simple menu with which to dismiss
    // the menu.
    maxHeight: 'calc(100% - 96px)',
    // Add iOS momentum scrolling.
    WebkitOverflowScrolling: 'touch',
  },
  /* Styles applied to the `List` component via `MenuList`. */
  list: {
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,
  },
});

const Select = React.forwardRef(function Select(
  props: WithStyles<SelectClassKey> & SelectProps,
  ref: any
) {
  const {
    autoWidth = false,
    children,
    classes,
    displayEmpty = false,
    IconComponent = ArrowDropDownIcon,
    id,
    input,
    inputProps,
    label,
    labelId,
    labelWidth = 0,
    ListContainer,
    ListContainerProps,
    PopoverProps,
    MenuListProps,
    multiple = false,
    native = false,
    onClose,
    onOpen,
    open,
    renderValue,
    SelectDisplayProps,
    variant: variantProps = 'standard',
    ...other
  } = props;

  const inputComponent = native ? NativeSelectInput : SelectInput;

  const muiFormControl = useFormControl();
  const fcs = formControlState({
    props,
    muiFormControl,
    states: ['variant'],
  });

  const variant = (fcs.variant || variantProps) as SelectVariant;

  const InputComponent =
    input ||
    {
      standard: <Input />,
      outlined: <OutlinedInput label={label} labelWidth={labelWidth} />,
      filled: <FilledInput />,
    }[variant];

  return React.cloneElement(InputComponent, {
    // Most of the logic is implemented in `SelectInput`.
    // The `Select` component is a simple API wrapper to expose something better to play with.
    inputComponent,
    inputProps: {
      children,
      IconComponent,
      variant,
      type: undefined, // We render a select. We can ignore the type provided by the `Input`.
      multiple,
      ...(native
        ? { id }
        : {
            autoWidth,
            displayEmpty,
            labelId,
            ListContainer,
            ListContainerProps,
            PopoverProps,
            MenuListProps,
            onClose,
            onOpen,
            open,
            renderValue,
            SelectDisplayProps: { id, ...SelectDisplayProps },
          }),
      ...inputProps,
      classes: inputProps
        ? mergeClasses({
            baseClasses: classes as Classes,
            newClasses: inputProps.classes,
            Component: Select,
          })
        : classes,
      ...(input ? input?.props?.inputProps : {}),
    },
    ref,
    ...other,
  });
});

(Select as any).muiName = 'Select';

export default withStyles<SelectClassKey>(styles, { name: 'MuiSelect' })(
  Select
);
