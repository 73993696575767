/* eslint-disable no-param-reassign */
import React, { useReducer, Dispatch, useMemo } from 'react';
import {
  createNamedContext,
  useGenericContext,
  useGenericCreateContext,
} from 'edgeco/hooks/genericContext';
import produce from 'immer';
import { UseQueryParams, useQueryParams } from 'edgeco/hooks/useQueryParams';
import useGeneratedIds from 'edgeco/hooks/useGeneratedIds';
import { FilterQuery } from 'views/production-detail/definitions';
import {
  getFilterValuesFromQuery,
  mapFilterToQueryObject,
} from './filter-utils';

export enum FilterActionType {
  ApplyAllEditFilters = 'ApplyAllEditFilters',
  ApplySpecificFilter = 'ApplySpecificFilter',
  UpdateSpecificEditFilter = 'UpdateSpecificEditFilters',
  SetFiltersFromQueryObject = 'SetFiltersFromQueryObject',
  ClearDateHighlight = 'ClearDateHighlight',
}

type FilterAction = {
  type: FilterActionType;
  payload?: any;
};

type FilterState = {
  filters: TableFilterCollection;
  filterValues: StringIndexable; // current values for the table
  filterQuery: FilterQuery;
  editQuery: FilterQuery;
  highlightStart?: boolean;
};

function updateQuery(
  draft: FilterState,
  modifyQuery: UseQueryParams<{}>['modifyQuery']
) {
  const query = mapFilterToQueryObject(draft.filterValues);
  modifyQuery(...(Object.entries(query) as any));
  draft.filterQuery = query;
}

export function useFilterReducer(initialState: Pick<FilterState, 'filters'>) {
  const queryParams = useQueryParams();
  const { modifyQuery, queryObject } = queryParams;
  const filterReducer: React.Reducer<FilterState, FilterAction> = (
    state,
    { type, payload }
  ) => {
    return produce(state, (draft) => {
      switch (type) {
        case FilterActionType.ApplyAllEditFilters: {
          Object.values(draft.filterValues).forEach((fv) => {
            fv.value = fv.editValue;
          });
          updateQuery(draft, modifyQuery);
          break;
        }
        case FilterActionType.ApplySpecificFilter: {
          const { id, value } = payload;
          draft.filterValues[id].editValue = value;
          draft.editQuery = mapFilterToQueryObject(
            draft.filterValues,
            'editValue'
          );
          break;
        }
        case FilterActionType.UpdateSpecificEditFilter: {
          const { id, value } = payload;
          draft.filterValues[id].specificEditValue = value;
          break;
        }
        case FilterActionType.SetFiltersFromQueryObject: {
          draft.filterValues = getFilterValuesFromQuery(draft.filters, payload);
          draft.highlightStart = true;
          break;
        }
        case FilterActionType.ClearDateHighlight: {
          draft.highlightStart = false;
          break;
        }
        default:
          break;
      }
      return draft;
    });
  };
  const filters = useGeneratedIds(initialState.filters, 'filters');
  const filterValues = useMemo(
    () => getFilterValuesFromQuery(filters, queryObject),
    [filters, queryObject]
  );
  const filterQuery = useMemo(
    () => mapFilterToQueryObject(filterValues),
    [filterValues]
  );
  const [state, dispatch] = useReducer(filterReducer, {
    ...initialState,
    filters,
    filterValues,
    filterQuery,
    editQuery: filterQuery,
  });
  return { state, dispatch };
}

type FilterContext<TFilters = {}> = {
  state: FilterState;
  dispatch: Dispatch<FilterAction>;
};

// FilterContext should never be exported, it is not type safe and shouldn't be used directly
const contextInstance = createNamedContext('FilterContext', {});

export function useCreateFilterContext<T = {}>(
  contextValues: FilterContext<T>
) {
  return useGenericCreateContext<FilterContext<T>>(
    contextValues,
    contextInstance
  );
}

export function useFilterContext<T = {}>() {
  return useGenericContext<FilterContext<T>>(contextInstance);
}
