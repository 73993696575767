import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  title: {
    textAlign: 'left',
  },
  titleRight: {
    position: 'absolute',
    right: 0,
    top: 0,
    textAlign: 'right',
  },
}));

type TitleText =
  | string
  | {
      left?: React.ReactNode;
      right?: React.ReactNode;
    };

type TitleProps = {
  className?: string;
  text: TitleText;
  variant?: Variant | 'inherit';
};

function Title({ variant = 'h3', className, ...rest }: TitleProps) {
  const classes = useStyles();
  let { text } = rest;
  if (typeof text === 'string') {
    text = {
      left: text,
    };
  }
  return (
    <Box className={clsx(className, classes.root)}>
      <Box component="div" className={clsx(classes.title)}>
        <Typography variant={variant}>{text?.left}</Typography>
      </Box>
      {text.right && (
        <Box
          component="div"
          className={clsx(classes.title, classes.titleRight)}
        >
          <Typography variant={variant}>{text.right}</Typography>
        </Box>
      )}
    </Box>
  );
}

export default Title;
