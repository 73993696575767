import { useCallback } from 'react';
import numeral from 'numeral';

export default function useTickFormatter(abbreviationMin?: number) {
  return useCallback(
    function tickFormatter(value: number) {
      let formatString = '$0.0a';
      if (abbreviationMin && value < abbreviationMin) {
        formatString = '$0,0';
      }
      return numeral(value)
        .format(formatString)
        .toUpperCase()
        .replace(/\.0([A-Z])/gi, '$1')
        .replace(/\.0$/gi, '')
        .toString();
    },
    [abbreviationMin]
  );
}
