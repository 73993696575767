import { Divider, Typography, makeStyles, List } from '@material-ui/core';
import clsx from 'clsx';
import { SearchBox } from 'edgeco/components/input';
import { useAccounts } from 'edgeco/graphql/advisor-summary/queries/accounts';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import produce from 'immer';
import { Household } from 'edgeco/types/account';
import AccountListItem from './AccountListItem';
import { householdSizes } from './shared';

const useStyles = makeStyles(() => ({
  header: {
    height: householdSizes.headerHeight,
  },
  searchContainer: {
    position: 'relative',
    height: 80,
  },
  search: {
    margin: '30px 0 16px 8px',
    width: 275,
  },
  searchInput: {
    height: 34,
  },
  listTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    padding: 10,
  },
  columnHeaderWrapper: {
    paddingLeft: householdSizes.checkTotalWidth + 10,
  },
  columnHeaders: {
    display: 'inline-block',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginRight: 30,
  },
}));

type AccountListProps = {
  selectedHousehold: Household;
  households: Household[];
  height: number;
  selected: Set<string>;
  onChange: (accounts: Set<string>) => void;
};

export default function AccountList({
  selectedHousehold,
  households,
  height,
  selected: newAccounts,
  onChange: setNewAccounts,
}: AccountListProps) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  // update if our household changes
  useEffect(() => {
    setNewAccounts(new Set());
  }, [selectedHousehold.accounts, setNewAccounts]);

  const { accounts, fetchNextPage, pageInfo } = useAccounts(searchText);
  const hasNextPage = pageInfo?.hasNextPage;
  const itemCount = hasNextPage ? accounts.length + 1 : accounts.length;

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < accounts.length,
    [accounts.length, hasNextPage]
  );

  const setSearchDebounce = useCallback(
    debounce((v: string) => {
      setSearchText(v);
    }, 200),
    []
  );

  const accountsInOtherHouseholds = useMemo(
    () =>
      new Map(
        households
          .filter((h) => h.id !== selectedHousehold.id)
          .flatMap((h) => [...h.accounts].map((a) => [a, h]))
      ),
    [households, selectedHousehold.id]
  );

  const handleAccountSelected = useCallback(
    (account) =>
      setNewAccounts(
        produce(newAccounts, (draft) => {
          if (newAccounts.has(account)) {
            draft.delete(account);
          } else {
            draft.add(account);
          }
        })
      ),
    [newAccounts, setNewAccounts]
  );

  return (
    <>
      <div className={classes.header}>
        <div className={classes.searchContainer}>
          <SearchBox
            handleChange={(e) => setSearchDebounce(e.target.value)}
            textFieldProps={{
              placeholder: 'Search by Account or Short Name',
              className: classes.search,
            }}
            inputProps={{
              className: classes.searchInput,
            }}
          />
        </div>
        <Divider />
        <Typography className={classes.listTitle}>
          Add to {selectedHousehold?.name}
        </Typography>
        <div className={classes.columnHeaderWrapper}>
          <Typography className={clsx(classes.columnHeaders)}>
            Account
          </Typography>
          <Typography className={clsx(classes.columnHeaders)}>
            Short Name
          </Typography>
        </div>
      </div>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={() => fetchNextPage()}
      >
        {({ onItemsRendered, ref }) => (
          <FixedSizeList
            className="List"
            height={height - householdSizes.headerHeight}
            itemCount={itemCount}
            itemSize={householdSizes.itemHeight}
            onItemsRendered={onItemsRendered}
            ref={ref}
            width={'100%'}
            innerElementType={List}
          >
            {({ index, style }) => {
              const accountObj = accounts[index] ?? {};
              const { account } = accountObj;
              const disabled =
                selectedHousehold.accounts.has(account) ||
                accountsInOtherHouseholds.has(account);
              const isChecked = disabled || newAccounts.has(account);
              const isFromOtherHousehold =
                accountsInOtherHouseholds.has(account);
              const householdName =
                accountsInOtherHouseholds.get(account)?.name;
              return (
                <AccountListItem
                  account={accountObj}
                  index={index}
                  style={style}
                  disabled={disabled}
                  isChecked={isChecked}
                  loading={!isItemLoaded(index)}
                  onClick={handleAccountSelected}
                  tooltipOptions={{
                    content: `Account ${account} is in Household ${householdName}`,
                    disabled: !isFromOtherHousehold,
                  }}
                />
              );
            }}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </>
  );
}
