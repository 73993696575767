import { gql } from '@apollo/client';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import {
  DeleteHouseholdMutation,
  DeleteHouseholdMutationVariables,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';
import { removeHouseholdFromCache, startPollingHouseholds } from './shared';

const DELETE_HOUSEHOLD = gql`
  mutation DeleteHousehold($householdId: Uuid!) {
    DeactivateHousehold(input: { householdId: $householdId }) {
      errors {
        code
        message
      }
    }
  }
`;

export const useDeleteHousehold = () => {
  const [deleteHousehold, { loading }] = useUserProfileMutation<
    DeleteHouseholdMutation,
    DeleteHouseholdMutationVariables
  >(DELETE_HOUSEHOLD);
  const [, dispatch] = useAppStateValue();
  return {
    deleteHousehold: (householdId: string) => {
      deleteHousehold({
        variables: {
          householdId,
        },
        update(cache, res) {
          const errors = res.data?.DeactivateHousehold?.errors ?? [];
          if (errors.length > 0) return;

          removeHouseholdFromCache(cache, householdId);
          startPollingHouseholds(dispatch);
        },
      });
    },
    loading,
  };
};
