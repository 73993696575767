import { useQuery } from '@apollo/client';

type QueryFn = typeof useQuery;
export const useUserProfileQuery: QueryFn = <TData, TVariables>(
  mutation: any,
  options: any
) =>
  useQuery<TData, TVariables>(mutation, {
    ...options,
    context: {
      ...options?.context,
      clientName: 'user-profile',
    },
  });
