import { gql } from '@apollo/client';
import { Report } from 'edgeco/types/report';
import { useMemo } from 'react';
import { GetUserReportsQuery } from '../@types';
import { useUserProfileQuery } from './useUserProfileQuery';

export const GET_USER_REPORTS = gql`
  query GetUserReports {
    viewer {
      reports {
        id {
          identifier
        }
        reportName
        revenueCategories
        visibleColumns
      }
    }
  }
`;

export const useUserReports = () => {
  const { data, loading, error } = useUserProfileQuery<GetUserReportsQuery>(
    GET_USER_REPORTS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only',
    }
  );
  const reports: Report[] = useMemo(
    () =>
      data?.viewer?.reports?.map<Report>((r) => ({
        id: r?.id?.identifier!,
        reportName: r.reportName!,
        revenueCategories: r.revenueCategories,
        visibleColumns: r.visibleColumns as string[],
      })) ?? [],
    [data]
  );
  return { reports, loading, error };
};
