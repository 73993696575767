import { gql, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { Account } from 'edgeco/types/account';
import { GetAccountsQuery, GetAccountsQueryVariables } from '../@types';

export const GET_ACCOUNTS = gql`
  query GetAccounts(
    $pageSize: Int!
    $cursor: String
    $where: AccountModelFilterInput
  ) {
    viewer {
      accounts(
        first: $pageSize
        after: $cursor
        where: $where
        order: { account: ASC }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          account
          shortName
        }
      }
    }
  }
`;

export const useAccounts = (searchString: string) => {
  const variables: GetAccountsQueryVariables = {
    pageSize: 80,
  };

  if (searchString) {
    variables.where = {
      or: [
        { account: { contains: searchString } },
        { shortName: { contains: searchString } },
      ],
    };
  }

  const { data, loading, error, fetchMore } = useQuery<GetAccountsQuery>(
    GET_ACCOUNTS,
    {
      variables,
      fetchPolicy: 'cache-and-network',
    }
  );
  const queryResults = data?.viewer?.accounts;
  const accounts = (queryResults?.nodes ?? []) as Account[];
  const pageInfo = queryResults?.pageInfo;

  const fetchNextPage = useCallback(() => {
    const newVars = {
      ...variables,
      cursor: pageInfo?.endCursor,
    };
    return fetchMore({
      query: GET_ACCOUNTS,
      variables: newVars,
      updateQuery: (prev: GetAccountsQuery, { fetchMoreResult: more }) => {
        if (!more?.viewer.accounts?.nodes) return prev;
        const existing = prev.viewer.accounts?.nodes ?? [];
        const rv: GetAccountsQuery = {
          viewer: {
            accounts: {
              pageInfo: more.viewer.accounts?.pageInfo,
              totalCount: more.viewer.accounts?.totalCount,
              nodes: [...existing, ...more.viewer.accounts.nodes],
            },
          },
        };
        return rv;
      },
    });
  }, [fetchMore, pageInfo, variables]);

  return { accounts, pageInfo, loading, fetchNextPage, error };
};
