import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { Household } from 'edgeco/types/account';
import { GetHouseholdQuery } from '../@types';
import { useUserProfileQuery } from './useUserProfileQuery';

export const GET_HOUSEHOLDS = gql`
  query GetHousehold {
    viewer {
      households {
        householdId
        name
        accounts
        status @client
      }
    }
  }
`;

export const useHouseholds = () => {
  const { data, loading, error } = useUserProfileQuery<GetHouseholdQuery>(
    GET_HOUSEHOLDS,
    {
      fetchPolicy: 'network-only',
      // TODO: Update to use subscriptions instead of purely optimistic caching
      // Only do the first fetch over the network, otherwise new households will disappear from the list on mutation
      nextFetchPolicy: 'cache-only',
    }
  );
  const households: Household[] = useMemo(
    () =>
      data?.viewer?.households?.map((h) => ({
        id: h.householdId!,
        name: h.name!,
        accounts: new Set((h.accounts ?? []) as string[]),
        status: h.status,
      })) ?? [],
    [data]
  );
  return { households, loading, error };
};
