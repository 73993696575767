import { Grid, makeStyles, fade, Theme, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import useTransferList from './useTransferList';

import { DIALOG_HEIGHT, SEARCH_BAR_HEIGHT, SELECTION_GROUP } from './constants';
import SearchBox from '../input/SearchBox';
import RightList from './RightList';
import LeftList from './LeftList';

const useStyles = makeStyles(
  ({
    spacing,
    extensions: {
      borders,
      color: { alabaster, white, emperor },
    },
  }: Theme) => ({
    rootStyle: {
      height: `${DIALOG_HEIGHT}px`,
      width: '800px',
      flexDirection: 'column',
    },
    topRowStyle: {
      maxHeight: `${SEARCH_BAR_HEIGHT}px`,
    },
    bottomRowStyle: {
      maxHeight: `${DIALOG_HEIGHT - SEARCH_BAR_HEIGHT}px`,
      flexDirection: 'row',
    },
    searchFieldStyle: {
      paddingLeft: spacing(1),
      width: '60%',
    },
    scrollContainer: {
      overflowY: 'auto',
      maxHeight: 'inherit',
      borderRight: borders.light,
    },
    baseListStyle: {
      padding: 0,
    },
    leftStyle: {
      backgroundColor: white,
    },
    leftListHeaderStyle: {
      borderBottom: borders.light,
    },
    rightStyle: {
      backgroundColor: alabaster,
    },
    listText: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '2.1rem',
    },
    listItem: {
      fontSize: '1.8rem',
      fontWeight: 400,
      lineHeight: '2.1rem',
    },
    headerStyle: {
      backgroundColor: emperor,
      color: white,
      '&:hover': {
        backgroundColor: fade(emperor, 0.75),
      },
      '&:nth-child(2)': {
        marginTop: 0,
      },
      marginBottom: spacing(1 / 2),
      marginTop: spacing(1 / 2),
      padding: spacing(0, 1 / 2),
    },
    highlightedText: {
      borderBottom: `2px solid ${emperor}`,
    },
    selectAllItem: {},
    rootList: {},
    pinnedListItem: {},
  })
);

const defaultTransferListOptions: TransferListOptions = {
  variant: 'left-right',
  showSelectGroup: false,
  searchTextMinimum: 3,
  groupOrder: null,
};

type Props = ReturnType<typeof useTransferList> & {
  addText?: string;
  showCheckedIndicator?: boolean;
  options?: Partial<typeof defaultTransferListOptions>;
  classes?: Partial<ReturnType<typeof useStyles>>;
};

function TransferList({
  addText,
  showCheckedIndicator,
  options,
  classes: classesProp,
  handleSearchChange,
  onDragEnd,
  handleChecked,
  onManualTransfer,
  listDefinition,
  searchText,
  handleCollapsible,
  checkedItems,
  expanded,
  listMap,
}: Props) {
  const classes = useStyles({ classes: classesProp });

  const {
    extensions: { borders },
  } = useTheme();

  // Override default options
  const renderOptions = {
    ...defaultTransferListOptions,
    ...options,
  };

  const showRightList = renderOptions.variant !== 'single';

  const RIGHT_GRID_SIZE = showRightList ? 5 : false;
  const LEFT_GRID_SIZE = showRightList ? 7 : 12;

  const rightItems = useMemo(
    () => listMap.get(SELECTION_GROUP) ?? [],
    [listMap]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container className={classes.rootStyle}>
        <Grid
          container
          item
          xs={LEFT_GRID_SIZE}
          className={classes.topRowStyle}
        >
          <Grid
            style={{
              borderBottom: borders.light,
            }}
            className={classes.leftStyle}
            item
            xs={12}
          >
            <SearchBox
              handleChange={handleSearchChange}
              textFieldProps={{
                className: classes.searchFieldStyle,
              }}
            />
          </Grid>
        </Grid>
        <Grid className={classes.bottomRowStyle} container item xs={12}>
          <LeftList
            addText={addText}
            renderOptions={renderOptions}
            handleChecked={handleChecked}
            onManualTransfer={onManualTransfer}
            allValues={listDefinition}
            listMap={listMap}
            classes={{
              selectAllItem: classes.selectAllItem,
              searchFieldStyle: classes.searchFieldStyle,
              scrollContainer: classes.scrollContainer,
              rootList: classes.rootList,
              listText: classes.listText,
              leftStyle: classes.leftStyle,
              highlightedText: classes.highlightedText,
              headerStyle: classes.headerStyle,
              baseListStyle: classes.baseListStyle,
              pinnedListItem: classes.pinnedListItem,
            }}
            showCheckedIndicator={showCheckedIndicator}
            checked={checkedItems}
            searchText={searchText}
            handleCollapsible={handleCollapsible}
            expanded={expanded}
            gridSize={LEFT_GRID_SIZE}
          />
          <RightList
            items={rightItems}
            renderOptions={renderOptions}
            onManualTransfer={onManualTransfer}
            classes={{
              scrollContainer: classes.scrollContainer,
              baseListStyle: classes.baseListStyle,
              rightStyle: classes.rightStyle,
              listText: classes.listText,
            }}
            gridSize={RIGHT_GRID_SIZE}
          />
        </Grid>
      </Grid>
    </DragDropContext>
  );
}

export default TransferList;
