import React, { useState, useCallback, SyntheticEvent } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FormControl,
  makeStyles,
  InputLabel,
  IconButton,
  ListItemSecondaryAction,
  ListItem,
} from '@material-ui/core';
import useModal from 'edgeco/components/modal/useModal';
import { useUserReports } from 'edgeco/graphql/user-profile/queries';
import { useRemoveUserReport } from 'edgeco/graphql/user-profile/mutations/user/removeUserReport';
import { Report } from 'edgeco/types/report';
import { UnderlineSelect } from 'edgeco/components/select';
import StickLeft from 'edgeco/components/common/StickLeft';
import { revenueCategoryEnumToNameMap } from '../../../../views/production-detail/definitions';
import { useTableContext } from '../TableContext';
import { FilterActionType, useFilterContext } from './FilterContext';
import { getSelectableColumns } from '../Filters';
import { buildListMap } from './filter-utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  inputLabel: {
    top: -16,
    left: 20,
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 1s',
  },
  listItem: {
    '&:hover $listItemSecondaryAction': {
      visibility: 'inherit',
      opacity: 1,
    },
  },
  sticky: {
    height: 0,
  },
  absoluteWrapper: {
    position: 'absolute',
    top: -80,
    right: 35,
    width: 200,
  },
  deletionText: {
    fontSize: 18,
    fontWeight: 'bold',
    padding: 10,
  },
}));

function convertFiltersOfType(revenueCategories: string[], map: any) {
  return Object.keys(map)
    .filter((key) => revenueCategories.includes(key))
    .map((key) => map[key]);
}

function SavedFilters<TData>() {
  const { reports } = useUserReports();
  const classes = useStyles();
  const {
    tableInstance,
    requiredColumns = [],
    setColumnListMap,
    setColumnListDefinition,
  } = useTableContext<TData>();
  const { dispatch } = useFilterContext();
  const [reportToDelete, setReportToDelete] = useState<Report>();
  const { removeReport } = useRemoveUserReport();

  const { Modal: ConfirmDeleteReportModal } = useModal({
    open: reportToDelete !== undefined,
    isDraggable: false,
    hideHeader: true,
    content: (
      <p className={classes.deletionText}>
        Are you Sure you want to delete the {reportToDelete?.reportName} report?
      </p>
    ),
    submitButton: {
      text: 'Confirm Deletion',
    },
    cancelButton: {
      text: 'Cancel',
    },
    onSubmit: (event: SyntheticEvent) => {
      event?.preventDefault();
      removeReport(reportToDelete?.id!);
      setReportToDelete(undefined);
    },
    onCancel: () => setReportToDelete(undefined),
  });

  const handleItemSelected = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const { visibleColumns, revenueCategories } = reports.find(
        (r) => r.id === event.target.value
      )!;
      const selectedColumns = [
        ...visibleColumns,
        ...requiredColumns,
        'expander',
      ];
      const { columns, columnDefinitions, setHiddenColumns } = tableInstance;
      const columnsToHide = columns
        .map((c) => c.id)
        .filter((c) => !selectedColumns.includes(c));
      setHiddenColumns(columnsToHide);
      const queryParams = revenueCategoryEnumToNameMap.reduce(
        (acc, [category, enumMap]) => {
          acc[category] = convertFiltersOfType(revenueCategories, enumMap);
          return acc;
        },
        {} as any
      );
      dispatch({
        type: FilterActionType.SetFiltersFromQueryObject,
        payload: queryParams,
      });

      const selectableColumns = getSelectableColumns(columnDefinitions);
      setColumnListDefinition?.(buildListMap(selectableColumns));
      setColumnListMap?.(buildListMap(selectableColumns, selectedColumns));
    },
    [
      dispatch,
      reports,
      requiredColumns,
      setColumnListDefinition,
      setColumnListMap,
      tableInstance,
    ]
  );

  const handleRemoveItem = (report: any) => {
    setReportToDelete(report);
  };

  if (!reports?.length) return null;

  return (
    <StickLeft className={classes.sticky}>
      <div className={classes.absoluteWrapper}>
        <FormControl className={classes.formControl}>
          <InputLabel className={classes.inputLabel}>
            Choose Your Report
          </InputLabel>
          <UnderlineSelect
            onChange={handleItemSelected as SelectInputProps['onChange']}
          >
            {reports?.map((report) => (
              <ListItem
                classes={{ container: classes.listItem }}
                value={report.id}
                key={report.id}
              >
                <ListItemText primary={report.reportName} />
                <ListItemSecondaryAction
                  className={classes.listItemSecondaryAction}
                >
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveItem(report)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </UnderlineSelect>
        </FormControl>
        {ConfirmDeleteReportModal}
      </div>
    </StickLeft>
  );
}

export default SavedFilters;
