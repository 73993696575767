import React, { useCallback } from 'react';
import {
  makeStyles,
  Select,
  MenuItem,
  Container,
  Typography,
} from '@material-ui/core';
import numeral from 'numeral';
import { ApolloError } from '@apollo/client';
import { useTrackComponent } from 'edgeco/hooks/AppInsights';
import { currencyFormatter, formatDate } from '../../../../libs';
import {
  DonutChart,
  DonutChartTotal,
} from '../../../../components/data-visualization';
import Title from '../../../../components/common/Title';
import { Loading } from '../../../../components/loading';

const useStyles = makeStyles({
  chartRoot: {
    position: 'relative',
    width: 374,
    padding: 0,
  },
  select: {
    position: 'absolute',
    top: 0,
    right: 5,
    width: 135,
  },
  tooltipLabel: {
    fontWeight: 'bold',
  },
});

type Props = {
  chartData: BaseChartDataShape<TopDataShape>[];
  loading: boolean;
  totalPercent: number;
  datePeriod: TopPayoutAccountDatePeriod;
  setDatePeriod: (period: TopPayoutAccountDatePeriod) => void;
  error?: ApolloError;
  title: string;
  trackingName: string;
};

export default function PeriodTopDonutChart({
  chartData,
  loading,
  totalPercent,
  error,
  datePeriod,
  setDatePeriod,
  title,
  trackingName,
}: Props) {
  const classes = useStyles();

  const onChange = useCallback(
    (event) => {
      setDatePeriod(event.target.value);
    },
    [setDatePeriod]
  );

  const track = useTrackComponent(`Significant Revenue - ${trackingName}`);
  const sectionWrapper = (component: React.ReactElement) => (
    <>
      <Title text={title} />
      <Container
        onMouseOver={track}
        onClick={track}
        className={classes.chartRoot}
      >
        {component}
      </Container>
    </>
  );

  if (error) return sectionWrapper(<div />);

  if (loading || chartData.length <= 0) return sectionWrapper(<Loading />);

  return sectionWrapper(
    <>
      <DonutChart
        data={chartData}
        legendWidth={200}
        dataKey={(d) => d.data.value}
        inner={
          <DonutChartTotal
            total={totalPercent}
            format="0%"
            description="of Total Production"
          />
        }
        getTooltipContent={({ data: dataShape }) => {
          if (!dataShape || error) return null;
          return (
            <>
              <Typography className={classes.tooltipLabel}>
                {numeral(dataShape.data.percentOfTotal).format('"0%"')} of{' '}
                {title}
              </Typography>
              <Typography>
                {currencyFormatter(dataShape.data.value, 9)} Total
              </Typography>
              <Typography>{formatDate(datePeriod)}</Typography>
            </>
          );
        }}
      />
      <Select value={datePeriod} onChange={onChange} className={classes.select}>
        <MenuItem value={'Month'}>Current Month</MenuItem>
        <MenuItem value={'Quarter'}>Current Quarter</MenuItem>
        <MenuItem value={'Year'}>Current Year</MenuItem>
      </Select>
    </>
  );
}
