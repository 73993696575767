import { Line } from 'recharts';
import { renderChartWrapper } from './renderChartWrapper';
import withTooltip from '../hooks/withTooltip';
import DotShape from '../shapes/Dot';
import colorScheme from '../colorScheme';

// isAnimationActive breaks dots https://github.com/recharts/recharts/issues/1426

function LineWrapper<TData>({
  strokeWidth,
  isAnimationActive,
  dot,
  activeDot,
  stroke,
  getTooltipContent,
  tooltipOptions: toolTipOptions,
  showBackground = true,
  points,
  dataKey,
  activeFill,
  ...rest
}: LineProps<TData>) {
  const RenderedDotShape = getTooltipContent ? withTooltip(DotShape) : DotShape;

  return (
    <>
      {showBackground && (
        <Line
          dataKey={dataKey}
          points={points}
          aria-label="chart-line-background"
          stroke={colorScheme.colors.backgroundWhite}
          isAnimationActive={false}
          strokeWidth={strokeWidth ? strokeWidth + 1 : 3}
          dot={false}
          {...rest}
        />
      )}
      <Line
        dataKey={dataKey}
        points={points}
        aria-label="chart-line"
        stroke={stroke}
        isAnimationActive={false}
        strokeWidth={strokeWidth ?? 2}
        dot={({ payload, value, ...dotRest }: any) => (
          <RenderedDotShape
            tooltipOptions={{
              content:
                getTooltipContent &&
                getTooltipContent({
                  data: payload,
                  value,
                  dataKey: dataKey as DataAccessor,
                }),
              ...toolTipOptions,
            }}
            payload={payload}
            value={value}
            activeFill={activeFill}
            {...dotRest}
          />
        )}
        {...rest}
      />
    </>
  );
}
export default renderChartWrapper(Line, LineWrapper, {
  activeDot: false,
  strokeWidth: undefined,
});
