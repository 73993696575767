export const DatePeriodValues = [
  'Day',
  'Yesterday',
  'Month',
  'MTD',
  'Quarter',
  'Year',
  'YTD',
  'T12',
  'Custom',
] as const;

export const FiscalQuarterValues = [
  'Quarter 1',
  'Quarter 2',
  'Quarter 3',
  'Quarter 4',
] as const;

export const ProductionCategoryValues = [
  'Commissions',
  'Direct',
  'Fees',
] as const;

export const NumberFormatValues = ['CHANGE', 'CURRENCY'] as const;

export const TopPayoutAccountDatePeriodValues = [
  'Month',
  'Quarter',
  'Year',
] as const;

declare global {
  export type DatePeriod = typeof DatePeriodValues[number];
  export type NumberFormat = typeof NumberFormatValues[number];
  export type FiscalQuarter = typeof FiscalQuarterValues[number];
  export type ProductionCategory = typeof ProductionCategoryValues[number];

  export type ProductionBreakdownDataShape = Record<
    ProductionCategory,
    number | null
  >;

  type GenericChartMeta = Record<string, any>;
  export type ProductionBreakdownChartDataShape = ChartDataShape<
    ProductionBreakdownDataShape,
    GenericChartMeta
  >;

  export type ProductionHistoryDataShape = Record<number, number> & {
    quarter: FiscalQuarter;
  };

  export type ProductionBreakdown = {
    year: number;
    quarter: {
      name: FiscalQuarter;
      id: number;
      isComplete: boolean;
    };
    payments: ProductionBreakdownDataShape;
    meta?: any;
  };

  export type ProductionHistory = {
    year: number;
    quarter1?: number;
    quarter2?: number;
    quarter3?: number;
    quarter4?: number;
  };

  export type ToutData = {
    data: {
      format: NumberFormat;
      value: number;
    };
    description: string;
    title: string;
  };

  // TODO: cleanup when API is implemented
  // Probably could be combined with ProductionBreakdown as a generic
  type PayEarnedDatePeriod = Extract<
    DatePeriod,
    'Day' | 'Month' | 'Year' | 'T12'
  >;

  export type PayEarnedData = Record<
    PayEarnedDatePeriod,
    Omit<ChartNestedDataShape<number, { total: number }>, 'key'>
  >;
  export type PayEarnedMetadata = { total: number; timeframe: DatePeriod };

  export type TopPayoutAccountDatePeriod = Extract<
    DatePeriod,
    typeof TopPayoutAccountDatePeriodValues[number]
  >;

  export type TopDataShape = {
    name: string;
    value: number;
    total: number;
    percentOfTotal?: number;
  };

  export type YearlyTopDataShape = TopDataShape & {
    year: number;
  };

  export type TopYearlyComparison = {
    current?: YearlyTopDataShape;
    comparison?: YearlyTopDataShape;
  };

  export type ColumnType = 'date';

  export type ColumnDefinition = (
    | {
        group: string;
        hidden?: boolean;
      }
    | {
        group?: never;
        hidden: 'always';
      }
  ) & {
    header: string;
    footer?: string;
    format?: string;
    type?: ColumnType;
    sticky?: import('react-table').StickyValues;
    width?: number;
    minWidth?: number;
    tooltip?: boolean;
    tooltipWidth?: number;
    isSortable?: boolean;
  };

  export type ColumnDefinitionList<D> = Record<keyof D, ColumnDefinition>;

  export type ProductionDetail = {
    // Misc
    transId: number;
    transDate: Date;

    // payment info
    revenueCategory: string;
    gross?: number;
    repPayoutPercent?: number;
    net?: number;
    paymentNotes?: number;

    // payment info
    payDate?: Date;
    originalToBePaidDate?: Date;
    payTo?: string;
    paymentMethod?: string;

    // product info
    productDescription?: string;
    productCode?: string;
    productType?: string;

    // account info
    accountNumber?: string;
    shortName?: string;
    accountType?: string;
    client?: string;
    householdName?: string;

    // misc
    accountBalance?: number;
    security?: string;

    // transaction details
    rr1?: number;
    rr2?: number;
    buySell?: string;
    price?: number;
    principal?: number;
    period?: string;
    shares?: number;
    maClearing?: number;
  };

  export type ProductionDetailKey = keyof ProductionDetail;

  export type FormLayout<T> = {
    groupings: FormGroup<T>[];
  };

  export type FormGroup<T> = {
    values: FormValue<T>[];
  };

  export type FormValue<T> = {
    title: string;
    format?: string;
    type?: ColumnType;
    getValue: DataAccessor<T>;
  };

  export type User = {
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email: string;
  };

  export type Rep = User & {
    repId: number;
    repCode?: number;
    visible?: boolean;
    isGroup?: boolean;
    groupId?: number;
    isViewer: boolean;
    repGroup?: RepGroup;
  };

  export type RepGroup = {
    groupId?: number;
    groupName?: string;
    groupCode?: string;
  };
}
