import { gql } from '@apollo/client';
import useAuth from 'edgeco/hooks/useAuth';

import {
  AddUserReportMutation,
  AddUserReportMutationVariables,
  RevenueCategoryPaymentType,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';

const ADD_USER_REPORT = gql`
  mutation AddUserReport($userID: Uuid!, $report: ProductionDetailInput!) {
    AddReportToUser(input: { userId: $userID, report: $report })
  }
`;

export const useAddProductionDetailReport = () => {
  const { accountInfo } = useAuth();
  const userId = accountInfo?.account?.accountIdentifier!;
  const [addReportsToUser, { loading }] = useUserProfileMutation<
    AddUserReportMutation,
    AddUserReportMutationVariables
  >(ADD_USER_REPORT);
  return {
    addReportsToUser: (
      reportName: string,
      revenueCategories: RevenueCategoryPaymentType[],
      visibleColumns: string[]
    ) => {
      addReportsToUser({
        variables: {
          userID: userId,
          report: { name: reportName, revenueCategories, visibleColumns },
        },
        update: (cache, response) => {
          const reportId = response.data?.AddReportToUser;
          cache.modify({
            fields: {
              viewer(existingViewer: any) {
                const newReportRef = cache.writeFragment({
                  data: {
                    __typename: 'ProductionDetailUserReport',
                    id: {
                      identifier: reportId,
                    },
                    reportName,
                    revenueCategories,
                    visibleColumns,
                  },
                  fragment: gql`
                    fragment NewReport on ProductionDetailUserReport {
                      id {
                        identifier
                      }
                      reportName
                      revenueCategories
                      visibleColumns
                    }
                  `,
                });
                const reports = [...existingViewer.reports, newReportRef];
                return { ...existingViewer, reports };
              },
            },
          });
        },
      });
    },
    loading,
  };
};
