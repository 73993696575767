import { makeStyles } from '@material-ui/core';

const useRechartStyles = makeStyles({
  hideTopGridLine: {
    '& .recharts-surface .recharts-cartesian-grid .recharts-cartesian-grid-horizontal line:last-child':
      {
        strokeOpacity: 0,
      },
  },
});

export { useRechartStyles };
