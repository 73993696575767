import { PieChart, Pie, Legend, Sector } from 'recharts';
import { makeStyles, Theme } from '@material-ui/core';

import { themeExtensions as thmExt } from '../../../assets/theme';
import { DonutChartProps } from '../types';
import { dataToCells, getValueByDataKey } from '../chartUtils';
import colorScheme from '../colorScheme';
import { useActiveState } from '../activeStateHook';
import ControlledTooltip from '../ControlledTooltip';
import TooltipContent from '../common/TooltipContent';

type StyleProps<TData = any> = DonutChartProps<TData> &
  WithTooltip & {
    pieWidth: number;
    pieHeight: number;
    arcWidth: number;
    arcStroke: number;
  };

const useStyles = makeStyles<Theme, StyleProps>({
  root: ({ width, height }) => ({
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    width,
    height,
  }),
  innerRoot: ({ pieWidth, pieHeight, arcWidth, arcStroke }) => {
    const width = pieWidth - 2 * (arcWidth + arcStroke);
    const height = pieHeight - 2 * (arcWidth + arcStroke);
    return {
      position: 'absolute',
      top: arcWidth + arcStroke,
      left: arcWidth + arcStroke,
      width,
      height,
      fontWeight: 'bold',
      fontFamily: 'Arial',
      pointerEvents: 'none',
    };
  },
  chartContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& .recharts-legend-wrapper': {
      '& .recharts-legend-item': {
        display: 'flex !important',
      },
      '& .recharts-surface': {
        marginTop: 3,
      },
      '& .recharts-symbols': {
        stroke: thmExt.color.borderLight,
        strokeWidth: 6,
      },
      '& .recharts-legend-item-text': {
        display: 'block',
        wordWrap: 'break-word',
        maxWidth: 130,
      },
    },
    // '& .recharts-legend-wrapper .recharts-symbols': {
    //   stroke: thmExt.color.borderLight,
    //   strokeWidth: 6,
    // },
    '& .recharts-legend-wrapper .recharts-legend-item': {
      stroke: thmExt.color.borderLight,
      strokeWidth: 6,
    },
    '& .recharts-legend-wrapper .recharts-legend-item-text': {
      flex: 1,
    },
  },
});

function DonutChart<TData extends object>(props: DonutChartProps<TData>) {
  const {
    data,
    dataKey = (d) => (d as any)?.data,
    inner,
    legend = 'vertical',
    width: pieWidth = 224,
    height: pieHeight = 224,
    onClick,
    tooltipOptions,
    getTooltipContent,
    legendWidth,
  } = props;

  const cx = pieWidth / 2 - 5;
  const cy = pieHeight / 2 - 5;

  let width = pieWidth;
  let height = pieHeight;
  const arcStroke = 2;
  const arcWidth = 32;

  const outerRadius = width / 2 - arcStroke;
  const innerRadius = outerRadius - arcWidth;
  switch (legend) {
    case 'vertical':
      width += legendWidth ?? 150;
      // padding so the legend isn't right against the chart
      width += 16;
      break;
    case 'horizontal':
      height += 60;
      width += legendWidth ?? 250;
      break;
    default:
  }

  const activeState = useActiveState(data, tooltipOptions?.interactive);

  const classes = useStyles({
    ...props,
    width,
    height,
    pieWidth,
    pieHeight,
    arcStroke,
    arcWidth,
  });
  return (
    <div className={classes.root}>
      <div className={classes.chartContainer}>
        <PieChart width={width} height={height}>
          <Pie
            cx={cx}
            cy={cy}
            width={pieWidth}
            height={pieHeight}
            data={data}
            nameKey="key"
            dataKey={dataKey}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            isAnimationActive={false}
            stroke={thmExt.color.borderLight}
            strokeWidth={2}
            onClick={onClick}
            activeIndex={activeState?.activeEntity?.index}
            activeShape={(secProps: any) => (
              <Sector
                {...secProps}
                fill={colorScheme.forIndex(
                  activeState!.activeEntity!.index,
                  data.length,
                  true
                )}
                // stroke="#fff"
              />
            )}
          >
            {dataToCells(data, dataKey, activeState)}
          </Pie>
          {legend === 'vertical' && (
            <Legend
              align="right"
              verticalAlign="middle"
              iconSize={15}
              iconType="square"
              layout={legend}
              width={legendWidth}
            />
          )}
          {legend === 'horizontal' && (
            <Legend align="center" verticalAlign="bottom" layout={legend} />
          )}
          {getTooltipContent && (
            <ControlledTooltip
              activeState={activeState}
              content={(p: any) =>
                p === undefined ? null : (
                  <TooltipContent>
                    {getTooltipContent({
                      dataKey,
                      data: p.data,
                      value: getValueByDataKey(p.data, dataKey),
                    })}
                  </TooltipContent>
                )
              }
            />
          )}
        </PieChart>
      </div>
      <div className={classes.innerRoot}>{inner}</div>
    </div>
  );
}

export default DonutChart;
