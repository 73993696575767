import { Theme, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { CSSProperties } from '@material-ui/styles';
import ListItem from './ListItem';

const useStyles = makeStyles<Theme, { dragStyle?: CSSProperties }>({
  root: {
    width: '100%',
  },
  dragging: ({ dragStyle }) => ({
    ...(dragStyle as any),
  }),
});

const DraggableListItem = ({
  provided,
  isDragging,
  transferItem,
  className,
  selected,
  style,
  ...rest
}: DraggableListItem) => {
  const { dragging, root } = useStyles({
    dragStyle: provided.draggableProps.style as CSSProperties,
  });
  return (
    <ListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      selected={isDragging}
      className={clsx(className, root, {
        [dragging]: isDragging,
      })}
      {...rest}
    />
  );
};

export default DraggableListItem;
