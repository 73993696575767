import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  useControlled,
  useForkRef,
  ownerDocument,
  capitalize,
  MenuList,
  Popover,
  WithStyles,
} from '@material-ui/core';
import { SelectVariant } from '@material-ui/core/Select/Select';
import { areEqualValues, renderValue } from './selectUtil';

function isEmpty(display: any) {
  return display == null || (typeof display === 'string' && !display.trim());
}

const SelectInput = React.forwardRef(function SelectInput(
  props: WithStyles<SelectClassKey | SelectVariant> & SelectInputProps,
  ref: React.Ref<any>
) {
  const {
    'aria-label': ariaLabel,
    autoFocus,
    autoWidth,
    children,
    classes,
    className,
    defaultValue,
    disabled,
    displayEmpty,
    IconComponent: IconComponentProps,
    inputRef: inputRefProp,
    labelId,
    ListContainer = React.Fragment,
    ListContainerProps,
    PopoverProps,
    MenuListProps,
    multiple,
    name,
    onBlur,
    onChange,
    onClose,
    onFocus,
    onOpen,
    open: openProp,
    readOnly,
    renderValue: renderValueProp,
    SelectDisplayProps = {},
    tabIndex: tabIndexProp,
    // catching `type` from Input which makes no sense for SelectInput
    type,
    value: valueProp,
    variant = 'standard',
    ...other
  } = props;
  const IconComponent = IconComponentProps as React.ElementType;
  const [value, setValue] = useControlled<SelectValueType>({
    controlled: valueProp as SelectValueType,
    default: defaultValue as SelectValueType,
    name: 'Select',
  });
  const valueArray = value as string[];

  const inputRef = React.useRef(null);
  const [displayNode, setDisplayNode] = React.useState<HTMLElement>();
  const { current: isOpenControlled } = React.useRef(openProp != null);
  const [menuMinWidthState, setMenuMinWidthState] = React.useState<number>();
  const [openState, setOpenState] = React.useState(false);
  const handleRef = useForkRef(ref, inputRefProp!);

  React.useImperativeHandle(
    handleRef,
    () => ({
      focus: () => {
        displayNode?.focus();
      },
      node: inputRef.current,
      value,
    }),
    [displayNode, inputRef, value]
  );

  React.useEffect(() => {
    if (autoFocus && displayNode) {
      displayNode?.focus();
    }
  }, [autoFocus, displayNode]);

  React.useEffect(() => {
    if (displayNode) {
      const label = ownerDocument(displayNode).getElementById(labelId!);
      if (label) {
        const handler = () => {
          if (getSelection()?.isCollapsed) {
            displayNode.focus();
          }
        };
        label.addEventListener('click', handler);
        return () => {
          label.removeEventListener('click', handler);
        };
      }
    }

    return undefined;
  }, [labelId, displayNode]);

  const update = useCallback(
    (open: boolean, event: React.ChangeEvent<{}>) => {
      if (open) {
        if (onOpen) {
          onOpen(event);
        }
      } else if (onClose) {
        onClose(event);
      }

      if (!isOpenControlled) {
        setMenuMinWidthState(autoWidth ? undefined : displayNode?.clientWidth);
        setOpenState(open);
      }
    },
    [autoWidth, displayNode, isOpenControlled, onClose, onOpen]
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    // Ignore everything but left-click
    if (event.button !== 0) {
      return;
    }
    // Hijack the default focus behavior.
    event.preventDefault();
    displayNode?.focus();

    update(true, event);
  };

  const handleClose = useCallback(
    (event: React.ChangeEvent<{}>) => {
      update(false, event);
    },
    [update]
  );

  const childrenArray = React.Children.toArray(children) as React.ReactElement<
    React.PropsWithChildren<
      {
        value: string;
        'aria-selected'?: string;
      } & React.HTMLAttributes<any>
    >
  >[];

  // Support autofill.
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = childrenArray
      .map((child) => child.props?.value)
      .indexOf(event.target.value);

    if (index === -1) {
      return;
    }

    const child = childrenArray[index];
    setValue(child.props?.value);

    onChange?.(event, child);
  };

  const handleItemClick =
    (child: React.ReactElement) =>
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      if (!multiple) {
        update(false, event);
      }

      let newValue;
      if (multiple) {
        newValue = Array.isArray(value) ? value.slice() : [];
        const itemIndex = valueArray.indexOf(child.props.value);
        if (itemIndex === -1) {
          newValue.push(child.props.value);
        } else {
          newValue.splice(itemIndex, 1);
        }
      } else {
        newValue = child.props.value;
      }

      if (child.props.onClick) {
        child.props.onClick(event);
      }

      if (value === newValue) {
        return;
      }

      setValue(newValue);

      if (onChange) {
        event.persist();
        // Preact support, target is read only property on a native event.
        Object.defineProperty(event, 'target', {
          writable: true,
          value: { value: newValue, name },
        });
        onChange(event, child);
      }
    };

  const handleKeyDown = (event: React.KeyboardEvent<{}>) => {
    if (!readOnly) {
      const validKeys = [
        ' ',
        'ArrowUp',
        'ArrowDown',
        // The native select doesn't respond to enter on MacOS, but it's recommended by
        // https://www.w3.org/TR/wai-aria-practices/examples/listbox/listbox-collapsible.html
        'Enter',
      ];

      if (validKeys.indexOf(event.key) !== -1) {
        event.preventDefault();
        update(true, event);
      }
    }
  };

  const open =
    displayNode !== null && (isOpenControlled ? openProp : openState);

  const handleBlur = (event: React.FocusEvent) => {
    // if open event.stopImmediatePropagation
    if (!open && onBlur) {
      event.persist();
      // Preact support, target is read only property on a native event.
      Object.defineProperty(event, 'target', {
        writable: true,
        value: { value, name },
      });
      onBlur(event);
    }
  };

  delete other['aria-invalid'];

  const display = renderValue(
    childrenArray,
    value,
    renderValueProp,
    displayEmpty,
    multiple
  );

  let foundMatch = false;
  const items = childrenArray.map((child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    let selected;

    if (multiple) {
      if (!Array.isArray(value)) {
        throw new Error(
          'Material-UI: The `value` prop must be an array ' +
            'when using the `Select` component with `multiple`.'
        );
      }

      selected = value.some((v) => areEqualValues(v, child.props.value));
    } else {
      selected = areEqualValues(value, child.props.value);
    }

    if (selected) {
      foundMatch = true;
    }

    return React.cloneElement(child, {
      'aria-selected': selected ? 'true' : undefined,
      onClick: handleItemClick(child),
      onKeyUp: (event: React.KeyboardEvent) => {
        if (event.key === ' ') {
          // otherwise our MenuItems dispatches a click event
          // it's not behavior of the native <option> and causes
          // the select to close immediately since we open on space keydown
          event.preventDefault();
        }

        if (child.props.onKeyUp) {
          child.props.onKeyUp(event);
        }
      },
      role: 'option',
      selected,
      value: undefined, // The value is most likely not a valid HTML attribute.
      'data-value': child.props.value, // Instead, we provide it as a data attribute.
    } as any);
  });

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (!foundMatch && !multiple && value !== '') {
        const values = childrenArray.map((child) => child.props.value);
        // eslint-disable-next-line no-console
        console.warn(
          [
            `Material-UI: You have provided an out-of-range value \`${value}\` for the select ${
              name ? `(name="${name}") ` : ''
            }component.`,
            "Consider providing a value that matches one of the available options or ''.",
            `The available values are ${
              values
                .filter((x) => x != null)
                .map((x) => `\`${x}\``)
                .join(', ') || '""'
            }.`,
          ].join('\n')
        );
      }
    }, [foundMatch, childrenArray, multiple, name, value]);
  }

  // Avoid performing a layout computation in the render method.
  let menuMinWidth = menuMinWidthState;

  if (!autoWidth && isOpenControlled && displayNode) {
    menuMinWidth = displayNode.clientWidth;
  }

  let tabIndex;
  if (typeof tabIndexProp !== 'undefined') {
    tabIndex = tabIndexProp;
  } else {
    tabIndex = disabled ? null : 0;
  }

  const buttonId =
    SelectDisplayProps.id ||
    (name ? `mui-component-select-${name}` : undefined);

  const containerProps = { ...ListContainerProps };
  if (ListContainer !== React.Fragment) {
    containerProps.inputWidth = menuMinWidth;
  }

  return (
    <React.Fragment>
      <div
        className={clsx(
          classes.root, // TODO v5: merge root and select
          classes.select,
          classes.selectMenu,
          classes[variant],
          {
            [classes.disabled]: disabled,
          },
          className
        )}
        ref={setDisplayNode as any}
        tabIndex={tabIndex!}
        role="button"
        aria-disabled={disabled ? 'true' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="listbox"
        aria-label={ariaLabel}
        aria-labelledby={
          [labelId, buttonId].filter(Boolean).join(' ') || undefined
        }
        onKeyDown={handleKeyDown}
        onMouseDown={disabled || readOnly ? undefined : handleMouseDown}
        onBlur={handleBlur}
        onFocus={onFocus}
        {...SelectDisplayProps}
        // The id is required for proper a11y
        id={buttonId}
      >
        {/* So the vertical align positioning algorithm kicks in. */}
        {isEmpty(display) ? (
          // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />
        ) : (
          display
        )}
      </div>
      <input
        value={Array.isArray(value) ? value.join(',') : value || ''}
        name={name}
        ref={inputRef as any}
        aria-hidden
        onChange={handleChange}
        tabIndex={-1}
        className={classes.nativeInput}
        autoFocus={autoFocus}
        {...other}
      />
      <IconComponent
        className={clsx(
          classes.icon,
          (classes as any)[`icon${capitalize(variant)}`],
          {
            [classes!.iconOpen!]: open,
            [classes!.disabled!]: disabled,
          }
        )}
      />
      <Popover
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        {...PopoverProps}
        open={open!}
        onClose={handleClose}
        anchorEl={displayNode}
        role={undefined}
        PaperProps={{
          ...PopoverProps?.PaperProps,
          style: {
            minWidth: menuMinWidth,
            ...PopoverProps?.PaperProps?.style,
          },
        }}
      >
        <ListContainer {...containerProps}>
          <MenuList
            className={classes.list}
            aria-labelledby={labelId}
            role={'listbox'}
            disableListWrap={true}
            {...MenuListProps}
          >
            {items}
          </MenuList>
        </ListContainer>
      </Popover>
    </React.Fragment>
  );
});

export default SelectInput;
