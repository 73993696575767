import { SubComponentProps } from 'react-table';
import {
  makeStyles,
  Button,
  Grid,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { getTnsUrl } from '../../libs/utils/router-utils';
import DataGrid from '../../components/data-visualization/DataGrid/DataGrid';
import StickLeft from '../../components/common/StickLeft';

const containerLeftRightMargin = 7;
const useStyles = makeStyles(({ spacing }) => ({
  gridContainer: {
    backgroundColor: '#F4F2F2',
    height: '90%',
    margin: `${spacing(1)}px ${spacing(containerLeftRightMargin)}px`,
    padding: spacing(1, 7),
    boxSizing: 'border-box',
    width: `calc(100% - ${spacing(containerLeftRightMargin) * 2}px)`,
  },
  data: {
    alignItems: 'center',
    padding: spacing(0.5, 0),
  },
  chart: {
    marginTop: spacing(1),
    alignItems: 'center',
  },
  button: {},
}));

function ProductionDetailSubComponent({
  row: { original: data },
}: SubComponentProps<ProductionDetail>) {
  const classes = useStyles();

  const handleButtonClick = () => {
    const url = `${getTnsUrl()}/rqHNWCommDetails.aspx?KeyField=${data.transId}`;
    window.open(url, '_blank');
  };

  const groups: FormValue<ProductionDetail>[][] = [
    [
      { title: 'RR1', getValue: (x) => x.rr1 },
      { title: 'Period', getValue: (x) => x.period },
      { title: 'Security', getValue: (x) => x.security },
    ],
    [
      { title: 'RR2', getValue: (x) => x.rr2 },
      { title: 'Account Balance', getValue: (x) => x.accountBalance },
      { title: 'Shares', getValue: (x) => x.shares },
    ],
    [
      { title: 'Buy/Sell', getValue: (x) => x.buySell },
      { title: 'Price', getValue: (x) => x.price },
    ],
    [
      { title: 'Principal', getValue: (x) => x.principal },
      { title: 'MA Clearing', getValue: (x) => x.maClearing },
    ],
  ];

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const layout: FormLayout<ProductionDetail> = small
    ? {
        groupings: [
          { values: groups[0] },
          { values: groups[1] },
          { values: groups[2] },
          { values: groups[3] },
        ],
      }
    : {
        groupings: [
          { values: [...groups[0], ...groups[2]] },
          { values: [...groups[1], ...groups[3]] },
        ],
      };

  return (
    <StickLeft>
      <Grid container className={classes.gridContainer}>
        <Grid xs={12} md={8} item className={classes.data}>
          <DataGrid data={data} layout={layout} />

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
          >
            View Full Report
          </Button>
        </Grid>
        <Grid xs={12} sm={4} item className={classes.chart}>
          {/* Placeholder Chart <br />
          <PieChart /> */}
        </Grid>
      </Grid>
    </StickLeft>
  );
}

export default ProductionDetailSubComponent;
