import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Loading } from 'edgeco/components/loading';
import StickLeft from 'edgeco/components/common/StickLeft';
import { formatColumnValue } from './tableUtils';
import { ColorSchemeProvider } from '../colorScheme';

const useStyles = makeStyles(
  ({
    typography: { pxToRem },
    zIndex,
    spacing,
    extensions: { color, borders },
  }) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      marginBottom: spacing(3),
      borderTop: `2px solid ${color.borderDark}`,
      fontWeight: 'bold',
    },
    columns: {
      flex: 1,
      margin: spacing(0, 0.5, 1),
      border: borders.light,
      padding: spacing(1.5),
      boxShadow: '0 2px 4px 0 rgba(0,0,0,.5)',
      position: 'relative',
      height: 76,
    },
    title: {
      textTransform: 'uppercase',
      fontSize: pxToRem(21),
    },
    value: {
      textAlign: 'right',
      fontSize: pxToRem(36),
      position: 'absolute',
      right: 20,
      bottom: 6,
    },
    sticky: {
      position: 'sticky',
      bottom: 0,
      zIndex: zIndex.drawer + 10,
      background: color.background,
    },
  })
);

type Props<DataType extends object> = {
  data?: DataType;
  columns: (keyof DataType)[];
  columnDefinitions: ColumnDefinitionList<DataType>;
  sticky?: boolean;
  loading?: boolean;
};

function FlexTableFooter<DataType extends object>({
  data,
  columns,
  columnDefinitions,
  sticky,
  loading,
}: Props<DataType>) {
  const classes = useStyles();
  const { color } = useTheme<EdgeCoTheme>().extensions;
  const titleColors = [color.teal, color.sushi, color.orange];
  const Wrapper = sticky ? StickLeft : React.Fragment;
  if (!data && !loading) return null;
  return data ? (
    <Wrapper className={classes.sticky}>
      <div className={classes.root}>
        {columns.map((column, index) => {
          const definition = columnDefinitions[column];
          return (
            <div
              key={`flex-footer-${column ?? index}`}
              className={classes.columns}
              style={{
                color: ColorSchemeProvider.forIndexFromScheme(
                  titleColors,
                  index
                ),
              }}
            >
              <div className={classes.title}>{definition.footer}</div>
              <div className={classes.value}>
                {formatColumnValue(data[column], definition)}
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  ) : (
    <Loading />
  );
}

export default FlexTableFooter;
