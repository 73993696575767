import React, { forwardRef } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { getRectPath } from './shapeUtils';

type BarShapeProps = {
  fill?: string;
  activeFill?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  stroke?: string;
  refId?: string;
  isActive?: boolean;
  value?: any;
  style?: any;
  pattern?: React.ReactElement;
};
const BarShape = forwardRef<any, BarShapeProps>((props, ref) => {
  const {
    fill: fillProp,
    activeFill,
    x,
    y,
    width,
    height,
    stroke,
    refId,
    value,
    style,
    isActive = false,
    pattern,
  } = props;
  const patternId = `bar-mask-pattern-${_uniqueId()}`;
  let fill;
  if (pattern) {
    fill = `url(#${patternId})`;
  } else if (isActive) {
    fill = activeFill;
  } else {
    fill = fillProp;
  }
  return (
    value && (
      <>
        <path
          role="chart-bar"
          ref={ref}
          d={getRectPath(x, y, height, width)}
          fill={fill}
          stroke={stroke}
          mask={`url(#mask-${refId})`}
          style={style}
        />
        {pattern &&
          React.cloneElement(pattern, {
            isActive,
            id: patternId,
          })}
      </>
    )
  );
});

export default BarShape;
