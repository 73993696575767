import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckBoxProps,
  Theme,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import IconButton from '../buttons/IconButton';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 1 / 2),
    '&:hover': {
      backgroundColor: 'transparent!important', // TODO: can't get iconbutton hover to turn off
    },
  },
});

export type CheckboxProps = WithStyles<typeof styles> &
  Pick<MuiCheckBoxProps, 'edge'> & {
    labelId?: string;
    className?: string;
    isChecked?: boolean;
    borderColor?: string;
    backgroundColor?: string;
    checkColor?: string;
  };

function Checkbox({
  className,
  borderColor,
  backgroundColor,
  checkColor,
  isChecked,
  labelId,
  classes,
  edge = 'start',
}: CheckboxProps) {
  return (
    <MuiCheckbox
      edge={edge}
      checked={isChecked}
      tabIndex={-1}
      disableRipple
      inputProps={{
        'aria-labelledby': labelId,
      }}
      className={clsx(classes.root, className)}
      icon={
        <IconButton
          size="small"
          primaryColor={borderColor}
          backgroundColor={backgroundColor}
          variant="checkbox"
        />
      }
      checkedIcon={
        <IconButton
          size="small"
          primaryColor={borderColor}
          backgroundColor={backgroundColor}
          secondaryColor={checkColor}
          variant="checkboxChecked"
        />
      }
    />
  );
}

export default withStyles(styles)(Checkbox);
