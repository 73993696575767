import { ISessionAction, ISessionState } from './types';

import { SessionActionType } from './actions';
import { changeState } from '../state-helpers';

export const initialSessionState: ISessionState = {
  scroll: {
    horizontal: false,
    vertical: false,
  },
  isPollingHouseholds: false,
};

export const sessionStateHandlers: {
  [key: string]: DispatchHandler<ISessionState, ISessionAction>;
} = {
  [SessionActionType.UpdateScroll]: changeState('scroll'),
  [SessionActionType.SetHouseholdPolling]: changeState('isPollingHouseholds'),
};
