import { gql, useApolloClient, useQuery } from '@apollo/client';
import useEffectAsync from 'edgeco/hooks/useEffectAsync';

import { useCallback, useEffect, useMemo } from 'react';
import { GetSelectedRepsQuery, GetViewerRepsQuery } from '../@types';
import { GET_VIEWER_REPS } from './viewerReps';

export const GET_SELECTED_REPS = gql`
  query GetSelectedReps {
    selectedReps @client
  }
`;

export const useSelectedRepsQuery = () => {
  const { data: selectedRepsQuery, loading: loadingSelectedReps } =
    useQuery<GetSelectedRepsQuery>(GET_SELECTED_REPS);

  return { selectedRepsQuery, loadingSelectedReps };
};

export const useRepCacheInitialization = () => {
  const client = useApolloClient();
  const { data: selectedRepsQuery, loading: loadingSelected } =
    useQuery<GetSelectedRepsQuery>(GET_SELECTED_REPS);

  const {
    data: allReps,
    loading,
    error,
    previousData,
  } = useQuery<GetViewerRepsQuery>(GET_VIEWER_REPS, {
    fetchPolicy: 'network-only',
    pollInterval: 10 * 60 * 1000,
  });

  const selected = useMemo(() => {
    if (
      selectedRepsQuery?.selectedReps &&
      selectedRepsQuery?.selectedReps.length > 0
    )
      return selectedRepsQuery?.selectedReps;
    return allReps?.viewer?.reps && allReps.viewer.reps.length > 0
      ? [allReps.viewer.reps[0].repId]
      : [];
  }, [allReps, selectedRepsQuery]);

  const resetRepSelection = useCallback(
    (selection: number[]) =>
      client.writeQuery({
        query: GET_SELECTED_REPS,
        data: {
          selectedReps: selection,
        },
      }),
    [client]
  );

  // Preload the viewers reps and set initial selectedReps locally
  useEffect(() => {
    if (loading || !allReps?.viewer?.reps) return;
    if (
      !selectedRepsQuery?.selectedReps ||
      (selectedRepsQuery?.selectedReps &&
        selectedRepsQuery?.selectedReps.length <= 0)
    )
      resetRepSelection(selected);
  }, [
    allReps,
    loading,
    error,
    selectedRepsQuery,
    client,
    selected,
    resetRepSelection,
    loadingSelected,
  ]);

  useEffectAsync(async () => {
    if (loading || !allReps?.viewer?.reps || !previousData?.viewer?.reps)
      return;
    // compare previous reps with current. If different, clear cache
    const previousReps = JSON.stringify(
      allReps?.viewer?.reps.map((x) => x.repId).sort()
    );
    const newReps = JSON.stringify(
      previousData?.viewer?.reps.map((x) => x.repId).sort()
    );
    if (previousReps === newReps) return;

    //  TODO: This could be reworked. The resetStore initially updates queries with an empty array. Then resetRepSelection updates the queries.
    await client.resetStore(); // Clears cache, but doesn't refetch queries (unlike resetStore)
    resetRepSelection(selected);
  }, [allReps, client, loading, previousData, resetRepSelection, selected]);

  return { loading, allReps };
};
