import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { GET_SELECTED_REPS } from '../queries';

// Could also be used with a ReactiveVar, but there isn't a refined persistence for them yet
// example:
// export default (repIdsVar: ReactiveVar<number[] | undefined>) => {
//   return (repIds: number[]) => {
//     repIdsVar(repIds);
//   };
// };

const useSelectedRepsMutation = () => {
  const client = useApolloClient();
  const updateRepSelection = useCallback(
    (repIds: number[]) => {
      client.writeQuery({
        query: GET_SELECTED_REPS,
        data: {
          selectedReps: repIds,
        },
      });
    },
    [client]
  );
  return updateRepSelection;
};
export default useSelectedRepsMutation;
