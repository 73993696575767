import { gql, useApolloClient } from '@apollo/client';
import { Account } from 'edgeco/types/account';
import { useEffect, useMemo, useState } from 'react';
import { useLogger } from '../../../logging/useLogger';
import { GetAccountsByIdQuery, GetAccountsByIdQueryVariables } from '../@types';

export const GET_ACCOUNTS_BY_ID = gql`
  query GetAccountsById($accountIds: [String!]!, $cursor: String) {
    viewer {
      accounts(
        first: 100
        after: $cursor
        where: { account: { in: $accountIds } }
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          account
          shortName
        }
      }
    }
  }
`;

export const useAccountsById = (accountIds: string[]) => {
  const logger = useLogger('libs.graphql.advisor-summary.accounts');
  const [accounts, setAccountMap] = useState(new Map<string, Account>());

  const newAccounts = useMemo(
    () => [...new Set(accountIds)].filter((a) => !accounts.has(a)),
    // intentionally ignore the accountMap, we set that while fetching so it shouldn't cause a refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountIds]
  );
  const client = useApolloClient();

  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      let hasNextPage = true;
      let cursor: string | undefined | null;
      const newMap = new Map(accounts);
      while (hasNextPage && !cancel) {
        logger.debug(
          cursor
            ? `Fetching accounts after ${cursor}`
            : 'First page of accounts'
        );
        // eslint-disable-next-line no-await-in-loop
        const currentResult = await client.query<
          GetAccountsByIdQuery,
          GetAccountsByIdQueryVariables
        >({
          query: GET_ACCOUNTS_BY_ID,
          variables: {
            accountIds: newAccounts,
            cursor,
          },
        });
        const data = currentResult.data.viewer.accounts;
        if (data?.nodes) {
          hasNextPage = data.pageInfo.hasNextPage;
          cursor = data.pageInfo.endCursor;
          if (!cancel && data.nodes) {
            data.nodes?.forEach((n) =>
              newMap.set(n.account!, {
                account: n.account!,
                shortName: n.shortName!,
              })
            );
          }
        } else {
          hasNextPage = false;
        }
      }
      setAccountMap(newMap);
    };
    if (newAccounts.length) {
      fetchData();
    } else {
      logger.debug('No new accounts to fetch');
    }
    return () => {
      logger.debug('Account fetching canceled');
      cancel = true;
    };
    // we update the accounts map, ignore it so we don't get stuck in a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountIds, client, logger, newAccounts]);

  return { accounts };
};
