import * as React from 'react';

type ResultBox<T> = { v: T };

/**
 * Similar to useMemo, but instead lazy creates an item T exactly once.  Creates a ref that holds the value or function.  Can be used to wrap stateful functions locally in component.
 * https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
 */
export default function useConstant<T>(fn: () => T): T {
  const ref = React.useRef<ResultBox<T>>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}
