import { ApolloCache, gql } from '@apollo/client';
import { Dispatch } from 'react';
import { IAction } from 'state';
import { SessionActionType } from '../../../../../state/session';
import { Household } from '../../@types';

export const getCacheId = (householdId: string) =>
  `Household:{"householdId":"${householdId}"}`;

function removeHousehold(
  householdId: string,
  cached: any[],
  readField: (prop: string, obj: any) => any
) {
  return cached.filter((h: any) => readField('householdId', h) !== householdId);
}

export function getHouseholdFromCache(
  cache: ApolloCache<any>,
  householdId: string
) {
  return cache.readFragment<Household>({
    id: getCacheId(householdId), // The value of the to-do item's unique identifier
    fragment: gql`
      fragment GetHousehold on Household {
        name
        householdId
        accounts
      }
    `,
  });
}

export function updateHouseholdCache(
  cache: ApolloCache<any>,
  household: Partial<Household>
) {
  cache.modify({
    fields: {
      viewer(existingViewer: any, { readField }: any) {
        const newHouseholdRef = cache.writeFragment({
          data: {
            __typename: 'Household',
            ...household,
          },
          fragment: gql`
            fragment NewTodo on Household {
              householdId
              name
              accounts
              status
            }
          `,
        });
        let households = removeHousehold(
          household.householdId,
          existingViewer.households ?? [],
          readField
        );
        households = [...households, newHouseholdRef];
        return { ...existingViewer, households };
      },
    },
  });
}

export function removeHouseholdFromCache(
  cache: ApolloCache<any>,
  householdId: string
) {
  cache.modify({
    fields: {
      viewer(existingViewer: any, { readField }: any) {
        const households = removeHousehold(
          householdId,
          existingViewer.households,
          readField
        );
        return { ...existingViewer, households };
      },
    },
  });
}

const pollingDuration = 1000 * 60;
let pollingTimeout: NodeJS.Timeout;

// TODO: This is a temporary solution to an issue with apollo client
// It appears that there is a memory leak with each query. While this doesn't stop the memory leak it will reduce the
// likelihood that it causes a crash by only polling for one minute after a household is updated.
// https://github.com/apollographql/apollo-client/issues/7013
export function startPollingHouseholds(dispatch: Dispatch<IAction>) {
  dispatch({
    type: SessionActionType.SetHouseholdPolling,
    payload: true,
  });

  clearTimeout(pollingTimeout);
  pollingTimeout = setTimeout(() => {
    dispatch({
      type: SessionActionType.SetHouseholdPolling,
      payload: false,
    });
  }, pollingDuration);
}
