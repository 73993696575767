import { NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import useAuth from 'edgeco/hooks/useAuth';
import useEffectAsync from 'edgeco/hooks/useEffectAsync';
import { useCallback, useMemo, useState } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import createGraphQLClient from './client';
import {
  cachePersistence,
  cache,
  cachePersistor,
  currentCachePrefix,
  currentCacheSuffix,
} from './cache';

const useGraphQL = () => {
  const { authState } = useAuth();
  const [persistor, setPersistor] =
    useState<CachePersistor<NormalizedCacheObject>>();
  const apolloClient = useMemo(() => createGraphQLClient(cache), []);

  /**
   * Clears cached items if the app version changes.
   */
  const clearDeprecatedCache = useCallback(() => {
    const oldStorageKeys = Object.keys(localStorage).filter(
      (x) => x.startsWith(currentCachePrefix) && !x.endsWith(currentCacheSuffix)
    );
    if (oldStorageKeys.length <= 0) return;
    oldStorageKeys.forEach((x) => cachePersistence.removeItem(x));
  }, []);

  const clearCurrentCache = useCallback(async () => {
    await apolloClient.clearStore();

    if (!persistor) return;

    await persistor.purge();
  }, [apolloClient, persistor]);

  // Load cached queries & reactive variables
  useEffectAsync(async () => {
    clearDeprecatedCache();
    await cachePersistor.restore();

    setPersistor(cachePersistor);
  }, [apolloClient, clearDeprecatedCache]);

  // clear cache on logout
  useEffectAsync(async () => {
    if (authState === AuthenticationState.Unauthenticated)
      await clearCurrentCache();
  }, [authState, clearCurrentCache]);

  return { cache, persistor, clearCurrentCache, apolloClient };
};

export default useGraphQL;
