// eslint-disable-next-line import/no-cycle
import CancellationTokenSource from './CancellationTokenSource';

export default class CancellationToken {
  private tokenSource: CancellationTokenSource;

  constructor(tokenSource: CancellationTokenSource) {
    this.tokenSource = tokenSource;
  }

  get isCancellationRequested() {
    return this.tokenSource.isCancellationRequested;
  }
}
