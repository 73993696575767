import { gql } from '@apollo/client';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import { v4 as uuid } from 'uuid';
import {
  CreateHouseholdMutation,
  CreateHouseholdMutationVariables,
  CommandStatus,
} from '../../@types';

import { useUserProfileMutation } from '../useUserProfileMutation';
import {
  getCacheId,
  updateHouseholdCache,
  startPollingHouseholds,
} from './shared';

const ADD_HOUSEHOLD = gql`
  mutation CreateHousehold($householdId: Uuid!, $name: String!) {
    result: CreateHousehold(input: { householdId: $householdId, name: $name }) {
      errors {
        code
        message
      }
      householdState {
        householdId
        name
        accounts
        status @client
      }
    }
  }
`;

export const useAddHousehold = () => {
  const [addHousehold, { loading }] = useUserProfileMutation<
    CreateHouseholdMutation,
    CreateHouseholdMutationVariables
  >(ADD_HOUSEHOLD);
  const [, dispatch] = useAppStateValue();
  return {
    addHousehold: (name: string) => {
      const householdId = uuid().replaceAll('-', '');
      const newHousehold = {
        id: getCacheId(householdId),
        name,
        householdId,
        accounts: [],
      };
      addHousehold({
        variables: newHousehold,
        update: (cache, res) => {
          const result = res.data?.result;
          const errors = result?.errors ?? [];
          if (errors?.length <= 0 && result?.householdState) {
            updateHouseholdCache(cache, result.householdState);
          } else {
            updateHouseholdCache(cache, {
              ...newHousehold,
              status: CommandStatus.Failed,
            });
          }
          if (result?.householdState?.status !== CommandStatus.Processing) {
            startPollingHouseholds(dispatch);
          }
        },
        optimisticResponse: (vars) => {
          return {
            result: {
              errors: [],
              householdState: {
                householdId: vars.householdId,
                name: vars.name,
                accounts: [],
                status: CommandStatus.Processing,
              },
            },
          };
        },
      });
    },
    loading,
  };
};
