import { ComposedChart, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import Legend from '../common/CustomLegend';
import XAxis from '../common/XAxis';
import YAxis from '../common/YAxis';
import Grid from '../common/Grid';
import { useRechartStyles } from '../common/classes';

import { getMultiSeriesKeys } from '../chartUtils';
import useBringLineForward from '../hooks/useBringLineForward';

// There is an issue with active dots not showing up in ReCharts
// https://github.com/recharts/recharts/issues/2081
// This is just one way to change the active dot style
const useStyles = (activeDotStyle: CSSProperties) =>
  makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    chartRoot: {
      '& .recharts-active-dot': activeDotStyle,
    },
  });

function ComboChart({
  margin,
  children: childrenProp,
  xAxis,
  yAxis,
  legendSeries,
  grid,
  barCategoryGap,
  barSize,
  barGap,
  activeDotStyle,
  hideTopGridLine = true,
  width,
  height,
  dataShape,
}: ComboChartProps) {
  const chartMargins = margin ?? { top: 24, left: 48, right: 0, bottom: 24 };
  const { chartRoot, container } = useStyles(activeDotStyle || {})();
  const classes = useRechartStyles();
  const legendData =
    legendSeries ?? (dataShape && getMultiSeriesKeys(dataShape));

  if (process.env.NODE_ENV === 'test') {
    // TODO Mock responsive container globally
    // JSDom renders ResponsiveContainer with string values that do not get passed to it's children.
    // Tests will fail on the container since the children don't render.
    // eslint-disable-next-line no-param-reassign
    width = 500;
    // eslint-disable-next-line no-param-reassign
    height = 500;
  }

  const children = useBringLineForward(childrenProp);

  return (
    <div className={container}>
      <ResponsiveContainer width={width ?? '100%'} height={height ?? '100%'}>
        <ComposedChart
          className={clsx(
            chartRoot,
            hideTopGridLine && classes.hideTopGridLine
          )}
          barSize={barSize}
          barGap={barGap}
          barCategoryGap={barCategoryGap}
          data={dataShape?.chartData}
          margin={chartMargins}
        >
          {grid ?? <Grid />}
          {xAxis ?? <XAxis />}
          {yAxis ?? <YAxis />}

          {children}
        </ComposedChart>
      </ResponsiveContainer>
      {legendData && <Legend series={legendData} />}
    </div>
  );
}

export default ComboChart;
