import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import { useEffect } from 'react';

export function useHouseholdPolling(
  startPolling: (pollInterval: number) => void,
  stopPolling: () => void,
  interval: number = 5000
) {
  const [{ isPollingHouseholds }] = useAppStateValue();

  useEffect(() => {
    if (isPollingHouseholds) {
      startPolling(interval);
    }
    return () => {
      stopPolling();
    };
  }, [interval, isPollingHouseholds, startPolling, stopPolling]);
}
