import { gql } from '@apollo/client';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import {
  RemoveAccountMutation,
  RemoveAccountMutationVariables,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';
import {
  getHouseholdFromCache,
  updateHouseholdCache,
  startPollingHouseholds,
} from './shared';

const REMOVE_ACCOUNT_FROM_HOUSEHOLD = gql`
  mutation RemoveAccount($householdId: Uuid!, $account: String!) {
    RemoveAccountsFromHousehold(
      input: { householdId: $householdId, accounts: [$account] }
    ) {
      errors {
        code
        message
      }
    }
  }
`;

export const useRemoveAccountFromHousehold = () => {
  const [removeAccountFromHousehold, { loading }] = useUserProfileMutation<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >(REMOVE_ACCOUNT_FROM_HOUSEHOLD);
  const [, dispatch] = useAppStateValue();
  return {
    removeAccountFromHousehold: (householdId: string, account: string) =>
      removeAccountFromHousehold({
        variables: {
          householdId,
          // TODO figure out if Colin is accepting this
          account,
        },
        update: (cache, res) => {
          const errors = res.data?.RemoveAccountsFromHousehold?.errors ?? [];
          if (errors.length > 0) return;

          const household = getHouseholdFromCache(cache, householdId);
          updateHouseholdCache(cache, {
            ...(household ?? {}),
            accounts: [
              ...(household?.accounts ?? []).filter((act) => act !== account),
            ],
          });
          startPollingHouseholds(dispatch);
        },
      }),
    loading,
  };
};
