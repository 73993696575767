import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppInsightsContextProvider } from 'edgeco/hooks/AppInsights';
import { ApolloProvider } from '@apollo/client';
import { useGraphQL } from 'edgeco/graphql';
import appConfig from 'edgeco/config/appConfig';
import LogContext, { defaultValue } from './libs/logging/LogContext';
import { RouteMap } from './libs/routes/routes';
import { AppStateProvider, appReducer, initialState } from './state';
import theme from './assets/theme';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import {
  Feature,
  useCreateFeatureFlagContext,
} from './components/feature-flags';

const App: React.FC = () => {
  const { apolloClient } = useGraphQL();

  const qaAndLower = ['development-api', 'development', 'qa'].includes(
    appConfig.deployEnv
  );
  const devOnly = ['development-api', 'development'].includes(
    appConfig.deployEnv
  );

  const flags: Record<Feature, boolean> = {
    [Feature.ProfilePage]: qaAndLower,
    [Feature.Future1Page]: devOnly, // it will be hidden in QA
    [Feature.Notifications]: devOnly, // it will be hidden at QA
  };
  const FeatureFlagContext = useCreateFeatureFlagContext(flags);
  return (
    <>
      <LogContext.Provider value={defaultValue}>
        <Router>
          <FeatureFlagContext.Provider value={flags}>
            <ApolloProvider client={apolloClient}>
              <AppStateProvider reducer={appReducer} init={initialState}>
                <AppInsightsContextProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <RouteMap />
                  </ThemeProvider>
                </AppInsightsContextProvider>
              </AppStateProvider>
            </ApolloProvider>
          </FeatureFlagContext.Provider>
        </Router>
      </LogContext.Provider>
    </>
  );
};

export default App;
