/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line import/no-cycle
import CancellationToken from './CancellationToken';

export default class CancellationTokenSource {
  private tokens: CancellationToken[];

  private _isCancellationRequested: boolean;

  constructor() {
    this.tokens = [];
    this._isCancellationRequested = false;
  }

  get token() {
    const token = new CancellationToken(this);
    this.tokens.push(token);
    return token;
  }

  cancel() {
    this._isCancellationRequested = true;
  }

  get isCancellationRequested() {
    return this._isCancellationRequested;
  }
}
