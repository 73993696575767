import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { WithStyles, createStyles, withStyles } from '@material-ui/core';

import { useMeasure } from 'edgeco/hooks/useMeasure';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import { useRepCacheInitialization } from 'edgeco/graphql/advisor-summary/queries/selectedReps';
import { ActionType } from '../../state';
import Topbar from './Topbar';

const styles = () =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      boxSizing: 'border-box',
    },
  });

interface DecoratedProps
  extends WithStyles<typeof styles>,
    Partial<RouteComponentProps> {
  children?: any;
}
/**
 * This serves as the root component for all application specific components.
 *
 */
function Root({ classes, children }: DecoratedProps) {
  const { ref, measurement } = useMeasure();
  const [, dispatch] = useAppStateValue();
  useRepCacheInitialization();
  useEffect(() => {
    dispatch({ type: ActionType.UpdateScroll, payload: measurement.scroll });
  }, [dispatch, measurement]);

  return (
    <div className={classes.root} ref={ref}>
      <Topbar />
      {children}
    </div>
  );
}

export default withStyles(styles)(Root);
