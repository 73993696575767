import { Theme, createStyles, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import clsx from 'clsx';

const styles = ({
  extensions,
  spacing,
  typography: { pxToRem },
  breakpoints,
}: Theme) =>
  createStyles({
    header: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      fontSize: pxToRem(21),
      marginBottom: spacing(3),
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        display: 'block',
        lineHeight: 1,
      },
    },
    headerItem: {
      flexGrow: 1,
    },
    title: {
      color: extensions.color.teal,
      fontSize: pxToRem(36),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(2),
      },
    },
    repId: {
      right: spacing(2),
      display: 'flex',
      flexGrow: 0,
      flexShrink: 0,
      flexDirection: 'column',
      alignItems: 'flex-end',
      [breakpoints.down('sm')]: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  });

type Props = WithStyles<typeof styles> & {
  className?: string;
  title?: React.ReactNode;
};

function PageHeader({ className, classes, title }: Props) {
  return (
    <div className={clsx(className, classes.header)}>
      <div className={clsx(classes.headerItem, classes.title)}>{title}</div>

      <div className={classes.repId}></div>
    </div>
  );
}

export default withStyles(styles)(PageHeader);
