import { IAction, IState } from '.';
import {
  SessionActionType,
  initialSessionState,
  sessionStateHandlers,
} from './session';

export const ActionType = { ...SessionActionType };

export const initialState: IState = {
  ...initialSessionState,
};

/**
 * FACTORY
 */
export const reducerFactory =
  (initial: IState, handlers: any) =>
  (state: any = initial, action: any) => {
    const handler = handlers[action.type];

    if (handler) {
      return handler(state, action);
    }

    return state;
  };

/**
 * STATE HANDLERS
 * Similar to the Redux switch pattern.
 * Switches state change based on the action type
 */
export const stateHandlers = {
  ...sessionStateHandlers,
};

export const appReducer: React.Reducer<IState, IAction> = (state, action) => {
  return reducerFactory(initialState, stateHandlers)(state, action);
};
