import { gql, useQuery } from '@apollo/client';
import { GetViewerRepsQuery } from '../@types';

export const GET_VIEWER_REPS = gql`
  query GetViewerReps {
    viewer {
      reps {
        __typename
        repId
        firstName
        lastName
        email
        repCode
        isViewer
        groupId
        isGroup
        repGroup {
          groupName
          groupCode
        }
      }
    }
  }
`;

export const useViewerRepsQuery = () => {
  const { data: allViewerRepsQuery, loading: loadingAllReps } =
    useQuery<GetViewerRepsQuery>(GET_VIEWER_REPS);

  return { allViewerRepsQuery, loadingAllReps };
};
