import useAuth, { LOGIN_REQUEST_URL_KEY } from 'edgeco/hooks/useAuth';
import React, { useEffect } from 'react';
import { AuthenticationState } from 'react-aad-msal';
import { useHistory } from 'react-router';

const OidcRoutes = ({ children }: any) => {
  const { authState, login, authError } = useAuth();
  const history = useHistory();
  const { errorMessage } = authError ?? {};

  useEffect(() => {
    if (authState === AuthenticationState.Unauthenticated) {
      if (!errorMessage?.includes('AADB2C90118')) {
        login();
      }
    }
  }, [errorMessage, authState, login]);

  useEffect(() => {
    if (
      authState === AuthenticationState.Authenticated &&
      window.location.pathname === '/callback'
    ) {
      const url = localStorage.getItem(LOGIN_REQUEST_URL_KEY);
      localStorage.removeItem(LOGIN_REQUEST_URL_KEY);
      if (url) {
        history.push(url);
      } else {
        history.push('/');
      }
    }
  });

  if (authState !== AuthenticationState.Authenticated) {
    return <div />;
  }

  return <>{children}</>;
};

export default React.memo(OidcRoutes);
