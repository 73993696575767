import { useState } from 'react';
import { useTopHouseholdsChart } from 'edgeco/graphql/advisor-summary/queries/topHouseholds';
import PeriodTopDonutChart from './PeriodTopDonutChart';

export default function TopHouseholds() {
  const [datePeriod, setDatePeriod] =
    useState<TopPayoutAccountDatePeriod>('Month');
  const { topPayoutAccountsChartData, loading, error, totalPercent } =
    useTopHouseholdsChart(datePeriod);

  return (
    <>
      <PeriodTopDonutChart
        title="Top 5 Household"
        trackingName="HouseholdPeriod"
        chartData={topPayoutAccountsChartData}
        loading={loading}
        error={error}
        totalPercent={totalPercent}
        datePeriod={datePeriod}
        setDatePeriod={setDatePeriod}
      />
    </>
  );
}
