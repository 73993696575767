import { useEffect, useState } from 'react';
import { useTopPayoutAccountsChart } from 'edgeco/graphql/advisor-summary/queries';
import PeriodTopDonutChart from './PeriodTopDonutChart';

export default function TopPayoutAccounts(props: {
  onTopAccountsLoaded: (payload: KeyValueTypes[]) => void;
}) {
  const [datePeriod, setDatePeriod] =
    useState<TopPayoutAccountDatePeriod>('Month');
  const { topPayoutAccountsChartData, loading, error, totalPercent } =
    useTopPayoutAccountsChart(datePeriod);

  useEffect(() => {
    const accountKeyOnly = topPayoutAccountsChartData.map((x) => x.key);
    props.onTopAccountsLoaded(accountKeyOnly);
  }, [topPayoutAccountsChartData]);

  return (
    <>
      <PeriodTopDonutChart
        title="Top 5 Revenue"
        trackingName="TopPayout"
        chartData={topPayoutAccountsChartData}
        loading={loading}
        error={error}
        totalPercent={totalPercent}
        datePeriod={datePeriod}
        setDatePeriod={setDatePeriod}
      />
    </>
  );
}
