import React from 'react';
import ReactDraggable from 'react-draggable';

// TODO: could make cancel selector more user friendly
// Cancel Selector indicates non-draggable items
// https://material-ui.com/components/dialogs/#draggable-dialog
// TODO: move to react-beautiful-dnd
export const Draggable = <TProps extends object>(
  Component: React.ComponentType<TProps>,
  handleId: string,
  cancelSelectors?: string[]
) => {
  const nodeRef = React.useRef(null);

  return (props: TProps) => (
    <ReactDraggable
      nodeRef={nodeRef}
      handle={`#${handleId}`}
      cancel={cancelSelectors ? `'${cancelSelectors}'` : undefined}
    >
      <Component ref={nodeRef} {...props} />
    </ReactDraggable>
  );
};
