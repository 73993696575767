import {
  OutlinedInputProps,
  OutlinedInput as MuiOutlinedInput,
  makeStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  inputStyle: {
    color: theme.palette.text.primary,
    padding: theme.spacing(1 / 2, 2),
    '&:focus': {
      outline: 'none',
    },
  },
  disableHtml5Style: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

type Props = OutlinedInputProps & {
  disableHtml5Buttons?: boolean;
};
function OutlinedInput({
  className: wrapperClassName,
  inputProps,
  disableHtml5Buttons,
  ...restProps
}: Props) {
  const { inputStyle, disableHtml5Style } = useStyles();
  const { className: inputClassName, ...inputRest } = inputProps ?? {};

  const renderedProps = {
    inputProps: {
      className: clsx(inputStyle, inputClassName),
      ...inputRest,
    },
    className: clsx(wrapperClassName, {
      [disableHtml5Style]: disableHtml5Buttons,
    }),
    ...restProps,
  };

  return <MuiOutlinedInput {...renderedProps} />;
}

export default OutlinedInput;
