import { gql } from '@apollo/client';
import { useAppStateValue } from 'edgeco/hooks/useAppStateValue';
import {
  AddAccountsMutation,
  AddAccountsMutationVariables,
  Household,
} from '../../@types';
import { useUserProfileMutation } from '../useUserProfileMutation';
import {
  getHouseholdFromCache,
  updateHouseholdCache,
  startPollingHouseholds,
} from './shared';

const ADD_ACCOUNTS_TO_HOUSEHOLD = gql`
  mutation AddAccounts($householdId: Uuid!, $accounts: [String!]!) {
    AddAccountsToHousehold(
      input: { householdId: $householdId, accounts: $accounts }
    ) {
      errors {
        code
        message
      }
    }
  }
`;

export const useAddAccountsToHousehold = () => {
  const [addAccountsToHousehold, { loading }] = useUserProfileMutation<
    AddAccountsMutation,
    AddAccountsMutationVariables
  >(ADD_ACCOUNTS_TO_HOUSEHOLD, {});
  const [, dispatch] = useAppStateValue();
  return {
    addAccountsToHousehold: (householdId: string, accounts: string[]) =>
      addAccountsToHousehold({
        variables: {
          householdId,
          accounts,
        },
        update: (cache, res) => {
          const errors = res.data?.AddAccountsToHousehold?.errors ?? [];
          if (errors.length > 0) return;

          const household = getHouseholdFromCache(cache, householdId);
          updateHouseholdCache(cache, {
            ...(household ?? {}),
            accounts: [...(household!.accounts ?? []), ...accounts],
          } as Household);
          startPollingHouseholds(dispatch);
        },
      }),
    loading,
  };
};
