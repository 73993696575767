import { Collapse, makeStyles } from '@material-ui/core';

import clsx from 'clsx';
import { Row, SubComponent } from 'react-table';

// colspan doesn't work well with sticky and causes the contents of the
// sub-component to scroll, to fix this we set the content wrapper to have a with of 0
// and allow the table to set a max-width on the sub-components. See Table.tsx.
const useStyles = makeStyles({
  cell: {
    position: 'sticky',
    left: 0,
    padding: 0,
  },
  collapsed: {
    border: 'none',
  },
  contentWrapper: {
    gridColumn: '1 / -1',
  },
});

type CollapsibleRowProps<D extends object> = {
  subComponent?: SubComponent<D>;
  row: Row<D>;
};

function CollapsibleRow<D extends object>({
  subComponent,
  row,
}: CollapsibleRowProps<D>) {
  const classes = useStyles();
  if (!subComponent) return null;
  return (
    <div className={classes.contentWrapper}>
      <div className={clsx(classes.cell, !row.isExpanded && classes.collapsed)}>
        <div>
          <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
            <div>{subComponent({ row })}</div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default CollapsibleRow;
