import { Typography, makeStyles, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { Checkbox } from 'edgeco/components/input';
import ListItem from 'edgeco/components/lists/ListItem';
import React, { useCallback } from 'react';
import { Account } from 'edgeco/types/account';
import { withTooltip } from 'edgeco/components/data-visualization';
import { shallowEqual } from '../../../../libs';
import { householdSizes } from './shared';

const useStyles = makeStyles(({ extensions: { color }, spacing }) => ({
  listItem: {
    background: color.background,
    '&$alt': {
      background: color.tableAltRow,
    },
    '&.MuiButtonBase-root.Mui-disabled': {
      pointerEvents: 'initial',
    },
  },
  alt: {},
  accountName: {
    fontWeight: 'bold',
    marginRight: spacing(2),
  },
  itemText: {
    display: 'inline-block',
  },
  listItemAction: {
    marginLeft: householdSizes.checkMarginLeft,
    minWidth: 0,
    width: householdSizes.checkWidth,
  },
  loading: {
    width: '100%',
    textAlign: 'center',
  },
  checkbox: {
    '&$disabled': {
      pointerEvents: 'none',
    },
  },
  disabled: {},
}));

type Props = {
  index: number;
  style: any;
  loading?: boolean;
  account?: Account;
  disabled?: boolean;
  isChecked?: boolean;
  onClick: (account: string) => void;
};

const AccountListItem = withTooltip<Props>(
  React.forwardRef(
    (
      {
        account: accountObj,
        loading,
        style,
        index,
        disabled,
        isChecked,
        onClick,
      }: Props,
      ref: any
    ) => {
      const classes = useStyles();
      const sharedProps: ListItemProps = {
        style,
        dense: true,
        button: true,
        disableGutters: true,
        className: clsx(classes.listItem, index % 2 === 0 && classes.alt),
        isNarrow: true,
      };

      const account = accountObj?.account;
      const handleClick = useCallback(() => {
        onClick(account!);
      }, [account, onClick]);

      if (loading || !accountObj) {
        return (
          <ListItem {...sharedProps}>
            <div className={classes.loading}>Loading...</div>
          </ListItem>
        );
      }

      const { shortName } = accountObj;
      return (
        <div ref={ref}>
          <ListItem
            {...sharedProps}
            disabled={disabled ?? false}
            text={{
              primary: (
                <>
                  <ListItemText>
                    <Typography
                      className={clsx(classes.accountName, classes.itemText)}
                    >
                      {account}
                    </Typography>
                    <Typography className={classes.itemText}>
                      {shortName}
                    </Typography>
                  </ListItemText>
                </>
              ),
            }}
            onClick={handleClick}
            primaryAction={{
              props: {
                className: classes.listItemAction,
              },
              children: (
                <Checkbox
                  className={clsx(
                    classes.checkbox,
                    disabled && classes.disabled
                  )}
                  edge={false}
                  isChecked={isChecked}
                />
              ),
            }}
          />
        </div>
      );
    }
  )
);

export default React.memo(AccountListItem, (prev, next) => {
  const { tooltipOptions: tooltipA, ...a } = prev;
  const { tooltipOptions: tooltipB, ...b } = next;
  return shallowEqual(a, b) && shallowEqual(tooltipA, tooltipB);
});
