const checkMarginLeft = 35;
const checkWidth = 45;
const checkTotalWidth = checkMarginLeft + checkWidth;

export const householdSizes = {
  modalHeight: 450,
  headerHeight: 140,
  itemHeight: 30,
  checkMarginLeft,
  checkWidth,
  checkTotalWidth,
};
