import { makeStyles, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles<EdgeCoTheme, { color: string }>(
  (theme: EdgeCoTheme) => ({
    root: {
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.extensions.color.data.comparisonWhite,
      justifyContent: 'space-between',
      height: '100%',
    },
    eyebrow: ({ color }) => ({
      color,
      textTransform: 'uppercase',
      fontWeight: 700,
    }),
    headline: ({ color }) => ({
      color,
      ...theme.extensions.font.headline,
    }),
    caption: {
      ...theme.typography.caption,
    },
  })
);

type TileProps = {
  eyebrowText?: string;
  headlineText?: string;
  captionText?: string;
  color: string;
  breakpoints?: Breakpoints;
};
export default function Tile({
  eyebrowText,
  headlineText,
  captionText,
  color,
  breakpoints = {
    md: 4,
    xs: 12,
  },
}: TileProps) {
  const { root, eyebrow, headline, caption } = useStyles({ color });
  return (
    <Grid item {...breakpoints}>
      <div className={root}>
        <Typography variant="h3" className={eyebrow}>
          {eyebrowText}
        </Typography>
        <div className={headline}>{headlineText}</div>
        <div className={caption}>{captionText}</div>
      </div>
    </Grid>
  );
}
