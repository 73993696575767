import React, { PropsWithChildren } from 'react';
import {
  LineChart,
  ComposedChartProps,
  ResponsiveContainer,
  XAxisProps,
  TooltipProps,
  YAxisProps,
  CartesianGridProps,
} from 'recharts';
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import Legend from '../common/CustomLegend';
import XAxis from '../common/XAxis';
import YAxis from '../common/YAxis';
import Grid from '../common/Grid';
import { useRechartStyles } from '../common/classes';
import { getMultiSeriesKeys } from '../chartUtils';
import useBringLineForward from '../hooks/useBringLineForward';

// There is an issue with active dots not showing up in ReCharts
// https://github.com/recharts/recharts/issues/2081
// This is just one way to change the active dot style
const useStyles = (activeDotStyle: CSSProperties) =>
  makeStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
    },
    chartRoot: {
      '& .recharts-active-dot': activeDotStyle,
    },
    hideTopGridStyle: {
      '& .recharts-surface .recharts-cartesian-grid .recharts-cartesian-grid-horizontal line:last-child':
        {
          strokeOpacity: 0,
        },
    },
  });

type LineChartProps<TData extends object> = ComposedChartProps & {
  data: TData[];
  nameKey: string;
  margin?: Margin;
  xAxis?: React.ReactElement<XAxisProps>;
  yAxis?: React.ReactElement<YAxisProps>;
  tooltips?: React.ReactElement<TooltipProps>[];
  grid?: React.ReactElement<CartesianGridProps>;
  activeDotStyle?: CSSProperties;
  hideTopGridline?: boolean;
  legendSeries?: (string | Date | number)[];
};

export default function LineChartWrapper<T extends object>({
  margin,
  data,
  children: childrenProp,
  xAxis,
  yAxis,
  grid,
  width,
  height,
  activeDotStyle,
  hideTopGridline = true,
  legendSeries,
  nameKey,
}: PropsWithChildren<LineChartProps<T>>) {
  const chartMargins = margin ?? { top: 24, left: 48, right: 0, bottom: 24 };
  const { chartRoot, container } = useStyles(activeDotStyle || {})();
  const classes = useRechartStyles();
  const legendData =
    legendSeries ?? getMultiSeriesKeys(data, nameKey).reverse();

  if (process.env.NODE_ENV === 'test') {
    // TODO Mock responsive container globally
    // JSDom renders ResponsiveContainer with string values that do not get passed to it's children.
    // Tests will fail on the container since the children don't render.
    // eslint-disable-next-line no-param-reassign
    width = 500;
    // eslint-disable-next-line no-param-reassign
    height = 500;
  }

  const children = useBringLineForward(childrenProp);
  return (
    <div className={container}>
      <ResponsiveContainer width={width ?? '100%'} height={height ?? '100%'}>
        <LineChart
          className={clsx(
            chartRoot,
            hideTopGridline && classes.hideTopGridLine
          )}
          data={data}
          margin={chartMargins}
        >
          {grid ?? <Grid />}
          {xAxis ?? <XAxis />}
          {yAxis ?? <YAxis />}
          {children}
        </LineChart>
      </ResponsiveContainer>
      <Legend series={legendData} />
    </div>
  );
}
