import React, { PropsWithChildren } from 'react';
import { createStyles, Grid, WithStyles, withStyles } from '@material-ui/core';

export const styles = (theme: EdgeCoTheme) =>
  createStyles({
    filter: {
      padding: theme.spacing(1),
    },
    filterMenu: {
      background: theme.extensions.color.background,
    },
    filterComponent: {
      width: '100%',
      maxWidth: 180,
    },
    dateFilter: {
      minWidth: 165,
    },
  });

export type FilterContainerClassKey = keyof ReturnType<typeof styles>;

type Props = WithStyles<FilterContainerClassKey> &
  PropsWithChildren<{
    filter: AnyTableFilter;
    breakpoints?: Breakpoints;
    index: number;
    depth: number;
  }>;

function PureFilterContainer({
  filter,
  classes,
  breakpoints,
  index,
  depth,
  children,
}: Props) {
  return (
    <Grid
      item
      className={classes.filter}
      xs={filter.breakpoints || breakpoints ? undefined : 3}
      {...breakpoints}
      {...filter.breakpoints}
      key={`report-filter-${depth}-${index}-${filter.field}`}
    >
      <div>{filter.title}</div>
      {children}
    </Grid>
  );
}

const FilterContainer = withStyles(styles, { name: 'FilterContainer' })(
  PureFilterContainer
);
export default FilterContainer;
export type FilterContainerProps = React.ComponentProps<typeof FilterContainer>;
